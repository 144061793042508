import { memo } from "react";
import { FormattedMessage } from "react-intl";

import { MeetingSidebarParty, MeetingSidebarPartyInfo } from "common/meeting/sidebar/party";
import SignerPartyInfo from "common/meeting/sidebar/signer_info";
import { VideoCondenseAction } from "common/meeting/sidebar/party/actions";
import { StandardTrackWithWitnessControls } from "common/meeting/sidebar/party/track";
import UserFullName from "common/user/user_full_name";
import type { RemoteParty } from "common/video_conference";

import type {
  RemoteWitnessMeetingSidebar_meetingParticipants_SignerParticipant as SignerParticipant,
  RemoteWitnessMeetingSidebar_meetingParticipants_WitnessParticipant as WitnessParticipant,
  RemoteWitnessMeetingSidebar_meetingParticipants as MeetingParticipant,
} from "./index_fragment.graphql";

type Props = {
  party: RemoteParty<MeetingParticipant>;
  onCheckId: (x: SignerParticipant) => void;
  credentialsViewed: (participantId: string) => boolean;
};

function particpantIsCurrentPenholder(participant: MeetingParticipant): boolean {
  return "isCurrentPenHolder" in participant && participant.isCurrentPenHolder;
}

function SignerParty({ party, onCheckId, credentialsViewed }: Props) {
  const { participants, track } = party;
  const singleSigner = participants.length === 1;
  const signerParticipantsInThisParty = participants.filter(
    (participant) => participant.__typename === "SignerParticipant",
  );

  const isColocatedSigners = signerParticipantsInThisParty.some((signer) => signer.parentId);
  const allSignersCredentialsViewed = signerParticipantsInThisParty.every((participant) =>
    credentialsViewed(participant.id),
  );

  const credentialSignerParticipant =
    signerParticipantsInThisParty.find((signer) => signer.isCurrentPenHolder) ||
    signerParticipantsInThisParty[0];

  const handleCheckId = () => onCheckId(credentialSignerParticipant);

  return (
    <MeetingSidebarParty
      track={(trackProps) => (
        <StandardTrackWithWitnessControls
          onCheckId={handleCheckId}
          track={track}
          checkIdWarning={
            isColocatedSigners
              ? !allSignersCredentialsViewed
              : !credentialsViewed(credentialSignerParticipant.id)
          }
          popoutMenuProps={{
            children: () => (
              <VideoCondenseAction
                toggleVideoCondensed={trackProps.toggleVideoCondensed}
                videoCondensed={trackProps.videoCondensed}
              />
            ),
          }}
        />
      )}
      renderExpansion={
        singleSigner
          ? undefined
          : () =>
              participants.map((participant) => (
                <SignerPartyInfo
                  key={participant.id}
                  participant={participant as SignerParticipant | WitnessParticipant}
                />
              ))
      }
    >
      <MeetingSidebarPartyInfo
        header={
          singleSigner ? (
            <UserFullName user={participants[0]} wrap />
          ) : (
            <FormattedMessage
              id="00585d06-f876-4e12-97c9-4872ee249c89"
              defaultMessage="Multi-signer"
            />
          )
        }
        headerSubtext={
          singleSigner && particpantIsCurrentPenholder(participants[0]) ? (
            <FormattedMessage
              id="e8600317-af73-409c-a968-6626240df2e4"
              defaultMessage="Active Signer"
            />
          ) : singleSigner ? (
            <FormattedMessage id="2bffb7e5-2623-4ccf-ae21-fadea4bacc66" defaultMessage="Signer" />
          ) : (
            <FormattedMessage
              id="c6b246ca-2dc4-48c4-85ef-14fa0dd5d343"
              defaultMessage="{numSigners, number} Signers"
              values={{ numSigners: participants.length }}
            />
          )
        }
      />
    </MeetingSidebarParty>
  );
}

export default memo(SignerParty);
