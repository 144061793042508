import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import { Heading, Paragraph } from "common/core/typography";
import { IconButton } from "common/core/button/icon_button";
import Icon from "common/core/icon";
import Button from "common/core/button";

import type { ActiveParticipant } from "..";
import Styles from "./profile.module.scss";

type Props = {
  activeParticipant: ActiveParticipant;
  onClose: () => void;
  onViewCredentials: (participantId: string) => void;
  credentialsViewed: boolean;
  loading: boolean;
};

const MESSAGES = defineMessages({
  closeButton: {
    id: "684f9efe-f2d8-4ebc-90e3-76b4ee9fde0d",
    defaultMessage: "Exit identity verification",
  },
});

export default function IdentityProfile(props: Props) {
  const { activeParticipant, onClose, onViewCredentials, credentialsViewed, loading } = props;
  const intl = useIntl();

  return (
    <div className={Styles.container}>
      <IconButton
        className={Styles.closeButton}
        onClick={onClose}
        name="x-mark"
        label={intl.formatMessage(MESSAGES.closeButton)}
      />
      <Heading level="h4" textStyle="subtitleSmall">
        <FormattedMessage
          id="99111547-e09a-4078-b8de-3e1154b5ad14"
          defaultMessage="Identity profile"
        />
      </Heading>
      {credentialsViewed ? (
        <Heading level="h2" textStyle="headingThree">
          <Icon name="success" className={Styles.validatedIcon} />
          <FormattedMessage
            id="de11737e-d269-4587-ad52-7c924b963d20"
            defaultMessage="Viewed by witness"
          />
        </Heading>
      ) : (
        <div>
          <Paragraph>
            <FormattedMessage
              id="f72c1122-afb0-42bd-9f2e-60f711c4a580"
              defaultMessage="Validate that you've seen the credentials as a witness"
            />
          </Paragraph>
          <Button
            buttonColor="action"
            variant="secondary"
            key="mark-as-viewed"
            onClick={() => onViewCredentials(activeParticipant.id)}
            isLoading={loading}
          >
            <FormattedMessage
              id="6fdb0f52-46ee-40ae-a3ce-ab0d2dc41187"
              defaultMessage="Mark as viewed"
            />
          </Button>
        </div>
      )}
    </div>
  );
}
