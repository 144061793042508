import { FormattedMessage } from "react-intl";

import { Paragraph } from "common/core/typography";

import type { CommonContentProps } from "..";

export function Subheading({
  selfieRequired,
  requiresIdPhotos,
  identityVerifiedWitness,
  isPs1583Bundle,
}: CommonContentProps & { identityVerifiedWitness: boolean; isPs1583Bundle: boolean }) {
  return (
    <Paragraph>
      {identityVerifiedWitness ? (
        <FormattedMessage
          id="b24341d4-510a-4b41-bc42-25a2838a41bc"
          defaultMessage="You will be serving as a witness in a meeting where your signature(s) may be notarized. The notary will be reviewing your identity proofing steps before completing the meeting."
        />
      ) : !requiresIdPhotos ? (
        <FormattedMessage
          id="b34341d4-510a-4b41-bc42-25a2838a41bc"
          defaultMessage="We'll need to verify your identity with a selfie"
        />
      ) : selfieRequired ? (
        <FormattedMessage
          id="c967add6-bc68-49df-a18e-bb84c4070af5"
          defaultMessage="We'll need to verify your identity with a {isPs1583Bundle, select, true{photo} other{government-issued}} ID and a selfie - we will validate the selfie image with the ID"
          values={{ isPs1583Bundle }}
        />
      ) : (
        <FormattedMessage
          id="c765add6-bc68-49df-a18e-bb84c4070af5"
          defaultMessage="We'll need to verify your identity with a {isPs1583Bundle, select, true{photo} other{government-issued}} ID"
          values={{ isPs1583Bundle }}
        />
      )}
    </Paragraph>
  );
}
