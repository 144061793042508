import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import type { Subscription } from "zen-observable-ts";

import { MortgageTransactionType, AsyncJobStatus } from "graphql_globals";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";
import { useMutation, useQuery } from "util/graphql";

import DuplicateOrganizationTransactionMutation from "./duplicate_organization_transaction_mutation.graphql";
import AsyncJobsQuery, {
  type AsyncJobs as AsyncJobsQueryType,
  type AsyncJobs_documentBundle_DocumentBundle_asyncJobs as AsyncJob,
} from "./async_jobs_query.graphql";

const MESSAGES = defineMessages({
  cloneError: {
    id: "b49c3f96-9ccb-43a4-8a74-52c04f34cc08",
    defaultMessage: "Clone failed. Retry?",
  },
  success: {
    id: "ea455018-b224-4ff5-a39f-fb4c292ff789",
    defaultMessage: "Transaction cloned",
  },
});

type DocumentBundle = {
  asyncJobs: AsyncJob[] | null;
  id: string;
} | null;

export const useCloneTransactionAction = () => {
  const duplicateOrganizationTransactionMutation = useMutation(
    DuplicateOrganizationTransactionMutation,
  );

  const asyncJobsQueryObservable = useQuery(AsyncJobsQuery, {
    variables: {
      documentBundleId: "",
    },
  }).observable;

  const [isLoading, setLoading] = useState(false);

  const subscribers: Subscription[] = [];

  function filterAsyncJob(asyncJobId: string) {
    return (object: { data: AsyncJobsQueryType }) => {
      const documentBundle = object.data.documentBundle as DocumentBundle;

      if (documentBundle?.asyncJobs) {
        const asyncJobs: AsyncJob[] = documentBundle.asyncJobs;

        return (
          asyncJobs.find((job) => job.id === asyncJobId && job.status !== AsyncJobStatus.PENDING) ||
          null
        );
      }

      return null;
    };
  }

  const navigate = useNavigate();
  const intl = useIntl();

  function stopObservable() {
    asyncJobsQueryObservable.stopPolling();
    subscribers.forEach((sub) => sub.unsubscribe());
  }

  function errorHandler() {
    setLoading(false);
    stopObservable();
    pushNotification({
      position: "topCenter",
      type: NOTIFICATION_TYPES.DEFAULT,
      subtype: NOTIFICATION_SUBTYPES.ERROR,
      message: intl.formatMessage(MESSAGES.cloneError),
    });
  }

  const action = (transactionId: string) => {
    setLoading(true);
    duplicateOrganizationTransactionMutation({
      variables: {
        input: {
          id: transactionId,
        },
      },
    })
      .then((result) => {
        const documentBundle =
          result.data?.duplicateOrganizationTransaction?.organizationTransaction.documentBundle;
        const documentBundleId = documentBundle!.id;
        const asyncJobId = documentBundle!.asyncJobs![0]?.id;

        const clonedTransactionId =
          result.data?.duplicateOrganizationTransaction?.organizationTransaction.id;

        const transactionType =
          result.data?.duplicateOrganizationTransaction?.organizationTransaction.transactionType;

        const path =
          transactionType === MortgageTransactionType.real_estate_esign ||
          transactionType === MortgageTransactionType.real_estate_proof
            ? `/transaction/esign/${clonedTransactionId}`
            : `/transaction/${clonedTransactionId}`;

        if (asyncJobId) {
          asyncJobsQueryObservable.refetch({
            documentBundleId,
          });

          const subscribe = asyncJobsQueryObservable
            .map(filterAsyncJob(asyncJobId))
            .filter((asyncJob) => asyncJob !== null)
            .subscribe({
              next: (asyncJob) => {
                if (asyncJob.status === AsyncJobStatus.COMPLETED) {
                  setLoading(false);
                  asyncJobsQueryObservable.stopPolling();
                  subscribers.forEach((sub) => sub.unsubscribe());

                  if (clonedTransactionId) {
                    navigate(path);
                    pushNotification({
                      position: "topCenter",
                      type: NOTIFICATION_TYPES.DEFAULT,
                      subtype: NOTIFICATION_SUBTYPES.SUCCESS,
                      message: intl.formatMessage(MESSAGES.success),
                    });
                  } else {
                    errorHandler();
                  }
                } else if (asyncJob.status === AsyncJobStatus.FAILED) {
                  errorHandler();
                }
              },
              error: () => {
                errorHandler();
              },
            });
          asyncJobsQueryObservable.startPolling(2000);
          subscribers.push(subscribe);
        }
      })
      .catch(() => {
        errorHandler();
      });
  };

  return { action, isLoading };
};
