import { useCallback, useEffect, useRef, useState } from "react";
import { catchError, EMPTY, exhaustMap, from, mergeMap, tap, timer } from "rxjs";

import { useMutation } from "util/graphql";
import { type QueryResult, useLazyQuery } from "util/graphql/query";
import {
  type DocumentsTypes,
  PhotoIdentification,
  StepType,
  IdentityVerificationVendors,
} from "graphql_globals";
import { useSubject } from "util/rxjs/hooks";
import { captureException } from "util/exception";

import IdvStateQuery, {
  type IdvState,
  type IdvState_steps as IdvStep,
  type IdvState_steps_CredentialAnalysisStep as IdvCaStep,
  type IdvStateVariables,
} from "./index.query.graphql";
import StartIdvForSignerIdentity from "./start_idv_for_signer_identity.mutation.graphql";

export type ServiceInput = {
  type: "service";
  primaryCountry: string | null;
  primaryDocumentType: DocumentsTypes | null;
  secondaryCountry: string | null;
  secondaryDocumentType: DocumentsTypes | null;
  desktopFlow?: boolean;
  simulateFailures?: boolean;
  singleCredentialVerification?: PhotoIdentification | null;
};

export type { IdvResult } from "./poll_identify_verification_result";

type Props = {
  signerIdentityId: string;
  onInquiryCreationFailure?: () => void;
  onInquiryCreation: (data: {
    inquiryId: string;
    vendorSdkKey: string | null;
    vendor: NonNullable<ReturnType<typeof getNamedSupportedVendor>>;
  }) => void;
};

function getNamedSupportedVendor(vendor: IdentityVerificationVendors | null) {
  if (!vendor) {
    return null;
  }
  switch (vendor) {
    case IdentityVerificationVendors.TWILIO:
      return null;
    case IdentityVerificationVendors.PERSONA:
      return "persona";
    case IdentityVerificationVendors.SOCURE:
      return "socure";
  }
}

function getIDVState(idvKey: string | null, steps?: IdvStep[]) {
  if (!steps || !idvKey) {
    return null;
  }

  return (
    (
      steps.find((step) => {
        return (
          step.stepType === StepType.CREDENTIAL_ANALYSIS &&
          (step as IdvCaStep).idvState?.key === idvKey
        );
      }) as IdvCaStep | undefined
    )?.idvState || null
  );
}

export default function useIDVService({
  onInquiryCreation,
  onInquiryCreationFailure,
  signerIdentityId,
}: Props) {
  const [queryPolling, setQueryPolling] = useState(false);
  const [mutationLoading, setMutationLoading] = useState(false);
  const [idvKey, setIdvKey] = useState<string | null>(null);
  const startIdvForSignerIdentityMutateFn = useMutation(StartIdvForSignerIdentity);
  const mutationCallRef = useRef<Promise<void | QueryResult<IdvState, IdvStateVariables>> | null>(
    null,
  );
  const initialize$ = useSubject<ServiceInput>();

  const [startInquiryIDQuery, { data, stopPolling }] = useLazyQuery(IdvStateQuery, {
    variables: { signerIdentityIds: [signerIdentityId] },
    pollInterval: 2_000,
    nextFetchPolicy: "no-cache",
  });

  const idvState = getIDVState(idvKey, data?.steps);

  useEffect(() => {
    const sub = initialize$
      .pipe(
        // We use exhaustMap to ignore new emitted values as a precaution - only one Promise chain
        // should be active at a time.
        exhaustMap((data) => {
          setIdvKey(null);
          setMutationLoading(true);

          const mutationPromise = startIdvForSignerIdentityMutateFn({
            variables: {
              input: {
                signerIdentityId,
                simulateFailure: data.simulateFailures ?? false,
                desktopFlow: data.desktopFlow ?? false,
                singleCredentialVerification: data.singleCredentialVerification ?? null,
                selectedIdentityDocuments: [
                  ...(data.primaryDocumentType
                    ? [
                        {
                          type: PhotoIdentification.PRIMARY,
                          issuer: data.primaryCountry,
                          code: data.primaryDocumentType,
                        },
                      ]
                    : []),
                  ...(data.secondaryDocumentType
                    ? [
                        {
                          type: PhotoIdentification.SECONDARY,
                          issuer: data.secondaryCountry ?? data.primaryCountry,
                          code: data.secondaryDocumentType,
                          name: data.secondaryDocumentType,
                        },
                      ]
                    : []),
                ],
              },
            },
          }).then(({ data }) => data?.startIdvForSignerIdentity?.idvKey);

          return from(mutationPromise).pipe(
            // Handle side effects using tap to update state
            tap((idvKey) => {
              setMutationLoading(false);
              setIdvKey(idvKey!);
            }),
            // Use mergeMap to ensure its resolution is part of the observable pipeline
            mergeMap(() => {
              setQueryPolling(true);
              return from(startInquiryIDQuery());
            }),
            // Handle errors for both the mutation and the initial query for the IDV key
            catchError(() => {
              setMutationLoading(false);
              setQueryPolling(false);
              onInquiryCreationFailure?.();
              return EMPTY;
            }),
          );
        }),
      )
      .subscribe();

    return () => sub.unsubscribe();
  }, [initialize$]);

  useEffect(() => {
    if (!queryPolling) {
      return;
    }

    const sub = timer(60000).subscribe({
      complete: () => {
        captureException(new Error("IDV inquiry creation timed out"));
        setIdvKey(null);
        setQueryPolling(false);
        stopPolling();
        onInquiryCreationFailure?.();
      },
    });

    return () => sub.unsubscribe();
  }, [queryPolling, onInquiryCreationFailure]);

  const initialize = useCallback(
    (data: ServiceInput) => {
      // Prevent emitting new values if the query is already polling or mutation loading
      if (mutationLoading || queryPolling) {
        return;
      }
      // Emit the new values to the initialize observable
      initialize$.next(data);
    },
    [mutationLoading, queryPolling, initialize$],
  );

  useEffect(() => {
    if (idvState?.inquiryId) {
      setQueryPolling(false);
      stopPolling();

      const vendor = getNamedSupportedVendor(idvState.vendor);

      return vendor
        ? onInquiryCreation({
            inquiryId: idvState.inquiryId,
            vendorSdkKey: idvState.vendorSdkKey,
            vendor,
          })
        : onInquiryCreationFailure?.();
    }
  }, [idvState?.inquiryId]);

  useEffect(() => {
    return () => {
      mutationCallRef.current = null;
    };
  }, []);

  return {
    initialize,
    loading: queryPolling || mutationLoading,
  };
}
