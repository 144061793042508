import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import Link from "common/core/link";
import Icon from "common/core/icon";
import Button from "common/core/button";
import { redirectToSubdomain } from "util/application_redirect";
import AppSubdomains from "constants/app_subdomains";
import { PROOF_WORKRAMP_REGISTER_URL } from "constants/support";
import { ESIGN_TRANSACTION_TYPE } from "constants/transaction";
import ProofAcademyImage from "assets/images/proof_academy.png";
import SignedDocumentImage from "assets/images/get_started/signed-document.svg";
import { NotaryCapacityType, type NotaryActivationStatus } from "graphql_globals";
import { EASYLINK_DASHBOARD_PATH } from "util/routes";
import WrInstantAuthQuery, {
  type WorkrampInstantAuthUrl_notaryProfile_NotaryProfile as NotaryProfile,
} from "common/notary/profile_wizard/section/wr_instant_auth_query.graphql";
import { LinkStyledButton } from "common/core/link/link_styled_button";
import { hardNavigateTo } from "util/navigation";
import { useLazyQuery } from "util/graphql/query";

import { LinkItem } from "../common/link_item";
import { HowToItem } from "../common/how_to_item";
import Styles from "./common.module.scss";
import MeetingStats from "./meeting_stats";

type NotaryProfileWithCapacity = {
  id: string;
  capacities: {
    type: NotaryCapacityType;
    status: NotaryActivationStatus;
    completedMeetingCount: number;
  }[];
  workrampInstantAuthDisabled?: boolean;
};

type Props = {
  notaryProfile: NotaryProfileWithCapacity;
};

const MESSAGES = defineMessages({
  altSend: {
    id: "c29a830f-f1f6-46f4-8ea1-c1fc437bd864",
    defaultMessage: "Send Transaction",
  },
  altJoinNod: {
    id: "bd66b7e2-f76c-48b1-bcfa-1ced4108a5a2",
    defaultMessage: "On-Demand Notary Network",
  },
  scheduledCalls: {
    id: "385aeb6c-c5b4-4a1c-9e29-385345e205c3",
    defaultMessage: "Your scheduled calls",
  },
});

export function SendClientMeetingsPanel({ notaryProfile }: Props) {
  const intl = useIntl();
  return (
    <div className={Styles.panel}>
      <div className={Styles.panelContentWrapper}>
        <MeetingStats
          meetingCount={
            notaryProfile.capacities.find(
              (c) => c.type === NotaryCapacityType.BRING_YOUR_OWN_TRANSACTIONS,
            )?.completedMeetingCount || 0
          }
          iconName="calendar"
          title={intl.formatMessage(MESSAGES.scheduledCalls)}
        />
        <footer>
          <Button
            buttonColor="action"
            variant="primary"
            buttonSize="large"
            onClick={() => redirectToSubdomain(AppSubdomains.business, "/transaction/new")}
          >
            <Icon className={Styles.sendClientIcon} name="notarize-filled" />
            <FormattedMessage
              id="196c8fda-9a20-41e7-ad93-a712af4af495"
              defaultMessage="Send new notarization request"
            />
          </Button>
          <Button
            buttonColor="action"
            variant="secondary"
            buttonSize="large"
            onClick={() =>
              redirectToSubdomain(AppSubdomains.business, "/transaction/new?type=ESIGN")
            }
          >
            <Icon className={Styles.sendClientIcon} name="sign" />
            <FormattedMessage
              id="f4d3a5a0-6461-4871-a22d-a49c07d4857c"
              defaultMessage="Send new eSignature request"
            />
          </Button>
        </footer>
      </div>
    </div>
  );
}

export function ProofAcademyItem({ notaryProfile }: Props) {
  // need to make this additional query because the WR instant auth url needs to be generated right at the time of use + expires after 2 mins
  const [fetchTrainingUrl, { loading }] = useLazyQuery(WrInstantAuthQuery, {
    fetchPolicy: "no-cache",
  });
  return (
    <HowToItem
      img={{ src: ProofAcademyImage, alt: "", className: Styles.academyImage }}
      title={
        <FormattedMessage
          id="7e135560-6a62-425e-a62f-260bebbfc58f"
          defaultMessage="Log in to your Proof Academy account"
        />
      }
      info={
        <FormattedMessage
          id="00bfaf31-e9e8-4699-9c66-f5177d364c40"
          defaultMessage="Log in to your Proof Academy account to access training courses, continuing education opportunities and updates to Proof functionality."
        />
      }
      links={
        <LinkStyledButton
          className={Styles.trainingLink}
          underlined={false}
          loading={loading}
          disabled={notaryProfile.workrampInstantAuthDisabled}
          onClick={() => {
            fetchTrainingUrl({ variables: { notaryProfileId: notaryProfile.id } }).then(
              (result) => {
                const notaryProfileWithWr = result.data?.notaryProfile as NotaryProfile;
                const trainingUrl =
                  notaryProfileWithWr.workrampInstantAuthUrl || PROOF_WORKRAMP_REGISTER_URL;
                hardNavigateTo(trainingUrl, { newTab: true });
              },
            );
          }}
        >
          <FormattedMessage
            id="26cc613c-597f-4700-b8e0-1d1d862c09b7"
            defaultMessage="{icon} Go to trainings"
            values={{ icon: <Icon name="arrow-up-right-square" /> }}
          />
        </LinkStyledButton>
      }
    />
  );
}

export function SendHowToItem() {
  return (
    <HowToItem
      img={{ src: SignedDocumentImage, alt: "" }}
      title={
        <FormattedMessage
          id="b0e603a2-f959-4172-bc21-67e31a91bcce"
          defaultMessage="Send your first document"
        />
      }
      info={
        <FormattedMessage
          id="e77a12d8-7973-42d7-bf2b-7e4c88fde9c4"
          defaultMessage="Pro tip: First try sending it to yourself to see how it works!"
        />
      }
      links={
        <>
          <Link onClick={() => redirectToSubdomain(AppSubdomains.business, "/transaction/new")}>
            <FormattedMessage
              id="bee5c751-7c6c-4216-b111-f4eae711a297"
              defaultMessage="Send a notarization request"
            />
          </Link>
          <Link
            onClick={() =>
              redirectToSubdomain(
                AppSubdomains.business,
                `/transaction/new?type=${ESIGN_TRANSACTION_TYPE}`,
              )
            }
          >
            <FormattedMessage
              id="4e314ccf-2c79-42dd-8ffb-603c31af2f98"
              defaultMessage="Send an eSign request"
            />
          </Link>
        </>
      }
    />
  );
}

export function EasylinkLinkItem() {
  return (
    <LinkItem
      title={
        <FormattedMessage
          id="1b7117c4-4149-4eb0-9c69-34ead1c05b63"
          defaultMessage="Try developer-free automation"
        />
      }
      info={
        <FormattedMessage
          id="19af601c-14b1-4e55-be62-72a5fd716147"
          defaultMessage="Create a link that can be sent to signers or added to your website in under 5 minutes."
        />
      }
      links={
        <Link onClick={() => redirectToSubdomain(AppSubdomains.business, EASYLINK_DASHBOARD_PATH)}>
          <FormattedMessage
            id="d477f9ed-ac78-469c-9966-fa0f7522d0b4"
            defaultMessage="Create an EasyLink"
          />
        </Link>
      }
    />
  );
}
