import { memo, type ComponentProps, type ReactNode } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import { MeetingEndedState } from "graphql_globals";
import { MeetingSidebarParty, MeetingSidebarPartyInfo } from "common/meeting/sidebar/party";
import StandardLocalPartyActions from "common/meeting/sidebar/party/actions";
import { useClearSigningAssets } from "common/meeting/delete_signings_mark";
import { StandardTrack } from "common/meeting/sidebar/party/track";
import PopoutMenuItem from "common/core/popout_menu/item";
import Pencil from "assets/images/gray_pencil.svg";

import MeetingQuery from "../index_query.graphql";

type Props = {
  muted: boolean;
  onToggleAVSettings: ComponentProps<typeof StandardLocalPartyActions>["toggleAvOpen"];
  onToggleMute: () => void;
  meeting: {
    id: string;
    endedState: MeetingEndedState;
  };
  party: {
    track: ReactNode;
    participants: { userId: string | null }[];
  };
};

const MESSAGES = defineMessages({
  popoutButtonLabel: {
    id: "fa3dd4cc-d234-43d3-a75c-a5aab4946d42",
    defaultMessage: "Expand your party controls",
  },
});

function LocalParty({
  muted,
  party: { track, participants },
  onToggleMute,
  meeting,
  onToggleAVSettings,
}: Props) {
  const { id: meetingId, endedState } = meeting;
  const intl = useIntl();
  const [{ userId }] = participants;
  const handleClearAssets = useClearSigningAssets(meetingId, MeetingQuery);

  return (
    <MeetingSidebarParty
      isLocal
      track={(trackProps) => (
        <StandardTrack
          muted={muted}
          onToggleMute={onToggleMute}
          track={track}
          isCompleted={endedState !== MeetingEndedState.NOT_COMPLETED}
          trackButtonAriaLabel={intl.formatMessage(MESSAGES.popoutButtonLabel)}
          popoutMenuProps={{
            children: () => (
              <>
                <StandardLocalPartyActions
                  toggleAvOpen={onToggleAVSettings}
                  videoCondensed={trackProps.videoCondensed}
                  toggleVideoCondensed={trackProps.toggleVideoCondensed}
                />
                <PopoutMenuItem
                  onClick={() => {
                    userId && handleClearAssets(userId);
                  }}
                  backgroundImage={Pencil}
                >
                  <FormattedMessage
                    id="597de71a-0111-42e7-a88e-e240c815d821"
                    description="Button text for remote witness to clear their signature configuration in meeting"
                    defaultMessage="Reset signature and initials"
                  />
                </PopoutMenuItem>
              </>
            ),
          }}
        />
      )}
    >
      <MeetingSidebarPartyInfo
        header={
          <FormattedMessage
            id="69f278bf-451b-408e-9057-5d35098f3202"
            defaultMessage="Your video feed"
          />
        }
      />
    </MeetingSidebarParty>
  );
}

export default memo(LocalParty);
