import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import Env from "config/environment";
import { Badge } from "common/core/badge";
import { BinaryToggle } from "common/core/form/binary_toggle";
import type { IDVInteraction } from "common/identity_verification/interaction";

import Styles from "./index.module.scss";

const MESSAGES = defineMessages({
  failVerificationsToggle: {
    id: "2e1122c9-04e7-4bfb-bf7f-6d2539ba1b9a",
    defaultMessage: "Fail verifications",
  },
});

export function DevelopmentOptions({ idvInteraction }: { idvInteraction: IDVInteraction }) {
  const { isCustomerFacingTestEnvironment, hasTestingEnvironmentWarning } = Env;
  const intl = useIntl();

  if (isCustomerFacingTestEnvironment || !hasTestingEnvironmentWarning) {
    return null;
  }

  return (
    <div className={Styles.developmentOptions}>
      <Badge kind="infoSubtle">
        <FormattedMessage id="11f03782-254e-44ba-bd8c-bbf4b1be02a8" defaultMessage="Development" />
      </Badge>
      <div className={Styles.developmentOptionsItem}>
        <BinaryToggle
          automationId="fail-verifications-toggle"
          label={intl.formatMessage(MESSAGES.failVerificationsToggle)}
          onChange={(newValue) => {
            idvInteraction.onConfigurationChange({
              simulateFailures: newValue,
            });
          }}
          value={Boolean(idvInteraction.configuration.simulateFailures)}
        />
      </div>
    </div>
  );
}
