import type { ComponentProps } from "react";
import { FormattedMessage } from "react-intl";

import AlertMessage from "common/core/alert_message";

import { PS1583ProofingResource } from "./ps1583_proofing_resource";
import Styles from "./index.module.scss";

export function Footer({
  showProofingResource,
  ...proofingResourceProps
}: ComponentProps<typeof PS1583ProofingResource> & { showProofingResource: boolean }) {
  return (
    <>
      <AlertMessage kind="transparent" customIcon="lock" className={Styles.privacyBlurb}>
        <FormattedMessage
          id="aab6d59a-cdb5-4707-9ff0-44995b05afdd"
          defaultMessage="Your privacy is important to us. All data is encrypted and securely stored. Images captured are used for verification purposes only."
        />
      </AlertMessage>

      {showProofingResource && <PS1583ProofingResource {...proofingResourceProps} />}
    </>
  );
}
