import { useEffect, useMemo, useRef } from "react";
import { Client, type ClientOptions } from "persona";

import { useFeatureFlag } from "common/feature_gating";
import Env from "config/environment";

const { personaEnvironment } = Env;

export enum DeprecatedTemplates {
  DYNAMIC_MOBILE_RETAKE_TEMPLATE_ID = "itmpl_1FeHE3WGFrGnGSTAcMQfFFQi",
  DYNAMIC_AUTOPASS_TEMPLATE = "itmpl_FJRq27meZ7tyVrhfw3RdvAyQ",
  DYNAMIC_SINGLE_ID_AND_SUPPLEMENTAL_TEMPLATE = "itmpl_BCDE4vKSKnKrMqe5AxRwJSor",
  DYNAMIC_FOREIGN_ID_ACCEPTED_TEMPLATE = "itmpl_e4vUPuRszEQG1qbika1AKPhi",
  DYNAMIC_PS1583_TEMPLATE = "itmpl_qGEVBUvxka72cviFxKk6imx2",
  DYNAMIC_SINGLE_ID_TEMPLATE = "itmpl_jHeQNqqNgWEpTTiL8f1jEepm",
  DYNAMIC_BIOMETRIC_TEMPLATE = "itmpl_dDZaQxZk3B7eusUabo7hc5aw",
}

export enum Templates {
  BIOMETRIC_VERIFICATION = "itmpl_C12hL79DGHwv9KfdyYQCLwzx",
  BIOMETRIC_VERIFICATION_DESKTOP_ONLY = "itmpl_VMLuzurxEjA9RRvxkKj12oBe",
  GOV_ID_MOBILE_ONLY = "itmpl_vFXyarubJL9SrinmH7Ph5aXx",
  GOV_ID_DESKTOP_ONLY = "itmpl_n26hW4reBcTmLyCkKz8jgtZR",
  AUTO_PASS_MOBILE_ONLY = "itmpl_barrpXEapPD86xAfcedjZELk",
  AUTO_PASS_DESKTOP_ONLY = "itmpl_W9fkVzNEiWQC9wZfvyD4DEms",
}

const TrackedPersonaErrors = ["load-camera-failed", "gpu-out-of-memory"];

export type CurrentParticipant = {
  dob: string | null;
  firstName: string | null;
  lastName: string | null;
  email?: string | null;
  id: string;
  personallyKnownToNotary?: boolean;
  witnessedSigner?: boolean;
  requiresBiometrics?: boolean;
};

export type Options = ClientOptions;
export type Fields = Options["fields"];

type DyanmicTemplateProps = {
  templateId: DeprecatedTemplates | Templates;
  fields?: Fields;
};

type InteractionProps = DyanmicTemplateProps & {
  currentParticipant: CurrentParticipant;
  referenceId: Options["referenceId"];
  onComplete: Options["onComplete"];
  onCancel?: Options["onCancel"];
  inquiryId?: Options["inquiryId"];
};

/** Creates a Persona client per referenceId. Does not create a new client if onComplete changes */
export function usePersonaClient(props: InteractionProps) {
  const { inquiryId, fields, referenceId, templateId, currentParticipant, onComplete, onCancel } =
    props;

  const environment = useFeatureFlag<Options["environment"]>("persona-environment", "production");

  const onCancelRef = useRef<typeof onCancel | null>(onCancel);
  const onCompleteRef = useRef<typeof onComplete | null>(onComplete);

  useEffect(() => {
    onCancelRef.current = onCancel;
    onCompleteRef.current = onComplete;
    return () => {
      onCancelRef.current = null;
      onCompleteRef.current = null;
    };
  });

  const client = useMemo(() => {
    const { firstName, lastName, dob } = currentParticipant;

    const baseFields = {
      ...(firstName && { nameFirst: firstName }),
      ...(lastName && { nameLast: lastName }),
      ...(dob && { birthdate: dob }),
    };

    const clientProps = inquiryId
      ? { inquiryId }
      : { referenceId, templateId, fields: { ...baseFields, ...fields } };

    return new Client({
      environmentId: personaEnvironment || environment,
      ...clientProps,
      onError: (metadata) => {
        // eslint-disable-next-line no-console
        console.log("Persona SDK error", {
          metadata,
        });
      },
      onEvent: (eventName, metadata) => {
        if (TrackedPersonaErrors.includes(eventName)) {
          // eslint-disable-next-line no-console
          console.log("Persona SDK event", {
            eventName,
            ...metadata,
          });
        }
      },
      onComplete: (...args) => onCompleteRef.current?.(...args),
      onCancel: (...args) => onCancelRef.current?.(...args),
    });
  }, [inquiryId, referenceId, templateId, fields]);

  useEffect(() => {
    return () => {
      client.cancel(true);
      client.destroy();
    };
  }, [client]);

  return client;
}
