import type { ReactNode } from "react";

import type { PresentNode } from "common/core/form/option";

import Styles from "./device_option.module.scss";

type Props = {
  badge?: ReactNode;
  radio: PresentNode;
  label: PresentNode;
  "data-automation-id"?: string;
};

export function DeviceOption({
  badge,
  radio,
  label,
  "data-automation-id": dataAutomationId,
}: Props) {
  return (
    <label className={Styles.deviceOption} data-automation-id={dataAutomationId}>
      {badge}
      {label}
      {radio}
    </label>
  );
}
