import { memo, useState, useCallback, type ComponentProps } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import {
  Feature,
  type NotarialActs,
  type LooseLeafCertificates,
  type PageTypes,
} from "graphql_globals";
import { getCurrentDocumentNode, getCurrentPenholderInSignerParties } from "common/meeting/util";
import { REQUEST_IN_MEETING_DOCUMENT_UPLOAD } from "constants/signer_capabilities";
import Icon from "common/core/icon";
import Button from "common/core/button";
import { useJumpToLooseLeaf } from "common/meeting/scroll";
import DocumentLockControls from "common/meeting/notary/document/navigator/lock";
import SignerActionsCount from "common/meeting/notary/document/navigator/signer_actions_count";
import type { NotaryMeeting_viewer_user as NotaryUser } from "common/meeting/notary/meeting_query.graphql";
import { useGraphicCache } from "common/meeting/context/graphic_cache";
import { usePDFContext } from "common/pdf/pspdfkit";
import { IconButton } from "common/core/button/icon_button";

import Styles from "./index.module.scss";
import DocumentListing from "./document";
import InMeetingRequestUploadButton, {
  useDocumentUploadNotification,
} from "./request_in_meeting_upload";
import {
  AddLooseLeafButton,
  useLooseLeafMutateFn,
  useAvailableLooseLeafs,
  getNotarialActForPageType,
} from "./loose_leaf";
import type { NotaryMeetingBundleNav as Meeting } from "./index_fragment.graphql";
import { useMutationCallbacks, useSealMutationCallback } from "../annotation";
import { quickStampForNonPreprinted } from "..";
import LooseLeafModal from "../../navigator/loose_leaf_modal/loose_leaf_modal";

type ListingProps = ComponentProps<typeof DocumentListing>;
type Props = {
  meeting: Meeting;
  notaryUser: NotaryUser;
  notaryUsStateName: string;
  notarialActLooseLeafMapping: Parameters<typeof useAvailableLooseLeafs>[0];
  onSelectDocument: (options: {
    id: string;
    name: null | string;
    autoNavigateAfterLock?: boolean;
  }) => void;
  onSelectLooseLeaf: ListingProps["onSelectLooseLeaf"];
  quickstampHelpState:
    | null
    | { type: "pre-placement-help" }
    | { type: "does-it-fit"; annotationIds: string[] };
  onQuickstampHelpMoveToLooseLeaf: (annotationIds: string[]) => undefined | NotarialActs;
  onQuickstampHelpExit: () => void;
};

const AUTO_QUICK_STAMP_POINT = { x: 10, y: 540 };

function BundleNavigator({
  meeting,
  notaryUser,
  notaryUsStateName,
  onSelectLooseLeaf,
  onSelectDocument,
  notarialActLooseLeafMapping,
  quickstampHelpState,
  onQuickstampHelpMoveToLooseLeaf,
  onQuickstampHelpExit,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [LLPModalOpen, setLLPModalOpen] = useState<boolean | { initLLPSelection: NotarialActs }>(
    false,
  );
  const currentDocumentNode = getCurrentDocumentNode(meeting);
  const currentDocumentId = currentDocumentNode.id;
  const { name, versionedLooseLeafCertificates } = currentDocumentNode;
  const looseLeafMutateFn = useLooseLeafMutateFn(meeting);
  useDocumentUploadNotification(onSelectDocument);
  const jumpToLooseLeaf = useJumpToLooseLeaf(currentDocumentId);
  const handleSealMutateFn = useSealMutationCallback();
  const mutateCallbacks = useMutationCallbacks();
  const cache = useGraphicCache();
  const { setFocused } = usePDFContext();
  const meetingParticipants = meeting.meetingParticipants;
  const signerParticipants = meetingParticipants.filter(
    (p) =>
      p.__typename === "SignerParticipant" || p.__typename === "IdentityVerifiedWitnessParticipant",
  );
  const bundleParticipants = meeting.documentBundle!.participants!;

  const handleSaveLLPsAndAutoQuickStamp: ComponentProps<typeof LooseLeafModal>["onSave"] = async (
    selectedLLPs,
    newLLPs,
    acknowledgementType,
  ) => {
    await looseLeafMutateFn(new Set(Array.from(selectedLLPs.keys())));
    setLLPModalOpen(false);
    if (!newLLPs.length) {
      return;
    }

    jumpToLooseLeaf({
      documentId: currentDocumentId,
      pageType: newLLPs[0] as unknown as PageTypes,
    });

    for (const newLLP of newLLPs) {
      const principals = selectedLLPs.get(newLLP);
      const notarialAct = getNotarialActForPageType(notarialActLooseLeafMapping, newLLP);
      const args = {
        meetingId: meeting.id,
        documentId: currentDocumentId,
        location: {
          pageType: newLLP as unknown as PageTypes,
          pageIndex: 0,
          point: AUTO_QUICK_STAMP_POINT,
        },
      };

      const activeSignerParticipant = getCurrentPenholderInSignerParties(meeting);
      const principalsIds = meetingParticipants
        .filter((participant) => participant.userId !== null && principals?.has(participant.userId))
        .map((participant) => participant.id);
      // eslint-disable-next-line no-await-in-loop
      await quickStampForNonPreprinted({
        args,
        activeSignerParticipant,
        meetingParticipants,
        notarialAct,
        acknowledgementType,
        principals: principalsIds,
        notaryUser,
        notaryUsStateName,
        cache,
        setFocused,
        handleSealMutateFn,
        mutateCallbacks,
      });
    }
  };

  const handleRemoveLooseLeaf = useCallback(
    ({ actType }: { actType: LooseLeafCertificates }) => {
      const newSelectedLLPs = new Set(
        versionedLooseLeafCertificates.map((llp) => llp.actType).filter((type) => type !== actType),
      );
      return looseLeafMutateFn(newSelectedLLPs);
    },
    [versionedLooseLeafCertificates],
  );
  const activeSignerPartyParticipant = getCurrentPenholderInSignerParties(meeting);
  const orgInMeetingUploadFeatureEnabled =
    meeting.documentBundle?.organizationTransaction.organization.featureList.includes(
      Feature.IN_MEETING_DOCUMENT_UPLOAD,
    );

  const showInMeetingRequestButton =
    orgInMeetingUploadFeatureEnabled ||
    (meeting.documentBundle!.canUpdate &&
      activeSignerPartyParticipant.__typename === "SignerParticipant" &&
      meeting.signerCapabilities!.includes(REQUEST_IN_MEETING_DOCUMENT_UPLOAD));
  const handleLock = (lockedDocument: { id: string; __typename: "Document" }) => {
    type PossibleDoc = undefined | (typeof documentEdges)[number];
    const documentEdges = meeting.documentBundle!.documents.edges;
    const curDocumentIndex = documentEdges.findIndex((e) => e.node.id === currentDocumentNode.id);
    const curDocument = documentEdges[curDocumentIndex] as PossibleDoc;
    if (!curDocument || curDocument.node.id !== lockedDocument.id) {
      // If notary has already navigated early, don't do anything
      return;
    }
    const nextDocument = documentEdges[curDocumentIndex + 1] as PossibleDoc;
    if (nextDocument) {
      const { id, name } = nextDocument.node;
      onSelectDocument({ id, name, autoNavigateAfterLock: true });
    }
  };

  const availableLooseLeafs = useAvailableLooseLeafs(notarialActLooseLeafMapping, meeting);

  return (
    <header>
      <div className={Styles.navigator}>
        <nav className={Styles.controls}>
          <button
            type="button"
            onClick={() => setIsOpen((o) => !o)}
            className={classnames(Styles.dropdown, isOpen && Styles.isOpen)}
          >
            {name}
            <Icon name="caret-down" />
          </button>
          <AddLooseLeafButton
            disabled={!availableLooseLeafs.length}
            onClick={() => setLLPModalOpen(true)}
          />
          {LLPModalOpen && (
            <LooseLeafModal
              initLLP={
                LLPModalOpen === true
                  ? undefined
                  : (LLPModalOpen.initLLPSelection as unknown as LooseLeafCertificates)
              }
              availableLooseLeafs={availableLooseLeafs}
              currentDocumentNode={currentDocumentNode}
              onClose={() => setLLPModalOpen(false)}
              onSave={handleSaveLLPsAndAutoQuickStamp}
              signerParticipants={signerParticipants}
              bundleParticipants={bundleParticipants}
            />
          )}
          {showInMeetingRequestButton && "signerIdentityId" in activeSignerPartyParticipant && (
            <InMeetingRequestUploadButton
              meetingId={meeting.id}
              activeParticipantId={activeSignerPartyParticipant.signerIdentityId}
            />
          )}
          <SignerActionsCount
            currentDocumentNode={currentDocumentNode}
            meetingParticipants={meeting.meetingParticipants}
          />
          <DocumentLockControls meeting={meeting} onLock={handleLock} />
        </nav>
        {isOpen && (
          <div className={Styles.documentListingWrapper}>
            <DocumentListing
              meeting={meeting}
              onSelectDocument={onSelectDocument}
              onRemoveLooseLeaf={handleRemoveLooseLeaf}
              onSelectLooseLeaf={onSelectLooseLeaf}
            />
          </div>
        )}
      </div>
      {quickstampHelpState && (
        <div className={Styles.quickstampHelp}>
          <Icon name="info" />
          {quickstampHelpState.type === "pre-placement-help" ? (
            <FormattedMessage
              id="a9be2e38-d55e-4e4f-acc5-0fa55765818e"
              defaultMessage="Make sure Quick Stamp doesn't overlap any contents of the document"
              tagName="p"
            />
          ) : (
            <>
              <FormattedMessage
                id="8c8d785d-8f2b-4d3a-86e0-2132168a14b8"
                defaultMessage="Is there enough room for the Quick Stamp?"
                tagName="p"
              />
              <Button buttonColor="action" variant="primary" onClick={onQuickstampHelpExit}>
                <FormattedMessage id="9a4f1338-dd2d-474f-b2fd-54f136508da4" defaultMessage="Yes" />
              </Button>
              <Button
                variant="tertiary"
                buttonColor="action"
                onClick={() => {
                  const initLLPSelection = onQuickstampHelpMoveToLooseLeaf(
                    quickstampHelpState.annotationIds,
                  );
                  setLLPModalOpen(initLLPSelection ? { initLLPSelection } : true);
                }}
              >
                <FormattedMessage
                  id="524f3d1b-a2f8-4f4c-94f8-2c0c81fc6d5c"
                  defaultMessage="No, move to loose leaf"
                />
              </Button>
              <IconButton
                name="x-mark"
                onClick={onQuickstampHelpExit}
                label={
                  <FormattedMessage
                    id="7eed44ce-35d7-4dd6-b779-1944b45b033a"
                    defaultMessage="Quickstamp help exit"
                  />
                }
                variant="tertiary"
                color="action"
              />
            </>
          )}
        </div>
      )}
    </header>
  );
}

export default memo(BundleNavigator);
