import { useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import Button from "common/core/button";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_SUBTYPES } from "constants/notifications";
import WorkflowModal from "common/modals/workflow_modal";
import { segmentTrack } from "util/segment";
import { useMutation } from "util/graphql";

import BiometricConsentCopyDisplay from "./biometric_consent_copy_display";
import type { BiometricConsentInfoTransaction } from "./biometric_consent_info_transaction.fragment.graphql";
import RecordBiometricConsentVersionMutation from "./biometric_consent.mutation.graphql";
import Styles from "./biometric_consent_modal.module.scss";

const MESSAGES = defineMessages({
  recordBiometricConsentError: {
    id: "bd6a9761-717a-4a2e-9676-9ea0aea5bd77",
    defaultMessage:
      "Error recording biometric consent. Please try again. If the error persists, please contact support.",
  },
});

export type Props = {
  signerIdentityId: string;
  transaction: BiometricConsentInfoTransaction;
  onAccept: () => void;
  onClose: () => void;
};

export default function BiometricConsentModal({
  signerIdentityId,
  transaction,
  onAccept,
  onClose,
}: Props) {
  const intl = useIntl();
  const recordBiometricConsent = useMutation(RecordBiometricConsentVersionMutation);
  const [recordingConsent, setRecordingConsent] = useState(false);

  const handleClose = () => {
    segmentTrack("[biometric-consent-modal] closed without consent");
    onClose();
  };

  const handleAccept = async () => {
    try {
      segmentTrack("[biometric-consent-modal] accepted");
      setRecordingConsent(true);
      await recordBiometricConsent({
        variables: {
          input: {
            systemVersionId: transaction.biometricConsentCopy.systemVersionId!,
            addendumVersionId: transaction.biometricConsentCopy.addendumVersionId,
            organizationId: transaction.publicOrganization.id,
            organizationTransactionId: transaction.id,
            signerIdentityId,
          },
        },
      });
      onAccept();
    } catch {
      pushNotification({
        subtype: NOTIFICATION_SUBTYPES.ERROR,
        position: "topCenter",
        message: intl.formatMessage(MESSAGES.recordBiometricConsentError),
      });
    } finally {
      setRecordingConsent(false);
    }
  };

  return (
    <WorkflowModal
      className={Styles.modal}
      closeBehavior={{
        tag: "with-button",
        onClose: handleClose,
        disableClickOutside: true,
      }}
      buttons={[
        <Button
          key="accept-button"
          variant="primary"
          buttonColor="action"
          fullwidth
          isLoading={recordingConsent}
          onClick={handleAccept}
        >
          <FormattedMessage id="d858bcd2-fe5c-4197-8340-0639415005b0" defaultMessage="Accept" />
        </Button>,
      ]}
      footerSeparator={false}
      autoFocus
    >
      <BiometricConsentCopyDisplay transaction={transaction} />
    </WorkflowModal>
  );
}
