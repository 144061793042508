import { FormattedMessage } from "react-intl";
import type { ReactNode } from "react";

import AlertMessage from "common/core/alert_message";
import { LinkStyledButton } from "common/core/link/link_styled_button";

import Styles from "./index.module.scss";

export function PS1583ProofingResource({
  isAttestation,
  pdfSrc,
  onShowDocument,
}: {
  isAttestation: boolean;
  pdfSrc?: string | null;
  onShowDocument: () => void;
}) {
  return (
    <AlertMessage className={Styles.alertBanner} kind="info">
      <div>
        <FormattedMessage
          id="e5995220-f1ed-4029-8667-b2b1bfe0449d"
          defaultMessage="<b>The Form 1583 requires two unexpired forms of identification.</b> At least one form of identification MUST have an address that matches the address in box {boxNumbers} on the form. <a>View document</a>"
          values={{
            boxNumbers: isAttestation ? "4f-4j or 9b-9f" : "7 or 10",
            b: (msg: ReactNode[]) => <strong>{msg}</strong>,
            a: (msg: ReactNode[]) =>
              pdfSrc && (
                <LinkStyledButton
                  secondary
                  onClick={onShowDocument}
                  style={{ display: "inline-block" }}
                >
                  {msg}
                </LinkStyledButton>
              ),
          }}
        />
      </div>
    </AlertMessage>
  );
}
