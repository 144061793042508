import "./index.scss";

import {
  Children,
  useState,
  useCallback,
  useEffect,
  type ReactNode,
  type ReactElement,
} from "react";
import classnames from "classnames";

import PlainModal from "common/modals/plain_modal";
import { segmentTrack } from "util/segment";
import { deprecatedInteractingWithInput } from "common/form/util";
import { Heading } from "common/core/typography";

import v2Styles from "./v2.module.scss";

type SlideElement = ReactElement<SlideProps, typeof Slide>;
type SlideFragment = {
  props: { children: SlideElement[] };
};
type SlideProps = {
  canSkip?: boolean;
  children: ReactNode;
  useV2styling?: boolean;
  v2Heading?: ReactNode;
  v2StepLabel?: ReactNode;
};
type CarouselRenderProps = {
  onBack: () => void;
  onNext: () => void;
  forceNext: () => void;
};
type CarouselProps = {
  disableNavigation?: boolean;
  initialSlide?: number;
  numberOfSlides: number;
  automationId?: string;
  hidePagination?: boolean;
  onFinalSlideNext?: () => void;
  analyticsPrefix?: string;
  analyticsProps?: Record<string, unknown>;
  /** Note: The fragment returned here should only contain <Slide /> children */
  children: (params: CarouselRenderProps) => SlideFragment;
  useV2styling?: boolean;
};
type CarouselPaginationProps = {
  numberOfSlides: number;
  currentSlide: number;
  setSlide: (slide: number) => void;
  disableNavigation?: boolean;
  useV2styling?: boolean;
};

export function Slide({ children, useV2styling }: SlideProps) {
  return <div className={`Slide${useV2styling ? "" : " Slide--legacy"}`}>{children}</div>;
}

function trackPageChange({
  page,
  fromKeyboard,
  analyticsPrefix,
}: {
  page: number;
  fromKeyboard?: boolean;
  analyticsPrefix?: string;
}) {
  if (analyticsPrefix) {
    segmentTrack(`${analyticsPrefix} Page Change`, { page, fromKeyboard });
  }
}

function CarouselPagination(props: CarouselPaginationProps) {
  const { numberOfSlides, currentSlide, setSlide, disableNavigation, useV2styling } = props;

  return (
    <ul className={useV2styling ? v2Styles.carouselPagination : "Carousel--pagination--list"}>
      {Array.from({ length: numberOfSlides }).map((_x, i) => {
        const cx = useV2styling
          ? `${v2Styles.carouselListItem} ${
              i === currentSlide ? v2Styles.carouselListItemActive : ""
            }`
          : classnames("Carousel--pagination--list--item", {
              "Carousel--pagination--list--item__active": i === currentSlide,
            });
        return (
          <li
            key={i}
            className={cx}
            data-automation-id={`carousel-item-${i}`}
            onClick={() => {
              if (disableNavigation) {
                return;
              }

              setSlide(i);
            }}
          />
        );
      })}
    </ul>
  );
}

export default function Carousel(props: CarouselProps) {
  const {
    children: childrenFunc,
    initialSlide: initialSlideProp,
    disableNavigation,
    onFinalSlideNext,
    numberOfSlides,
    hidePagination,
    automationId,
    analyticsPrefix,
    analyticsProps,
    useV2styling,
  } = props;

  const initialSlide = initialSlideProp
    ? Math.min(Math.max(0, initialSlideProp), numberOfSlides - 1)
    : 0;

  const [currentSlide, setCurrentSlide] = useState(initialSlide);
  const handleOnBack = useCallback(
    ({ fromKeyboard = false } = {}) => {
      if (disableNavigation) {
        return;
      }
      const targetSlide = Math.max(0, currentSlide - 1);
      trackPageChange({ page: targetSlide, fromKeyboard, analyticsPrefix });
      setCurrentSlide(targetSlide);
    },
    [currentSlide, disableNavigation, analyticsPrefix],
  );

  const forceNext = useCallback(
    ({ fromKeyboard = false } = {}) => {
      const nextSlide = currentSlide + 1;
      if (nextSlide > numberOfSlides - 1) {
        return onFinalSlideNext?.();
      }
      const targetSlide = Math.min(numberOfSlides - 1, nextSlide);
      trackPageChange({ page: targetSlide, fromKeyboard, analyticsPrefix });
      setCurrentSlide(targetSlide);
    },
    [numberOfSlides, currentSlide, onFinalSlideNext, analyticsPrefix],
  );

  const handleOnNext = useCallback(
    ({ fromKeyboard = false } = {}) => {
      if (disableNavigation) {
        return;
      }
      return forceNext({ fromKeyboard });
    },
    [disableNavigation, numberOfSlides, currentSlide, onFinalSlideNext, analyticsPrefix],
  );

  const childFragment = childrenFunc({
    onNext: handleOnNext,
    onBack: handleOnBack,
    forceNext,
  });
  const slide = Children.toArray(childFragment.props.children)[currentSlide];
  const { canSkip, v2Heading, v2StepLabel } = (slide as SlideElement).props;

  useEffect(() => {
    if (disableNavigation) {
      return;
    }
    const handleKeyUp = (event: KeyboardEvent) => {
      if (deprecatedInteractingWithInput()) {
        return;
      }

      if (event.key === "ArrowLeft") {
        handleOnBack({ fromKeyboard: true });
      }
      if (event.key === "ArrowRight" && canSkip) {
        handleOnNext({ fromKeyboard: true });
      }
    };
    window.document.addEventListener("keyup", handleKeyUp);

    return () => {
      window.document.removeEventListener("keyup", handleKeyUp);
    };
  }, [handleOnBack, handleOnNext, canSkip, disableNavigation]);

  const cx = classnames("Carousel", {
    "Carousel__no-pagination": hidePagination,
  });

  useEffect(() => {
    if (analyticsPrefix) {
      segmentTrack(`${analyticsPrefix} Modal Opened`, analyticsProps);
    }
  }, []);

  return useV2styling ? (
    <PlainModal size="medium" overlayBackground="blur">
      <div>
        <div
          className={`${v2Styles.carouselHeading} ${
            hidePagination ? v2Styles.carouselHeadingNoPagination : ""
          }`}
        >
          <Heading level="h1" textStyle="headingFive">
            {v2Heading}
          </Heading>
        </div>
        {!hidePagination && (
          <CarouselPagination
            currentSlide={currentSlide}
            setSlide={setCurrentSlide}
            numberOfSlides={numberOfSlides}
            disableNavigation={disableNavigation}
            useV2styling
          />
        )}
        <Heading level="h2" textStyle="allCapsLabelSmall" className={v2Styles.carouselStepLabel}>
          {v2StepLabel}
        </Heading>
        <div>{slide}</div>
      </div>
    </PlainModal>
  ) : (
    <PlainModal size="large" overlayBackground="blur">
      <div className={cx} data-automation-id={automationId}>
        <div className="Carousel--container">{slide}</div>
        {!hidePagination && (
          <div className="Carousel--pagination">
            <CarouselPagination
              currentSlide={currentSlide}
              setSlide={setCurrentSlide}
              numberOfSlides={numberOfSlides}
              disableNavigation={disableNavigation}
            />
          </div>
        )}
      </div>
    </PlainModal>
  );
}
