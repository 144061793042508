import type { ComponentProps } from "react";

import { Feature } from "graphql_globals";
import { onlyRequiresEsign } from "util/completion_requirements/completion_requirements_text";
import TransactionDetails from "common/transactions/details";

type DetailsProps = ComponentProps<typeof TransactionDetails>;
type LoadedParams = Parameters<NonNullable<DetailsProps["loadedMapFn"]>>;

function mapPropsOntoDetails({ node: transaction }: LoadedParams[0]) {
  // after transaction is cancelled node will be null
  // this will cause errors since this component gets the updated node value before the app navigates back to the dashboard
  if (!transaction) {
    return null;
  }
  const { featureList } = transaction.publicOrganization;
  const notesSupported = featureList.includes(Feature.NOTARY_NOTES);
  return {
    withNotes: notesSupported && !onlyRequiresEsign(transaction.bundle!.completionRequirements),
    withActivityLog: featureList.includes(Feature.TRANSACTION_RECORD_FULL_ACCESS),
  };
}

function BusinessTransactionDetails(props: Omit<DetailsProps, "loadedMapFn">) {
  return <TransactionDetails {...props} loadedMapFn={mapPropsOntoDetails} />;
}

export default BusinessTransactionDetails;
