import "common/notary/profile_wizard/section/index.scss";

import { memo, useMemo, type ReactNode } from "react";

import { NotaryComplianceStatuses, NotaryOnboardingStatuses } from "graphql_globals";
import StateRequiredEducation, {
  stateEducationSection,
} from "common/notary/profile_wizard/section/state_required_education";
import type { MaybeUpdateUserFn } from "common/notary/onboarding";
import CommissionDetails, {
  commissionDetailsSection,
} from "common/notary/profile_wizard/section/commission_details";
import InsuranceDetails, {
  insuranceDetailsSection,
} from "common/notary/profile_wizard/section/insurance_details";
import DigitalCertificate, {
  certificateSection,
} from "common/notary/profile_wizard/section/digital_certificate";
import SignatureAndSeal, {
  sigAndSealSection,
} from "common/notary/profile_wizard/section/signature_and_seal";
import PayoutOptions, { payoutSection } from "common/notary/profile_wizard/section/payout";
import PersonalInformationSection, {
  personalInfoSection,
} from "common/notary/profile_wizard/section/personal_information";
import Footer from "common/notary/onboarding/footer";
import IdentityVerification, {
  identityVerificationSection,
} from "common/notary/profile_wizard/section/identity_verification";
import ProofCertificate, {
  proofCertificateSection,
} from "common/notary/profile_wizard/section/digital_certificate/proof";
import OnboardingOverview from "common/notary/onboarding/overview";
import { useOnboardingV2 } from "util/feature_detection";
import { proofAcademySection } from "common/notary/profile_wizard/section/proof_academy";

import type {
  BusinessNotaryProfileOnboardingStep as User,
  BusinessNotaryProfileOnboardingStep_notaryProfile as NotaryProfile,
} from "./index_fragment.graphql";
import type { Step } from "../sidebar";

type StepProps = {
  user: User;
  activeStep?: Section;
  onNext: MaybeUpdateUserFn;
  onBack?: () => void;
  renderUnknownStep: () => ReactNode;
  refetch: () => void;
  sections: readonly Step[];
};

type MaybeSection =
  | ReturnType<typeof personalInfoSection>
  | ReturnType<typeof commissionDetailsSection>
  | ReturnType<typeof certificateSection>
  | ReturnType<typeof insuranceDetailsSection>
  | ReturnType<typeof payoutSection>
  | ReturnType<typeof stateEducationSection>
  | ReturnType<typeof sigAndSealSection>
  | ReturnType<typeof identityVerificationSection>
  | ReturnType<typeof proofCertificateSection>
  | ReturnType<typeof proofAcademySection>
  | ProfileApprovalSectionType;
type Section = Exclude<MaybeSection, false>;

function filterSections(maybeSection: MaybeSection): maybeSection is Section {
  return Boolean(maybeSection);
}

type ProfileApprovalSectionId = "ProfileApproval";

type ProfileApprovalSectionType = {
  id: ProfileApprovalSectionId;
  completed: boolean;
  isActive: boolean;
};

const profileApprovalSection = (notaryProfile: NotaryProfile): ProfileApprovalSectionType => {
  return {
    id: "ProfileApproval",
    completed: notaryProfile.complianceStatus === NotaryComplianceStatuses.COMPLIANT,
    isActive: false,
  };
};

export function useOnboardingSections(user: User, activeSectionId?: string) {
  const notaryProfile = user.notaryProfile!;
  const migrateExpiringNotaries = notaryProfile.usState.proofCertEnabled;
  const onboardingV2 = useOnboardingV2();

  const { sections, activeSection } = useMemo(() => {
    const lookup = new Set(notaryProfile.requirements);
    const sections: MaybeSection[] = [
      personalInfoSection(lookup, user),
      commissionDetailsSection(lookup, notaryProfile),
      insuranceDetailsSection(lookup, notaryProfile),
      certificateSection(lookup, notaryProfile, migrateExpiringNotaries),
      stateEducationSection(lookup, notaryProfile),
      sigAndSealSection(lookup, notaryProfile),
      payoutSection(user),
      identityVerificationSection(notaryProfile, migrateExpiringNotaries),
      proofCertificateSection(notaryProfile, migrateExpiringNotaries),
    ];

    if (onboardingV2) {
      sections.push(proofAcademySection(notaryProfile));
      sections.push(profileApprovalSection(notaryProfile));
    }

    const processedSections = sections.filter(filterSections).map((section) =>
      Object.freeze({
        ...section,
        isActive: activeSectionId === section.id,
      }),
    );

    const activeSection = processedSections.find((section) => section.isActive);
    return { sections: Object.freeze(processedSections), activeSection };
  }, [activeSectionId, user, notaryProfile, onboardingV2]);

  return {
    sections,
    activeSection,
  };
}

function renderContent({
  activeStep,
  user,
  onNext,
  onBack,
  renderUnknownStep,
  refetch,
  sections,
}: StepProps) {
  const paused = user.notaryProfile?.onboardingStatus === NotaryOnboardingStatuses.PAUSED;
  switch (activeStep?.id) {
    case "PersonalInfo":
      return (
        <PersonalInformationSection
          user={user}
          onNext={onNext}
          renderFooter={(handleSubmit) => <Footer onSubmit={handleSubmit} workToCommit />}
        />
      );
    case "CommissionDetails":
      return (
        <CommissionDetails
          user={user}
          onNext={onNext}
          countyRequired={activeStep.countyRequired}
          notaryIdRequired={activeStep.notaryIdRequired}
          certificateOfAuthRequired={activeStep.certificateOfAuthRequired}
          renderFooter={(handleSubmit) => (
            <Footer onBack={onBack} onSubmit={handleSubmit} workToCommit />
          )}
        />
      );
    case "InsuranceDetails":
      return (
        <InsuranceDetails
          user={user}
          onNext={onNext}
          renderFooter={(handleSubmit) => (
            <Footer onBack={onBack} onSubmit={handleSubmit} workToCommit disabled={paused} />
          )}
        />
      );
    case "DigitalCertificate":
      return (
        <DigitalCertificate
          user={user}
          onNext={onNext}
          renderFooter={(handleSubmit) => (
            <Footer onBack={onBack} onSubmit={handleSubmit} workToCommit disabled={paused} />
          )}
        />
      );
    case "SignatureAndSeal":
      return (
        <SignatureAndSeal
          user={user}
          onNext={onNext}
          renderFooter={(handleSubmit) => (
            <Footer onBack={onBack} onSubmit={handleSubmit} workToCommit disabled={paused} />
          )}
        />
      );
    case "StateEducation":
      return (
        <StateRequiredEducation
          user={user}
          onNext={onNext}
          proofRequired={activeStep.proofRequired}
          expirationDateRequired={activeStep.expirationDateRequired}
          renderFooter={(handleSubmit) => (
            <Footer onBack={onBack} onSubmit={handleSubmit} workToCommit disabled={paused} />
          )}
        />
      );
    case "PayoutOptions":
      return (
        <PayoutOptions
          user={user}
          renderFooter={(disabled) => (
            <Footer onBack={onBack} onSubmit={onNext} disabled={disabled || paused} workToCommit />
          )}
          refetch={refetch}
        />
      );
    case "IdentityVerification":
      return (
        <IdentityVerification
          user={user}
          renderFooter={(disabled) => (
            <Footer onBack={onBack} onSubmit={onNext} disabled={disabled || paused} workToCommit />
          )}
        />
      );
    case "ProofCertificate":
      return (
        <ProofCertificate
          user={user}
          renderFooter={(disabled) => (
            <Footer onBack={onBack} onSubmit={onNext} workToCommit disabled={disabled || paused} />
          )}
        />
      );
    case "ProofAcademy":
    case "ProfileApproval":
      return <OnboardingOverview steps={sections} notaryProfile={user.notaryProfile!} finished />;
    default:
      return renderUnknownStep();
  }
}

export const NotaryOnboardingStep = memo((props: StepProps) => (
  <div className="NotaryProfileWizardStep">{renderContent(props)}</div>
));
