/* eslint-disable prefer-arrow-callback */
import "./requirements.scss";

import { memo } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import classnames from "classnames";

import Button from "common/core/button";
import Icon from "common/core/icon";
import { Badge } from "common/core/badge";

export type MessageId = keyof typeof REQ_MESSAGES;
export type BlockingMessageId = keyof typeof BLOCKING_REQ_MESSAGES;

type ReqProps = {
  value: number;
  messageKey: MessageId;
  isBlocking?: boolean;
};

type BlockingReqProps = {
  messageKey: BlockingMessageId;
  value: number;
};

type DocumentReqProps = {
  documentName: string | null;
  onGoToDocumentClick: () => void;
  requirements: ReqProps[];
};

const REQ_MESSAGES = defineMessages({
  missingVerificationOfFactSeal: {
    id: "7b324298-f1de-411f-82cf-e52c89f0806a",
    defaultMessage: "Missing Verification of Fact Seal",
  },
  designation: {
    id: "ed695ba9-6a8e-474e-87d8-425c00b4ad3c",
    defaultMessage: "{value, plural, one {Designation} other {Designations}}",
  },
  enoteDesignation: {
    id: "74eeb182-0317-4cd5-a345-2d77baee4a3f",
    defaultMessage: "{value, plural, one {eNote Designation} other {eNote Designations}}",
  },
  signerIdentities: {
    id: "8e332a44-de23-411d-a00e-275f041ba9c9",
    defaultMessage: "{value, plural, one {ID} other {IDs}} Validation (required)",
  },
  disclosures: {
    id: "54e41147-63a3-493d-9e01-280afe893b37",
    defaultMessage:
      "{value, plural, one {Document requires} other {Documents require}} a disclosure",
  },
  notarySignatures: {
    id: "50160b82-372f-4622-8e18-6ee237e4ef4b",
    defaultMessage: "{value, plural, one {Signature} other {Signatures}}",
  },
  seals: {
    id: "112ebfb2-f9f5-4740-b69b-29de96b0fb62",
    defaultMessage: "{value, plural, one {Seal} other {Seals}}",
  },
  sealsWithoutSignatures: {
    id: "46e527a7-7771-4b28-bbeb-15fc83b20c0d",
    defaultMessage:
      "{value, plural, one {Seal requires} other {Seals require}} a notary signature on the same page",
  },
  instructions: {
    id: "2801ce5d-7608-4a0e-bd7c-86ed236c828e",
    defaultMessage: "Transaction {value, plural, one {Note} other {Notes}}",
  },
  witnessAcknowledgements: {
    id: "3801ce5d-7608-4a0e-ba7c-77ed236c829e",
    defaultMessage:
      "{value, plural, one {ID} other {IDs}} needs to be viewed by witness (required)",
  },
});

export const BLOCKING_REQ_MESSAGES = defineMessages({
  enforceRequired: {
    id: "7a1cf56c-57a7-4746-9b46-37d5731500d9",
    defaultMessage: "All required fields must be fulfilled",
  },
  signerIdentities: {
    id: "17d6589f-279d-4848-bc5b-78819b2f304f",
    defaultMessage: "Validate {value, plural, one {ID} other {IDs}} to complete the meeting",
  },
  requiredSeals: {
    id: "eb9dba1e-cc73-49bc-9ca0-f3f458e1773f",
    defaultMessage: "Place 1 Seal to complete the meeting",
  },
  missingSignersForVaultedTransaction: {
    id: "cf25cc1f-3b7b-412d-aefd-ebbb012949f9",
    defaultMessage: "This transaction requires all signers to be in meeting",
  },
  witnessAcknowledgements: {
    id: "2801ce5d-7608-4a2e-bd7c-86ed236c821e",
    defaultMessage:
      "Witnesses must view {value, plural, one {ID} other {IDs}} to complete the meeting",
  },
});

export function Requirement({ messageKey, value, isBlocking }: ReqProps) {
  const intl = useIntl();
  return (
    <div
      className={classnames(
        "NotaryMeetingUnfulfilledRequirementsModal--Req",
        isBlocking && "NotaryMeetingUnfulfilledRequirementsModal",
      )}
    >
      <div className="NotaryMeetingUnfulfilledRequirementsModal--CountContainer">
        <Badge kind="danger" className="NotaryMeetingUnfulfilledRequirementsModal--Count">
          {value}
        </Badge>
        <p>{intl.formatMessage(REQ_MESSAGES[messageKey], { value })}</p>
      </div>
    </div>
  );
}

export const BlockingRequirement = memo(function BlockingRequirement({
  messageKey,
  value,
}: BlockingReqProps) {
  const intl = useIntl();
  return (
    <p className="NotaryMeetingBlockingRequirementsModal--Req">
      <Icon
        name="doc-warning"
        size="extraLarge"
        className="NotaryMeetingBlockingRequirementsModal--Icon"
      />
      {intl.formatMessage(BLOCKING_REQ_MESSAGES[messageKey], { value })}
    </p>
  );
});

export function RequirementsForDocument({
  documentName,
  onGoToDocumentClick,
  requirements,
}: DocumentReqProps) {
  return (
    <div className="NotaryMeetingUnfulfilledRequirementsModal--DocumentRequirements">
      <div className="NotaryMeetingUnfulfilledRequirementsModal--DocumentNameContainer">
        <p className="NotaryMeetingUnfulfilledRequirementsModal--DocumentName">
          <FormattedMessage
            id="67dd32e6-271f-4242-8c61-4329dc2ed42e"
            defaultMessage="{hasName, select, true{{documentName}} other{Untitled Document}}"
            values={{ hasName: Boolean(documentName), documentName }}
          />
        </p>

        <Button
          variant="tertiary"
          buttonColor="action"
          withIcon={{ name: "caret-right", placement: "right" }}
          onClick={onGoToDocumentClick}
        >
          <FormattedMessage
            id="f2a4f80a-e1e1-4b45-8b80-2cab876b6f44"
            defaultMessage="Fix Requirement"
          />
        </Button>
      </div>
      <div>
        {requirements.map(({ value, messageKey }) => (
          <Requirement
            key={messageKey}
            value={value}
            isBlocking={messageKey in BLOCKING_REQ_MESSAGES}
            messageKey={messageKey}
          />
        ))}
      </div>
    </div>
  );
}

export function isBlockingRequirementKey(key: string): key is keyof typeof BLOCKING_REQ_MESSAGES {
  return key in BLOCKING_REQ_MESSAGES;
}
