import { useCallback } from "react";

import { StepType } from "graphql_globals";
import { useMutation } from "util/graphql";
import { redirectToSubdomain } from "util/application_redirect";
import AppSubdomains from "constants/app_subdomains";
import { captureException } from "util/exception";

import {
  type IdentityVerification_viewer_signerStepsV2 as SignerStepsV2,
  type IdentityVerification_signerIdentity_SignerIdentity as SignerIdentity,
} from "./index.graphql";
import {
  type PhotoIdentificationByCountry_steps_CredentialAnalysisStep as CredentialAnalysisStep,
  type PhotoIdentificationByCountry_steps,
} from "./id_selection/photo_identification_by_country.graphql";
import JoinMeetingMutation from "./join_meeting.mutation.graphql";

export type SignerStep = SignerStepsV2 | PhotoIdentificationByCountry_steps;

/**
 * Extracts the primary and secondary photo identification requirements from the
 * supplied signer steps.
 */
export function getPhotoIdentificationRequirements({
  signerSteps,
  externalSecondaryIdConstraint,
}: {
  signerSteps?: SignerStep[];
  externalSecondaryIdConstraint?: "required" | "optional";
}) {
  const step = signerSteps?.find((step) => {
    return step.stepType === StepType.CREDENTIAL_ANALYSIS;
  }) as CredentialAnalysisStep | undefined;

  if (!step) {
    return null;
  }

  const primaryIdOptions = step.primaryPhotoRequirement.supportedIdentifications.filter(
    (identification) => identification.providerCode,
  );

  // Supplemental ID options are returned if secondary ID is marked required by the signer step,
  // or if externalSecondaryIdConstraint is present.
  const supplementalIdOptions =
    step.secondaryIdRequired || externalSecondaryIdConstraint
      ? step.secondaryPhotoRequirement.supportedIdentifications
      : [];

  return {
    primaryIdOptions,
    supplementalIdOptions,
    supplementalIdConstraint:
      // [RX-621] This is a temporary workaround to consider secondary ID as optional for IAL2 Proof transactions
      // This should eventually be removed and only check `step.secondaryIdRequired` or `externalSecondaryIdConstraint === "required"`
      (step.secondaryIdRequired && externalSecondaryIdConstraint !== "optional") ||
      externalSecondaryIdConstraint === "required"
        ? ("required" as const)
        : ("optional" as const),
  };
}

export type PhotoIdentificationRequirements = ReturnType<typeof getPhotoIdentificationRequirements>;

export function useJoinMeeting() {
  const joinMeetingMutateFn = useMutation(JoinMeetingMutation);

  return useCallback(
    ({
      signerIdentity,
      route,
      meetingId,
    }: {
      signerIdentity: SignerIdentity;
      route: string;
      meetingId: string;
    }) => {
      joinMeetingMutateFn({
        variables: {
          input: {
            userId: signerIdentity.customer!.id,
            meetingId,
            signerIdentityId: signerIdentity.id,
          },
        },
      })
        .then(() => {
          redirectToSubdomain(AppSubdomains.business, route, {
            replace: true,
            newTab: false,
          });
        })
        .catch((e: Error) => {
          if (e.message !== "failed_to_join") {
            captureException(
              new Error(`Error joining identity verified witness meeting: ${signerIdentity.id}`),
            );
          }
          redirectToSubdomain(AppSubdomains.business, "/join-witness-meeting-failure", {
            replace: true,
            newTab: false,
          });
        });
    },
    [],
  );
}
