import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import Overlay from "common/modals/overlay";
import Button from "common/core/button";
import ClickOutside from "common/core/click_outside";
import { PDFViewer } from "common/pdf/pspdfkit/viewer";

import Styles from "./ps1583_document_modal.module.scss";

type Props = {
  pdfSrc: string;
  onClose: () => void;
};

const MESSAGES = defineMessages({
  modalTitle: {
    id: "fea073ed-da55-4392-847f-63ca13c56f74",
    defaultMessage: "PS1583 Instructions Document",
  },
});

function Ps1583DocumentModal({ pdfSrc, onClose }: Props) {
  const intl = useIntl();
  return (
    <Overlay>
      <ClickOutside onClickOutside={onClose}>
        <div
          role="dialog"
          aria-label={intl.formatMessage(MESSAGES.modalTitle)}
          data-automation-id="ps1583-document-view"
          className={Styles.container}
        >
          <div className={Styles.actions}>
            <Button buttonColor="action" variant="primary" buttonSize="large" onClick={onClose}>
              <FormattedMessage
                id="abcd3a4c-1921-4376-a8d3-020f58a82e49"
                defaultMessage="Exit document view"
              />
            </Button>
          </div>

          <PDFViewer className={Styles.pdf} url={pdfSrc} />
        </div>
      </ClickOutside>
    </Overlay>
  );
}

export default Ps1583DocumentModal;
