import "./index.scss";

import { PureComponent } from "react";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";
import { useSearchParams } from "react-router-dom";

import compose from "util/compose";
import SaveButton from "common/core/save_button";
import { DeprecatedFormGroup as FormGroup } from "common/form/group";
import { DeprecatedFormGroupErrors as FormGroupErrors } from "common/form/group_errors";
import { DeprecatedFormRowHelpTooltip } from "common/form/form_row_help_tooltip";
import { DeprecatedTextField } from "common/form/fields/text";
import { DeprecatedRadioButtonField } from "common/form/fields/radio";
import { composeValidators, getFormValues } from "util/form";
import { validatePresence, validateURL } from "validators/form";
import Env from "config/environment";
import { FullLogo } from "common/core/logo";

import builder from "./builder";
import easyLinkStyle from "./easy_link_style";

const MESSAGES = defineMessages({
  logoAlt: {
    id: "5ebc2bab-1990-47d9-afa7-a06cb99acecb",
    defaultMessage: "Notarize EasyLink Builder",
  },
});

function escapeQuote(string) {
  if (!string) {
    return string;
  }
  return string.replace(/"/g, '\\"');
}

function validate(values) {
  return composeValidators(
    validatePresence({ field: "apiKey", label: "Client API Key" }),
    validatePresence({ field: "documentName", label: "Document Name" }),
    validatePresence({ field: "fileURL", label: "File URL" }),
    validatePresence({ field: "allowSignerAnnotations", label: "Allow Annotations" }),
    validateURL({ field: "fileURL", label: "File URL" }),
  )(values);
}

class LinkBuilder extends PureComponent {
  state = {};

  componentDidMount() {
    this.props.initialize({
      buttonText: "Notarize",
      allowSignerAnnotations: false,
    });
  }

  update = () => {
    this.setState(() => ({ easyLink: this.buildEasyLink() }));
  };

  handleFocus = (event) => {
    event.target.select();
  };

  renderAPIKey = () => {
    return (
      <FormGroup fields={["apiKey"]}>
        <label htmlFor="apiKey">
          <FormattedMessage
            id="688f50e3-5cc0-44d3-98f6-908afdc555c1"
            description="apiKey"
            defaultMessage="API Key"
          />
        </label>
        <DeprecatedTextField
          id="apiKey"
          name="apiKey"
          data-automation-id="api-key-field"
          placeholder="Client API Key"
        />
        <FormGroupErrors fields={["apiKey"]} />
      </FormGroup>
    );
  };

  renderDocumentName = () => {
    return (
      <FormGroup fields={["documentName"]}>
        <label htmlFor="documentName">
          <FormattedMessage
            id="aea2f668-cfc2-401a-80ed-7171dc58b73e"
            description="documentName"
            defaultMessage="Document Name"
          />
        </label>
        <DeprecatedTextField
          id="documentName"
          name="documentName"
          data-automation-id="document-name-field"
          placeholder="e.g. PS1583, Minors Travel Consent"
        />
        <DeprecatedFormRowHelpTooltip tooltipPlacement="bottom">
          <FormattedMessage
            id="d2fe1617-17f3-439f-99c4-98a3a841d056"
            description="documentNameTooltip"
            defaultMessage="Describe what document signers are signing"
          />
        </DeprecatedFormRowHelpTooltip>
        <FormGroupErrors fields={["documentName"]} />
      </FormGroup>
    );
  };

  renderFileURL = () => {
    return (
      <FormGroup fields={["fileURL"]}>
        <label htmlFor="fileURL">
          <FormattedMessage
            id="53d42f62-4794-4027-9da8-a2e0fc085648"
            description="fileURL"
            defaultMessage="File URL"
          />
        </label>
        <DeprecatedTextField
          id="fileURL"
          name="fileURL"
          data-automation-id="file-url-field"
          placeholder="e.g. https://notarize.rocks/notarize.pdf"
        />
        <DeprecatedFormRowHelpTooltip tooltipPlacement="bottom">
          <FormattedMessage
            id="0944c9c5-4e53-49d4-8d18-7317948c412f"
            description="fileURLTooltip"
            defaultMessage="A valid URL of a .pdf file that requires notarization"
          />
        </DeprecatedFormRowHelpTooltip>
        <FormGroupErrors fields={["fileURL"]} />
      </FormGroup>
    );
  };

  renderButtonText = () => {
    return (
      <FormGroup fields={["buttonText"]}>
        <label htmlFor="buttonText">
          <FormattedMessage
            id="0a537844-921e-45a4-b654-6a5d5b304aa8"
            description="buttonText"
            defaultMessage="Button Text"
          />
        </label>
        <DeprecatedTextField
          id="buttonText"
          name="buttonText"
          data-automation-id="button-text-field"
          placeholder={'default "Notarize"'}
          value="Notarize"
        />
      </FormGroup>
    );
  };

  renderAllowSignerAnnotations = () => {
    return (
      <FormGroup fields={["allowSignerAnnotations"]}>
        <label htmlFor="fileURL">
          <FormattedMessage
            id="ba57e138-6cda-4475-8151-24405616cd79"
            description="allowAnnotations"
            defaultMessage="Allow Annotations"
          />
        </label>
        <DeprecatedRadioButtonField
          name="allowSignerAnnotations"
          labelText="No"
          labelClassName="LinkBuilder--Fields--RadioLabel"
          radioValue={false}
          size="small"
        />
        <DeprecatedRadioButtonField
          name="allowSignerAnnotations"
          labelText="Yes"
          labelClassName="LinkBuilder--Fields--RadioLabel"
          radioValue
          size="small"
        />
        <DeprecatedFormRowHelpTooltip tooltipPlacement="bottom">
          <FormattedMessage
            id="97e306ff-de63-4fbc-ac41-56b22bf63d36"
            description="allowAnnotationsTooltip"
            defaultMessage="Should signers be allowed to add annotations to the document"
          />
        </DeprecatedFormRowHelpTooltip>
        <FormGroupErrors fields={["allowSignerAnnotations"]} />
      </FormGroup>
    );
  };

  buildEasyLink = () => {
    const {
      formValues: { apiKey, documentName, fileURL, allowSignerAnnotations },
    } = this.props;
    return builder({
      hostName: Env.hostName,
      transactionName: documentName,
      document: fileURL,
      apiKey,
      allowSignerAnnotations,
    });
  };

  getSimpleSnippet = (link, buttonText) => {
    return `
      <a class="notarize-easy-link" href="${link}" target="_blank">${buttonText || "Notarize"}</a>
    `.trim();
  };

  getSimpleCSS = () => {
    return `<style>${easyLinkStyle}</style>`;
  };

  renderSimpleSnippet = (link) => {
    return (
      <div>
        <h4>
          <FormattedMessage
            id="f1cd868f-c8b0-4ef0-9d39-08f91a9d4ba3"
            description="plainLink"
            defaultMessage="Plain Link"
          />
        </h4>
        <span className="instruction">
          <FormattedMessage
            id="7ec069d1-ef51-4fff-b9b9-02655392009c"
            description="plainLinkDescription"
            defaultMessage="Just need a link? Start with the following code"
          />
        </span>
        <textarea
          onFocus={this.handleFocus}
          onMouseUp={this.handleFocus}
          spellCheck="false"
          value={this.getSimpleSnippet(link)}
          readOnly
        />
      </div>
    );
  };

  getRichSnippets = () => {
    const {
      formValues: { apiKey, documentName, fileURL, allowSignerAnnotations, buttonText },
    } = this.props;
    const anchor = `<a class="notarize-easy-link" href="https://notarize.com"
      data-allow-signer-annotations="${allowSignerAnnotations}"
      data-api-key="${escapeQuote(apiKey)}"
      data-document="${escapeQuote(fileURL)}"
      ${Env.hostName === "notarize.com" ? "" : `data-host="${Env.hostName}"`}
      data-transaction-name="${escapeQuote(documentName)}">${buttonText || "Notarize"}</a>
    `
      .trim()
      .replace(/\n( |\t)+/g, " ")
      .replace(/( |\t)+/g, " ")
      .trim();
    const script = `
      <!-- Add this to the HTML only once -->
      <script src="https://static.notarize.com/notarize.latest.min.js"></script>
    `.replace(/\n( |\t)+/g, "\n");
    return `${anchor}\n${script}`;
  };

  renderRichSnippet = () => {
    return (
      <div>
        <h4>
          <FormattedMessage
            id="581137f1-e522-4149-8a3a-878ca3720622"
            description="notarizeButton"
            defaultMessage="Notarize Button (Recommended)"
          />
        </h4>
        <span className="instruction">
          <FormattedMessage
            id="0e9541c6-7ae4-4595-9577-ff51b1271269"
            description="notarizeButtonDescription"
            defaultMessage="Add the following code to your HTML"
          />
          &nbsp;<em>body</em>
        </span>
        <textarea
          onFocus={this.handleFocus}
          onMouseUp={this.handleFocus}
          spellCheck="false"
          value={this.getRichSnippets()}
          readOnly
        />
      </div>
    );
  };

  render() {
    const { handleSubmit, showTitle, searchParams, intl } = this.props;
    const hideTitle = searchParams.get("hideTitle");
    const shouldShowTitle = hideTitle !== "true" && showTitle;
    const { easyLink } = this.state;

    return (
      <div className="LinkBuilder">
        <div className="LinkBuilder--content">
          {shouldShowTitle && (
            <h1>
              <FullLogo alt={intl.formatMessage(MESSAGES.logoAlt)} />
            </h1>
          )}
          <form className="Form" onSubmit={handleSubmit(this.update)}>
            {this.renderAPIKey()}
            {this.renderDocumentName()}
            {this.renderFileURL()}
            {this.renderButtonText()}
            {this.renderAllowSignerAnnotations()}
            <SaveButton title="Generate" {...this.props} />
          </form>
        </div>
        <aside>
          <h2>Preview</h2>
          {easyLink && (
            <a
              className="notarize-easy-link"
              href={easyLink}
              target="_blank"
              rel="noreferrer noopener"
            >
              {this.props.formValues.buttonText || "Notarize"}
            </a>
          )}
          {easyLink && this.renderRichSnippet()}
          {easyLink && this.renderSimpleSnippet(easyLink)}
        </aside>
      </div>
    );
  }
}

LinkBuilder.propTypes = {
  apiKey: PropTypes.string,
  showTitle: PropTypes.bool,
};

LinkBuilder.defaultProps = {
  showTitle: true,
};

export default compose(
  // Connect w/ redux-form
  reduxForm({
    form: "linkBuilder",
    validate,
  }),

  // Get values from redux-form state
  getFormValues("linkBuilder"),
  injectIntl,
)((props) => {
  return <LinkBuilder {...props} searchParams={useSearchParams()[0]} />;
});
