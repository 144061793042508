import { memo, useMemo, useState, useEffect, type ReactNode, type ComponentProps } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { map, filter, type Subject } from "rxjs";

import { fromSocketEvent } from "socket/util";
import { useQueryPoller } from "util/graphql/query/poll";
import { LeaveWarning } from "common/meeting/notification";
import { GraphicCacheProvider } from "common/meeting/context/graphic_cache";
import { ToolbarProvider } from "common/meeting/context/toolbar";
import { useSubject } from "util/rxjs/hooks";
import { MeetingEndedState } from "graphql_globals";
import { pushNotification } from "common/core/notification_center/actions";
import type { ApolloError } from "util/graphql";
import { RetakeHandlerWrapper, type RetakeStep } from "common/identity_verification/retake/signer";
import { isMobileDevice } from "util/support";
import RegularErrorModal from "common/error_modal/regular_error_modal";
import BeholderContainer from "common/meeting/beholder/container";
import BeholderDocumentContainer from "common/meeting/beholder/document_container";
import MeetingOverModal from "common/meeting/meeting_over_modal";
import WitnessToolbar from "common/meeting/witness/toolbar";
import WitnessMeetingDocumentBundle from "common/meeting/witness/document";
import type Channel from "socket/channel";
import SelectedDevicesController from "common/selected_devices_controller";
import { ChatProvider } from "common/chat";
import { Hidden, Visible } from "common/core/responsive";
import { MobileMeetingContainer } from "common/meeting/mobile";
import { useLogout } from "common/authentication";
import { QueryWithLoading } from "util/graphql/query";
import { useOdnRemoteWitnessCredViewer } from "util/feature_detection";
import { NOTIFICATION_TYPES, NOTIFICATION_SUBTYPES } from "constants/notifications";
import WorkflowModal from "common/modals/workflow_modal";
import { Paragraph } from "common/core/typography";
import Button from "common/core/button";
import { EVENTS_WITH_OPTIMISTIC_UPDATES } from "common/meeting/socket/events";
import { useSocketOptimisticCacheUpdater } from "common/meeting/socket_poller_lock";

import MeetingCredentialAnalysisV2 from "../notary/credential_analysis/v2";
import MeetingSidebar from "./sidebar";
import Socket from "./socket";
import MeetingQuery, {
  type RemoteWitnessMeeting_meeting_Meeting_meetingParticipants as MeetingParticipant,
  type RemoteWitnessMeeting_meeting_Meeting_meetingParticipants_SignerParticipant as SignerParticipant,
  type RemoteWitnessMeeting_meeting_Meeting_meetingParticipants_WitnessParticipant as WitnessParticipant,
  type RemoteWitnessMeeting_meeting_Meeting_meetingParticipants_IdentityVerifiedWitnessParticipant as IdentityVerifiedWitnessParticipant,
  type RemoteWitnessMeeting_meeting_Meeting as Meeting,
  type RemoteWitnessMeeting_viewer as Viewer,
  type RemoteWitnessMeeting,
} from "./index_query.graphql";

const LABELS = defineMessages({
  button: {
    id: "0d5fcaea-7c10-493b-bf88-ebf4eed9e89f",
    defaultMessage: "Leave meeting",
  },
});

type CacheState = {
  hasOptimisticUpdates: boolean;
};
type CustomInteractionError = {
  interactionErrorMessage: ReactNode;
};
type Interaction =
  | {
      locked: true;
    }
  | {
      locked: false;
      error?: Error | CustomInteractionError;
    };

type UploadPhotoEvent = { meeting_participant_id: string; photo_id_status: string };

type WitnessBundleProps = ComponentProps<typeof WitnessMeetingDocumentBundle>;
type CredentialAnalysisProps = ComponentProps<typeof MeetingCredentialAnalysisV2>;
type CredentialAnalysisParticipant = NonNullable<CredentialAnalysisProps["activeParticipant"]> & {
  __typename: MeetingParticipant["__typename"];
};

type Props = {
  meeting: Meeting;
  channel: Channel;
  witnessParticipant: WitnessParticipant | IdentityVerifiedWitnessParticipant;
  viewer: Viewer;
  notaryPointer: WitnessBundleProps["notaryPointer"];
  onShowNotaryPointer: () => void;
  indicatedDesignation: WitnessBundleProps["indicatedDesignation"];
  retakeStep: RetakeStep;
};

const MESSAGES = defineMessages({
  reverifySignerCredentials: {
    id: "72adb690-b394-4517-bc8c-d60189d676cf",
    defaultMessage: "New credentials added by signer",
  },
});

function useMeetingQueryPoller(
  meeting: Meeting,
  interaction$: Subject<Interaction>,
  cache$: Subject<CacheState>,
  channel: Channel,
) {
  const semaphore$ = useMemo(() => {
    return interaction$.pipe(map((e) => (e.locked ? "close" : "open")));
  }, [interaction$]);
  const void$ = useMemo(() => {
    return cache$.pipe(filter((e) => e.hasOptimisticUpdates));
  }, [cache$]);
  useQueryPoller({
    query: MeetingQuery,
    interval: 15_000,
    variables: { meetingId: meeting.id },
    skip: meeting.endedState !== MeetingEndedState.NOT_COMPLETED,
    semaphore$,
    void$,
  });

  useSocketOptimisticCacheUpdater({
    events: EVENTS_WITH_OPTIMISTIC_UPDATES,
    channel,
    cache$,
  });
}

function activeSignerParticipantPredicate(
  participant: MeetingParticipant,
): participant is SignerParticipant {
  return participant.__typename === "SignerParticipant" && participant.isCurrentPenHolder;
}

function RemoteWitnessMeetingInner({
  viewer,
  channel,
  notaryPointer,
  onShowNotaryPointer,
  indicatedDesignation,
  meeting,
  witnessParticipant,
  retakeStep,
}: Props) {
  const interaction$ = useSubject<Interaction>();
  const cache$ = useSubject<CacheState>();
  const navigate = useNavigate();
  const logout = useLogout();
  const intl = useIntl();
  const credentialViewerV2Enabled = useOdnRemoteWitnessCredViewer();
  const isODNWitness = witnessParticipant.isOnDemand;
  const { meetingParticipants } = meeting;
  const activeSignerParticipant = meetingParticipants.find(activeSignerParticipantPredicate);
  const activeParticipantId = activeSignerParticipant?.id;
  const retakeModalOpen = retakeStep === "persona" || retakeStep === "socure";
  const [credOpenForParticipant, setCredOpenForParticipant] = useState<
    CredentialAnalysisParticipant | null | undefined
  >(null);

  const participantCredentialsViewed = (participantId: string) =>
    witnessParticipant.signersAcknowledged.includes(participantId);

  const activeParticipantCredsViewed =
    activeParticipantId && participantCredentialsViewed(activeParticipantId);

  const redirectRemoteWitnessAfterMeeting = () => {
    if (witnessParticipant.isOnDemand) {
      navigate("/on-demand-queue");
    } else {
      logout().then(() => navigate("/"));
    }
  };

  useEffect(() => {
    if (!activeParticipantCredsViewed) {
      const timeoutId = setTimeout(() => setCredOpenForParticipant(activeSignerParticipant), 1500);
      return () => clearTimeout(timeoutId);
    }
  }, [activeParticipantCredsViewed]);

  // Prompt ondemand witnesses to re-verify credentials after signers upload new credentials
  useEffect(() => {
    if (!isODNWitness) {
      return;
    }
    const sub = fromSocketEvent<UploadPhotoEvent>(channel, "photo_updated")
      .pipe(
        filter((data) => {
          return (
            // When the notary opens a signer's credentials to prompt a retake, it automatically switches the active penholder to be that signer.
            data.meeting_participant_id === activeParticipantId ||
            data.photo_id_status === "SUCCESS"
          );
        }),
      )
      .subscribe(() => {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          subtype: NOTIFICATION_SUBTYPES.WARNING,
          message: intl.formatMessage(MESSAGES.reverifySignerCredentials),
        });
      });
    return () => sub.unsubscribe();
  }, [channel, activeParticipantId]);

  useMeetingQueryPoller(meeting, interaction$, cache$, channel);
  return (
    <ChatProvider conversationSid={meeting.conversationSid} currentParticipant={witnessParticipant}>
      <SelectedDevicesController channel={channel} participantId={witnessParticipant.id}>
        {({ onChangeDevices, selectedDevices, showAVSettings, toggleAVSettings }) => (
          <>
            <LeaveWarning meeting={meeting} />
            <BeholderContainer>
              {/* Desktop View */}
              <Hidden xs sm>
                <MeetingSidebar
                  publishVideo={!retakeModalOpen}
                  user={viewer.user!}
                  meeting={meeting}
                  onCheckId={setCredOpenForParticipant}
                  onChangeDevices={onChangeDevices}
                  selectedDevices={selectedDevices}
                  showAVSettings={showAVSettings}
                  toggleAVSettings={toggleAVSettings}
                  isCredentialsViewedForParticipant={participantCredentialsViewed}
                  isODNWitness={isODNWitness}
                />
                <BeholderDocumentContainer>
                  {isODNWitness && credentialViewerV2Enabled && credOpenForParticipant && (
                    <MeetingCredentialAnalysisV2
                      meeting={meeting}
                      channel={channel}
                      activeParticipant={credOpenForParticipant}
                      onClose={() => setCredOpenForParticipant(null)}
                      isWitnessViewer={isODNWitness}
                      credentialsViewed={participantCredentialsViewed(credOpenForParticipant.id)}
                    />
                  )}
                  <WitnessMeetingDocumentBundle
                    query={MeetingQuery}
                    user={viewer.user}
                    notaryPointer={notaryPointer}
                    onShowNotaryPointer={onShowNotaryPointer}
                    indicatedDesignation={indicatedDesignation}
                    witnessParticipant={witnessParticipant}
                    meeting={meeting}
                    interaction$={interaction$}
                  />
                </BeholderDocumentContainer>
                <WitnessToolbar meeting={meeting} />
              </Hidden>
              {/* Mobile View */}
              <Visible xs sm>
                <MobileMeetingContainer
                  meeting={meeting}
                  meetingQuery={MeetingQuery}
                  channel={channel}
                  user={viewer.user}
                  onChangeDevices={onChangeDevices}
                  selectedDevices={selectedDevices}
                  showAVSettings={showAVSettings}
                  toggleAVSettings={toggleAVSettings}
                  publishVideo={!(isMobileDevice() && retakeModalOpen)}
                >
                  <WitnessMeetingDocumentBundle
                    query={MeetingQuery}
                    notaryPointer={notaryPointer}
                    onShowNotaryPointer={onShowNotaryPointer}
                    indicatedDesignation={indicatedDesignation}
                    witnessParticipant={witnessParticipant}
                    user={viewer.user}
                    meeting={meeting}
                    interaction$={interaction$}
                  />
                </MobileMeetingContainer>
              </Visible>
            </BeholderContainer>
            {meeting.endedState !== MeetingEndedState.NOT_COMPLETED &&
              (meeting.endedState === MeetingEndedState.COMPLETED ? (
                <MeetingOverModal
                  buttonLabel={intl.formatMessage(LABELS.button)}
                  onSubmit={redirectRemoteWitnessAfterMeeting}
                />
              ) : (
                <WorkflowModal
                  buttons={[
                    <Button
                      key="redirect-witness"
                      buttonColor="action"
                      variant="primary"
                      onClick={redirectRemoteWitnessAfterMeeting}
                    >
                      <FormattedMessage
                        id="06aae146-3d0f-412f-905e-d8806156bfd3"
                        defaultMessage="Return to ODN queue"
                      />
                    </Button>,
                  ]}
                  title={
                    <FormattedMessage
                      id="fe0d6727-bb52-4c5e-8afd-b043c92a1eb3"
                      defaultMessage="The meeting was terminated"
                    />
                  }
                  footerSeparator={false}
                >
                  <Paragraph>
                    <FormattedMessage
                      id="6372fb26-1ff9-4d73-8145-381759f2f300"
                      defaultMessage="Unfortunately, this meeting was terminated by the notary. Return to the ODN queue to pick up another call."
                    />
                  </Paragraph>
                </WorkflowModal>
              ))}
          </>
        )}
      </SelectedDevicesController>
    </ChatProvider>
  );
}

export function RemoteWitnessRemovalModal({ failedToJoin }: { failedToJoin?: boolean }) {
  const navigate = useNavigate();
  return (
    <RegularErrorModal
      clearErrors={() => navigate("/on-demand-queue")}
      title={
        failedToJoin ? (
          <FormattedMessage
            id="a88ea6c3-bd83-4963-96c1-968e8e2e5f4a"
            defaultMessage="Failed to Join Meeting"
          />
        ) : (
          <FormattedMessage
            id="86d45498-4cbc-4f5f-a6f9-0e0021ec5478"
            defaultMessage="Meeting Failed"
          />
        )
      }
      errorString={
        <FormattedMessage
          id="29658bdc-35df-4f49-a4e1-d4c355bd9389"
          defaultMessage="The notary removed you from the meeting{failedToJoin, select, true{ during the join process. Please continue to the queue to join a meeting.} other{}}"
          values={{ failedToJoin }}
        />
      }
    />
  );
}

function WrappedRemoteWitnessMeeting({
  data,
  error,
  refetch,
}: {
  data: RemoteWitnessMeeting | undefined;
  error: ApolloError | undefined;
  refetch: () => Promise<unknown>;
}) {
  const meetingId = useParams().meetingId!;
  const navigate = useNavigate();
  if (error || !data?.meeting) {
    return (
      <RegularErrorModal
        clearErrors={error ? refetch : () => navigate("/")}
        errorString={
          <FormattedMessage
            id="f91f7e07-cf13-44be-a0d3-02989d643165"
            description="queryFailure"
            defaultMessage="Unable to load meeting."
          />
        }
      />
    );
  }
  const { viewer } = data;
  const viewerUserId = viewer.user!.id;
  const meeting = data.meeting as Meeting;
  const witnessParticipant = meeting.meetingParticipants.find(
    (p) =>
      (p.__typename === "WitnessParticipant" ||
        p.__typename === "IdentityVerifiedWitnessParticipant") &&
      p.userId === viewerUserId,
  ) as WitnessParticipant | IdentityVerifiedWitnessParticipant | undefined;
  if (!witnessParticipant) {
    return <RemoteWitnessRemovalModal />;
  }
  return (
    <GraphicCacheProvider>
      <ToolbarProvider
        currentDocumentId={meeting.currentDocumentId}
        localPenHolderId={witnessParticipant.id}
      >
        <Socket
          meetingId={meetingId}
          refetch={refetch}
          requiredFeatures={meeting.documentBundle?.requiredFeatures}
        >
          {(channel, { pointer, indicatedDesignation, onShowPointer }) => (
            <RetakeHandlerWrapper channel={channel} meeting={meeting}>
              {({ retakeStep }) => (
                <RemoteWitnessMeetingInner
                  notaryPointer={pointer}
                  channel={channel}
                  retakeStep={retakeStep}
                  indicatedDesignation={indicatedDesignation}
                  onShowNotaryPointer={onShowPointer}
                  witnessParticipant={witnessParticipant}
                  meeting={meeting}
                  viewer={viewer}
                />
              )}
            </RetakeHandlerWrapper>
          )}
        </Socket>
      </ToolbarProvider>
    </GraphicCacheProvider>
  );
}

function WrappedRemoteWitnessMeetingWithQuery() {
  const meetingId = useParams().meetingId!;

  return (
    <QueryWithLoading query={MeetingQuery} variables={{ meetingId }} partialRefetch>
      {({ data, error, refetch }) => <WrappedRemoteWitnessMeeting {...{ data, error, refetch }} />}
    </QueryWithLoading>
  );
}

export default memo(WrappedRemoteWitnessMeetingWithQuery);
