import { FormattedMessage } from "react-intl";
import { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { useMutation } from "util/graphql";
import { BulkActionButton } from "common/core/bulk_actions/common";
import { OrganizationTransactionDetailedStatus } from "graphql_globals";
import { pushNotification, removePushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_SUBTYPES } from "constants/notifications";
import ArchiveOrganizationTransactionsMutation from "common/transactions/archive_organization_transactions_mutation.graphql";
import UnarchiveOrganizationTransactionsMutation from "common/transactions/unarchive_organization_transactions_mutation.graphql";

export function useBulkArchive(
  items: { id: string; detailedStatus: OrganizationTransactionDetailedStatus }[],
  selectedItemsArray: string[],
) {
  const preventArchive =
    items.filter(
      (item) =>
        selectedItemsArray.includes(item.id) &&
        ![
          OrganizationTransactionDetailedStatus.COMPLETE,
          OrganizationTransactionDetailedStatus.COMPLETE_WITH_REJECTIONS,
          OrganizationTransactionDetailedStatus.ESIGN_COMPLETE,
          OrganizationTransactionDetailedStatus.WET_SIGN_COMPLETE,
        ].includes(item.detailedStatus),
    ).length > 0;

  return { preventArchive };
}

export function BulkActionArchiveButton({
  ids,
  disabled,
  selectedItemCount,
}: {
  ids: string[];
  disabled: boolean;
  selectedItemCount: number;
}) {
  const [archiveButtonClicked, setArchiveButtonClicked] = useState(false);
  const archiveOrganizationTransactionsMutateFn = useMutation(
    ArchiveOrganizationTransactionsMutation,
    {
      refetchQueries: ["TitleTransactions", "LenderTransactions", "BusinessTransactions"],
    },
  );
  const unarchiveOrganizationTransactionsMutateFn = useMutation(
    UnarchiveOrganizationTransactionsMutation,
    {
      refetchQueries: ["TitleTransactions", "LenderTransactions", "BusinessTransactions"],
    },
  );

  const previouslyArchivedIds = useRef<[] | string[]>([]);

  useEffect(() => {
    previouslyArchivedIds.current = ids;
  }, [archiveButtonClicked]);

  const unarchiveTransactions = async (uid: string | null = null) => {
    try {
      await unarchiveOrganizationTransactionsMutateFn({
        variables: {
          input: {
            ids: previouslyArchivedIds.current,
          },
        },
      });
      if (uid) {
        removePushNotification(uid);
      }
      pushNotification({
        message: (
          <FormattedMessage
            id="f795abad-5059-4efc-94c8-39fb8abae8ac"
            defaultMessage="{transactionCount, plural, one {This transaction has been} other {{transactionCount} transactions have been}} unarchived"
            values={{ transactionCount: previouslyArchivedIds.current.length }}
          />
        ),
      });
    } catch {
      if (uid) {
        removePushNotification(uid);
      }
      pushNotification({
        message: (
          <FormattedMessage
            id="cbbb883a-6c4f-4c80-815f-94c7792c513e"
            defaultMessage="Couldn't unarchive {transactionCount, plural, one {this transaction} other {{transactionCount} transactions}}"
            values={{ transactionCount: previouslyArchivedIds.current.length }}
          />
        ),
        subtype: NOTIFICATION_SUBTYPES.ERROR,
      });
    }
  };

  const archiveTransactions = async () => {
    const uid = uuidv4();
    setArchiveButtonClicked(!archiveButtonClicked);
    try {
      await archiveOrganizationTransactionsMutateFn({
        variables: {
          input: {
            ids,
          },
        },
      });
      pushNotification({
        uid,
        message: (
          <FormattedMessage
            id="25665948-b678-4865-8884-d0207cc45b01"
            defaultMessage="{transactionCount, plural, one {This transaction has been} other {{transactionCount} transactions have been}} archived"
            values={{ transactionCount: selectedItemCount }}
          />
        ),
        action: () => unarchiveTransactions(uid),
        actionText: "Undo",
      });
    } catch {
      pushNotification({
        message: (
          <FormattedMessage
            id="a986eac9-6d74-4156-a490-124f26f1c84d"
            defaultMessage="Unable to archive {transactionCount, plural, one {transaction} other {{transactionCount} transactions}}"
            values={{ transactionCount: selectedItemCount }}
          />
        ),
        subtype: NOTIFICATION_SUBTYPES.ERROR,
      });
    }
  };

  return (
    <BulkActionButton
      buttonColor="action"
      disabled={disabled}
      disabledHint={
        <FormattedMessage
          id="aaa22f29-0c2a-4f74-a46f-a5d4dcad2da6"
          defaultMessage="Only completed transactions can be archived."
        />
      }
      onClick={archiveTransactions}
      withIcon={{ name: "archive", placement: "left" }}
      automationSuffix="archive"
      label={
        <FormattedMessage id="a7e16a67-629b-4b31-b844-2e14cbbcf9eb" defaultMessage="Archive" />
      }
    />
  );
}

export function BulkActionUnarchiveButton({
  ids,
  selectedItemCount,
}: {
  ids: string[];
  selectedItemCount: number;
}) {
  const unarchiveOrganizationTransactionsMutateFn = useMutation(
    UnarchiveOrganizationTransactionsMutation,
    {
      refetchQueries: ["TitleTransactions", "LenderTransactions", "BusinessTransactions"],
    },
  );
  const unarchiveTransactions = async () => {
    try {
      await unarchiveOrganizationTransactionsMutateFn({
        variables: {
          input: {
            ids,
          },
        },
      });
      pushNotification({
        message: (
          <FormattedMessage
            id="d751e479-1479-4d9a-b7a9-784df1f35b4c"
            defaultMessage="{transactionCount, plural, one {This transaction has been} other {{transactionCount} transactions have been}} unarchived"
            values={{ transactionCount: selectedItemCount }}
          />
        ),
      });
    } catch {
      pushNotification({
        message: (
          <FormattedMessage
            id="a986eac9-6d74-4156-a490-124f26f1c84d"
            defaultMessage="Couldn't unarchive {transactionCount, plural, one {this transaction} other {{transactionCount} transactions}}"
            values={{ transactionCount: selectedItemCount }}
          />
        ),
        subtype: NOTIFICATION_SUBTYPES.ERROR,
      });
    }
  };
  return (
    <BulkActionButton
      buttonColor="action"
      onClick={unarchiveTransactions}
      withIcon={{ name: "arrow-clockwise", placement: "left" }}
      automationSuffix="unarchive"
      label={
        <FormattedMessage id="b5c58c7d-279c-4ace-bbe7-0f39852983f7" defaultMessage="Unarchive" />
      }
    />
  );
}
