import { memo, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useQuery } from "util/graphql";
import { ACCOUNT_TYPES } from "common/account/constants";
import LoadingIndicator from "common/core/loading_indicator";
import { isNotaryNST } from "common/notary/capacity";
import { Tier } from "graphql_globals";
import { TRANSACTION_PATH, MEMBER_MANAGEMENT_PATH } from "util/routes";
import { GET_STARTED_PATH } from "common/proof_frame/path";
import { usePermissions } from "common/core/current_user_role";
import { useHideGetStarted } from "common/get_started/common/util";
import { useNewOrgLandingExpt } from "util/feature_detection";
import { EXPERIMENT_GROUP_DEFAULT } from "common/experiments/group";

import BusinessRedirectRootQuery from "./redirect_root_query.graphql";

const REALTOR = ACCOUNT_TYPES.REALTOR.toUpperCase();
const BROKER = ACCOUNT_TYPES.BROKER.toUpperCase();

function RedirectRoot() {
  const navigate = useNavigate();
  const { data } = useQuery(BusinessRedirectRootQuery);
  const user = data?.viewer.user;
  const { hasPermissionFor } = usePermissions();
  const noGetStartedPermissions = useHideGetStarted();
  const newOrgLandingExpt = useNewOrgLandingExpt(user?.organization?.activeTier.tier);

  useEffect(() => {
    if (!user) {
      return;
    }
    const accountTypes = user.accountTypes || [];
    const isRealtorOrBroker = accountTypes.some((type) => type === REALTOR || type === BROKER);
    const isNst = isNotaryNST(user.notaryProfile as Parameters<typeof isNotaryNST>[0]);
    const isBasicTier = user.organization?.activeTier.tier === Tier.BASIC;
    const hideGetStarted = (isBasicTier && !isNst) || noGetStartedPermissions;

    if (isRealtorOrBroker && !user.dotloopConnected) {
      return navigate("/realtor_closings", { replace: true });
    } else if (
      !hasPermissionFor("viewOrganizationTransactions") &&
      hasPermissionFor("viewTeamDetails")
    ) {
      return navigate(MEMBER_MANAGEMENT_PATH, { replace: true });
    } else if (
      user.organization?.organizationTransactions.totalCount === 0 &&
      !hideGetStarted &&
      newOrgLandingExpt === EXPERIMENT_GROUP_DEFAULT
    ) {
      return navigate(GET_STARTED_PATH, { replace: true });
    }
    navigate(TRANSACTION_PATH, { replace: true });
  }, [user]);
  return <LoadingIndicator />;
}

export default memo(RedirectRoot);
