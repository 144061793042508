import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { CardNumberElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { useState, type ReactElement } from "react";

import { Hr } from "common/core/horizontal_rule";
import Button from "common/core/button";
import Link from "common/core/link";
import Icon from "common/core/icon";
import { TIER_PRICING_URL } from "constants/marketing";
import { Payer } from "graphql_globals";
import { useMutation } from "util/graphql";
import { StripeCardElementsUIForReactHookForm, getLazyStripe } from "common/settings/payment/util";
import { useForm } from "common/core/form";
import { invalidCard } from "errors/credit_card";
import AddCardToOrganizationMutation from "common/settings/payment/add_card_to_organization_mutation.graphql";
import { ChoiceChip, RadioGroup, RadioInput } from "common/core/form/option";
import { FormattedFieldError } from "common/core/form/error";
import AlertMessage from "common/core/alert_message";
import { isGraphQLError } from "util/graphql/query";

import { type Slideshow_viewer_user_organization as Organization } from "../slideshow_query.graphql";
import Styles from "./updated_onboarding.module.scss";
import UpdateOrganizationDefaultPayerMutation from "./update_organization_payer.graphql";

type ContainerProps = {
  organization: Organization;
  onNext: () => void;
};
type FormValues = {
  cardName: string;
  cardNumber: string;
  cardExpiry: string;
  cardCvc: string;
  defaultPayer: Payer | null;
};

const MESSAGES = defineMessages({
  chargeMyRecipients: {
    id: "5ae72db4-809c-4d91-a016-f00e6c6b545f",
    defaultMessage: "Charge my recipients",
  },
  chargeMyAccount: {
    id: "5ae72db4-809c-4d91-a016-f00e6c6b545f",
    defaultMessage: "Charge my account (credit card)",
  },
  validationError: {
    id: "8556464b-3225-454e-b7d9-9c1864dc9fcc",
    defaultMessage: "Please select a payment option.",
  },
} as const);

function PaymentModalInner({ organization, onNext }: ContainerProps): ReactElement {
  const addCardToOrganization = useMutation(AddCardToOrganizationMutation);
  const updateOrganizationPayer = useMutation(UpdateOrganizationDefaultPayerMutation);

  const stripe = useStripe();
  const elements = useElements();

  const intl = useIntl();

  const defaultValues: FormValues = {
    cardName: "",
    cardNumber: "",
    cardExpiry: "",
    cardCvc: "",
    defaultPayer: organization.paymentSpecified ? organization.defaultPayer : null,
  };
  const form = useForm({
    defaultValues,
  });
  const {
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = form;
  const defaultPayer = watch("defaultPayer");
  const [stripeError, setStripeError] = useState(false);

  const saveCreditCard = (cardName: string) => {
    if (defaultPayer === Payer.CUSTOMER) {
      return updateOrganizationPayer({
        variables: {
          input: {
            id: organization.id,
            defaultPayer,
          },
        },
      });
    }

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return Promise.resolve();
    }

    const cardElement = elements.getElement(CardNumberElement);
    return stripe.createToken(cardElement!, { name: cardName }).then((result) => {
      return addCardToOrganization({
        variables: {
          input: {
            id: organization.id,
            cardToken: result.token!.id,
            defaultPayer,
          },
        },
      }).catch((error) => {
        return Promise.reject(
          invalidCard({
            message: (isGraphQLError(error) && error.graphQLErrors[0]?.specifics) || undefined,
          }),
        );
      });
    });
  };

  const savePayment = (values: FormValues) => {
    setStripeError(false);
    const { cardName } = values;

    return (saveCreditCard(cardName) as Promise<Awaited<ReturnType<typeof saveCreditCard>>>)
      .then(() => onNext())
      .catch(() => setStripeError(true));
  };

  return (
    <form onSubmit={handleSubmit(savePayment)}>
      <div className={`${Styles.slideContent} ${Styles.modalContent}`}>
        <div>
          <RadioGroup
            label={
              <FormattedMessage
                id="2f634cab-fcaf-43a8-90de-1608c16f623b"
                defaultMessage="Your account is free, but transactions require payment. Who should pay for completed transactions? <l>View Pricing</l>"
                values={{
                  l: (text) => (
                    <Link underlined href={TIER_PRICING_URL}>
                      {text} <Icon name="arrow-up-right-square" />
                    </Link>
                  ),
                }}
              />
            }
            horizontal
          >
            <ChoiceChip
              label={intl.formatMessage(MESSAGES.chargeMyRecipients)}
              hasError={Boolean(form.formState.errors.defaultPayer)}
              radio={
                <RadioInput
                  value={Payer.CUSTOMER}
                  {...form.register("defaultPayer", {
                    required: intl.formatMessage(MESSAGES.validationError),
                  })}
                />
              }
            />
            <ChoiceChip
              label={intl.formatMessage(MESSAGES.chargeMyAccount)}
              hasError={Boolean(form.formState.errors.defaultPayer)}
              radio={
                <RadioInput
                  value={Payer.ORGANIZATION}
                  {...form.register("defaultPayer", {
                    required: intl.formatMessage(MESSAGES.validationError),
                  })}
                />
              }
            />
          </RadioGroup>
          <FormattedFieldError
            inputName="defaultPayer"
            error={form.formState.errors.defaultPayer}
          />
        </div>
        <div className={Styles.bottomContent}>
          {defaultPayer === Payer.ORGANIZATION && (
            <>
              <StripeCardElementsUIForReactHookForm
                changeCardElement={() => {}}
                form={form}
                showLabels
              />
              {stripeError && (
                <AlertMessage kind="danger" className={Styles.bottomContent}>
                  <FormattedMessage
                    id="51a679d2-2dc8-4396-9e9c-378f73dc5bf3"
                    defaultMessage="Sorry, something went wrong. Please try again."
                  />
                </AlertMessage>
              )}
            </>
          )}
          {defaultPayer === Payer.CUSTOMER && (
            <FormattedMessage
              id="801816a1-2236-497f-9c31-f95f90c5af5f"
              defaultMessage="Signers will be prompted for payment when they complete a transaction."
            />
          )}
        </div>
      </div>

      <Hr />
      <div className={Styles.buttons}>
        <Button
          key="confirm"
          buttonColor="action"
          variant="primary"
          isLoading={isSubmitting}
          automationId="payment-modal-primary-btn"
          type="submit"
          disabled={isSubmitting}
        >
          <FormattedMessage
            id="9e185784-919b-404c-bb50-cdf0cbf751ef"
            defaultMessage="Complete my set up"
          />
        </Button>
      </div>
    </form>
  );
}

export function PaymentModal(props: ContainerProps): ReactElement {
  return (
    <Elements stripe={getLazyStripe()}>
      <PaymentModalInner {...props} />
    </Elements>
  );
}
