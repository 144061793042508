import { useIntl } from "react-intl";

import { useForm } from "common/core/form";
import { useActiveOrganization } from "common/account/active_organization";
import { QueryWithLoading } from "util/graphql/query";
import { transformProofRequirementForSubmit } from "common/proof_requirements/common";
import { EditableTitle } from "common/core/form/subforms/editable_title";

import { IdentifyPayer, IdentifyProofRequirement, type IdentifyFormValues } from "./common";
import { CCRecipients, COMMON_MESSAGES } from "../common";
import { EditEasylinkLayout } from "../edit";
import OrganizationSettingsQuery, {
  type OrganizationSettings_organization_Organization as Organization,
} from "../organization_settings.query.graphql";

export function EditIdentifyEasylink(props: { initialValues: IdentifyFormValues }) {
  const { initialValues } = props;
  const [activeOrganizationId] = useActiveOrganization();
  const form = useForm<IdentifyFormValues>({
    defaultValues: initialValues,
  });
  const { control } = form;
  const intl = useIntl();

  return (
    <QueryWithLoading
      query={OrganizationSettingsQuery}
      variables={{ organizationId: activeOrganizationId! }}
    >
      {({ data }) => {
        const organization = data!.organization as Organization;
        const {
          defaultAuthenticationRequirement,
          availableProofRequirements,
          disableCcRecipients,
        } = organization;

        return (
          <EditEasylinkLayout
            form={form}
            initialValues={initialValues}
            title={
              <EditableTitle
                aria-label={intl.formatMessage(COMMON_MESSAGES.NAME_LABEL)}
                control={control}
                name="name"
              />
            }
            variables={(values, justActivated) => ({
              input: {
                easylinkId: initialValues.id,
                name: values.name,
                proofRequirement: transformProofRequirementForSubmit(values.proofRequirement),
                ...(justActivated && { active: true }),
                ccRecipientEmails: values.ccRecipients?.map(({ email }) => email) || [],
              },
            })}
          >
            <IdentifyProofRequirement
              form={form}
              defaultAuthenticationRequirement={defaultAuthenticationRequirement}
              availableProofRequirements={availableProofRequirements}
            />
            {!disableCcRecipients && <CCRecipients form={form} />}
            <IdentifyPayer organization={organization} form={form} />
          </EditEasylinkLayout>
        );
      }}
    </QueryWithLoading>
  );
}
