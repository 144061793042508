import { Fragment, type ReactElement } from "react";
import { FormattedMessage, useIntl, type IntlShape } from "react-intl";
import { v4 } from "uuid";

import type { FieldError, UseFormReturn } from "common/core/form";
import { isAriaInvalid, defaultRequiredMessage, FormattedFieldError } from "common/core/form/error";
import { Select } from "common/core/form/select";
import { Heading, Substyle } from "common/core/typography";
import { useId } from "util/html";
import { Card } from "common/core/card";
import { COUNTRIES } from "constants/countries";
import { RadioGroup, OptionBar, RadioInput } from "common/core/form/option";
import { PhotoType } from "common/identity/document_viewer/engine";

import Styles from "./index.module.scss";
import { SelectionDisabledInfo, messages, type IDSelectionFormValues } from ".";
import type {
  PhotoIdentificationByCountry_steps_CredentialAnalysisStep_primaryPhotoRequirement_supportedIdentifications as PrimarySupportedIdentification,
  PhotoIdentificationByCountry_steps_CredentialAnalysisStep_secondaryPhotoRequirement_supportedIdentifications as SecondarySupportedIdentification,
} from "./photo_identification_by_country.graphql";
import type { PhotoIdentificationRequirements } from "../util";
import SROnly from "../../core/screen_reader";

export type Props = {
  form: UseFormReturn<IDSelectionFormValues>;
  photoIdentificationRequirements: PhotoIdentificationRequirements;
  countryDataReady: boolean;
  size: "mobile" | "desktop";
  individualRetake?: PhotoType | null;
  isPs1583Bundle: boolean;
};

const PrimaryCountryLabel = () => (
  <>
    <SROnly>
      <FormattedMessage
        id="038cc88c-d150-4d0f-a55a-1a95a4c50f32"
        defaultMessage="Select country of government issued ID"
      />
    </SROnly>
    <FormattedMessage id="465ddad4-bc04-4812-971c-1b5abbc4bc87" defaultMessage="Select country" />
  </>
);

const SupplementalCountryLabel = ({
  supplementalIdConstraint,
}: {
  supplementalIdConstraint: "optional" | "required";
}) => (
  <>
    <SROnly>
      <FormattedMessage
        id="961f724b-bd47-4a56-a3e3-a647fdae99cf"
        defaultMessage="Select country of supplemental document"
      />
    </SROnly>
    <FormattedMessage
      id="bc32f552-2656-4e51-9118-3a258a66336b"
      defaultMessage="Select country {optional, select, true {{optionalLabel}} other {}}"
      values={{
        optional: supplementalIdConstraint === "optional",
        optionalLabel: (
          <Substyle textColor="subtle">
            <FormattedMessage
              id="4aa77e2f-1161-474d-86c7-5592f4f2d42f"
              defaultMessage="(Optional)"
            />
          </Substyle>
        ),
      }}
    />
  </>
);

export function IDSelectionForm({
  form,
  photoIdentificationRequirements,
  countryDataReady,
  size,
  individualRetake = null,
  isPs1583Bundle,
}: Props) {
  const { watch, formState } = form;
  const intl = useIntl();

  const primaryIdOptions = photoIdentificationRequirements?.primaryIdOptions ?? [];
  const supplementalIdOptions = photoIdentificationRequirements?.supplementalIdOptions || [];

  const unsupportedPrimaryCountryError: FieldError | undefined =
    countryDataReady && primaryIdOptions.length < 1
      ? {
          type: "unsupported-country",
          message: intl.formatMessage(messages.unsupportedCountry),
        }
      : undefined;
  const primaryIdLabel = isPs1583Bundle ? (
    <FormattedMessage
      id="c5f008f3-5b24-40d0-be6c-18753d8c5db1"
      defaultMessage="Select photo ID type"
    />
  ) : (
    <FormattedMessage
      id="7ac7c9e2-0eda-4d17-bfab-66033966817f"
      defaultMessage="Select government issued ID"
    />
  );
  const primaryIdError = (
    <FormattedFieldError inputName="primaryIdType" error={formState.errors.primaryIdType} />
  );
  const primaryIdHeading = isPs1583Bundle ? (
    <FormattedMessage id="8d8f5bcc-4f0c-46bf-b110-01499caf0532" defaultMessage="Photo ID type" />
  ) : (
    <FormattedMessage
      id="94365c91-3a7c-4796-8eaa-58407e6fb311"
      defaultMessage="Government Issued ID"
    />
  );

  const unsupportedSupplementalCountryError: FieldError | undefined =
    countryDataReady && supplementalIdOptions.length < 1
      ? {
          type: "unsupported-country",
          message: intl.formatMessage(messages.unsupportedCountry),
        }
      : undefined;
  const supplementalIdLabel = isPs1583Bundle ? (
    <FormattedMessage
      id="b3550fe3-66f0-498d-a5dc-90e1c7c0d69e"
      defaultMessage="Select address ID type"
    />
  ) : (
    <FormattedMessage
      id="499567f0-30dc-4375-8d0f-6abb57af98a6"
      defaultMessage="Select supplemental document {optional, select, true {{optionalLabel}} other {}}"
      values={{
        optional:
          photoIdentificationRequirements &&
          photoIdentificationRequirements.supplementalIdConstraint === "optional",
        optionalLabel: (
          <Substyle textColor="subtle">
            <FormattedMessage
              id="599b587e-069e-410c-aade-63075584aa04"
              defaultMessage="(Optional)"
            />
          </Substyle>
        ),
      }}
    />
  );
  const supplementalIdError = (
    <FormattedFieldError
      inputName="supplementalIdType"
      error={formState.errors.supplementalIdType}
    />
  );
  const supplementalHeading = isPs1583Bundle ? (
    <FormattedMessage id="e1bee278-0ca5-4832-b310-04fd6c31b430" defaultMessage="Address ID type" />
  ) : (
    <FormattedMessage
      id="1cea61be-38a0-4f27-b8b3-e185d71046cf"
      defaultMessage="Supplemental Document"
    />
  );

  const subProps: SubProps = {
    form,
    intl,
    individualRetake,
    selectPrimaryCountry: useId(),
    selectPrimaryId: useId(),
    selectSupplementalCountry: useId(),
    selectSupplementalId: useId(),
    primaryCountryCode: watch("primaryCountry"),
    primaryIdTypeValue: watch("primaryIdType"),
    supplementalCountryCode: watch("supplementalCountry"),
    supplementalIdTypeValue: watch("supplementalIdType"),
    primaryIdOptions,
    supplementalIdOptions,
    supplementalIdConstraint:
      photoIdentificationRequirements?.supplementalIdConstraint || "optional",
    unsupportedPrimaryCountryError,
    primaryIdLabel,
    primaryIdError,
    primaryIdHeading,
    unsupportedSupplementalCountryError,
    supplementalIdLabel,
    supplementalIdError,
    supplementalHeading,
  };

  if (size === "mobile") {
    return <MobileForm {...subProps} />;
  }

  return <DesktopForm {...subProps} />;
}

function showPrimaryAndOrSecondary(props: SubProps): {
  showPrimary: boolean;
  showSecondary: boolean;
} {
  const showPrimary =
    props.individualRetake === null || props.individualRetake === PhotoType.Primary;
  const hideSecondaryForOtherRetake =
    props.individualRetake && props.individualRetake !== PhotoType.Secondary;
  const showSecondaryForRetake = props.individualRetake === PhotoType.Secondary;
  const showSecondary =
    !hideSecondaryForOtherRetake &&
    (showSecondaryForRetake ||
      props.supplementalIdConstraint === "required" ||
      props.supplementalIdOptions.length > 0);

  return { showPrimary, showSecondary };
}

/**
 * Props used by both the mobile and desktop forms
 */
type SubProps = {
  intl: IntlShape;
  individualRetake: PhotoType | null;
  selectPrimaryCountry: string;
  selectPrimaryId: string;
  selectSupplementalCountry: string;
  selectSupplementalId: string;
  form: UseFormReturn<IDSelectionFormValues>;

  primaryCountryCode: string;
  primaryIdTypeValue: string;
  supplementalCountryCode: string | null;
  supplementalIdTypeValue: string | null;

  primaryIdOptions: PrimarySupportedIdentification[];
  supplementalIdOptions: SecondarySupportedIdentification[];
  supplementalIdConstraint: "required" | "optional";

  unsupportedPrimaryCountryError?: FieldError;
  primaryIdLabel: ReactElement;
  primaryIdError: ReactElement;
  primaryIdHeading: ReactElement;
  unsupportedSupplementalCountryError?: FieldError;
  supplementalIdLabel: ReactElement;
  supplementalIdError: ReactElement;
  supplementalHeading: ReactElement;
};

function MobileForm(props: SubProps) {
  const { showPrimary, showSecondary } = showPrimaryAndOrSecondary(props);
  return (
    <>
      {showPrimary && (
        <div className={Styles.mobileCard}>
          <Heading textStyle="headingFive" level="h2">
            {props.primaryIdHeading}
          </Heading>
          <FormattedMessage
            id="5ebdf341-bcb4-49ad-8804-f9e587c9fbcc"
            defaultMessage="Must show a photo of your face, date of birth, and expiration date."
            tagName="p"
          />
          <Select
            label={<PrimaryCountryLabel />}
            data-automation-id="primary-country-select"
            id={props.selectPrimaryCountry}
            aria-invalid={isAriaInvalid(
              props.form.formState.errors.primaryCountry ?? props.unsupportedPrimaryCountryError,
            )}
            items={COUNTRIES}
            {...props.form.register("primaryCountry", {
              required: defaultRequiredMessage(props.intl),
            })}
            autoComplete="country-name"
          />
          <FormattedFieldError
            inputName="primaryCountry"
            error={
              props.form.formState.errors.primaryCountry ?? props.unsupportedPrimaryCountryError
            }
          />
          {!props.unsupportedPrimaryCountryError && (
            <RadioGroup label={props.primaryIdLabel} groupError={props.primaryIdError}>
              {props.primaryIdOptions.map(({ description }) => {
                const disabledMessageId = v4();
                const disabled =
                  props.primaryCountryCode === props.supplementalCountryCode &&
                  description === props.supplementalIdTypeValue;
                return (
                  <Fragment key={`primary-${description}`}>
                    <OptionBar
                      label={description}
                      disabled={disabled}
                      input={
                        <RadioInput
                          disabled={disabled}
                          value={description}
                          aria-invalid={isAriaInvalid(props.form.formState.errors.primaryIdType)}
                          aria-describedby={disabled ? disabledMessageId : undefined}
                          {...props.form.register("primaryIdType", {
                            required: defaultRequiredMessage(props.intl),
                          })}
                        />
                      }
                    />
                    {disabled && <SelectionDisabledInfo id={disabledMessageId} />}
                  </Fragment>
                );
              })}
            </RadioGroup>
          )}
        </div>
      )}
      {showSecondary && (
        <div className={Styles.mobileCard}>
          <Heading textStyle="headingFive" level="h2">
            {props.supplementalHeading}
          </Heading>
          <Select
            label={
              <SupplementalCountryLabel supplementalIdConstraint={props.supplementalIdConstraint} />
            }
            data-automation-id="supplemental-country-select"
            id={props.selectSupplementalCountry}
            aria-invalid={isAriaInvalid(
              props.form.formState.errors.supplementalCountry ??
                props.unsupportedSupplementalCountryError,
            )}
            items={COUNTRIES}
            {...props.form.register("supplementalCountry", {
              required: defaultRequiredMessage(props.intl),
            })}
            autoComplete="country-name"
          />
          <FormattedFieldError
            inputName="supplementalCountry"
            error={
              props.form.formState.errors.supplementalCountry ??
              props.unsupportedSupplementalCountryError
            }
          />
          {props.supplementalIdOptions.length > 0 && (
            <RadioGroup label={props.supplementalIdLabel} groupError={props.supplementalIdError}>
              {props.supplementalIdOptions.map(({ description }) => {
                const disabledMessageId = v4();
                const disabled =
                  props.primaryCountryCode === props.supplementalCountryCode &&
                  props.primaryIdTypeValue === description;
                return (
                  <Fragment key={`supplemental-${description}`}>
                    <OptionBar
                      label={description}
                      disabled={disabled}
                      input={
                        <RadioInput
                          disabled={disabled}
                          aria-describedby={disabled ? disabledMessageId : undefined}
                          value={description}
                          aria-invalid={isAriaInvalid(
                            props.form.formState.errors.supplementalIdType,
                          )}
                          {...props.form.register("supplementalIdType", {
                            required:
                              props.supplementalIdConstraint === "required"
                                ? defaultRequiredMessage(props.intl)
                                : undefined,
                          })}
                        />
                      }
                    />
                    {disabled && <SelectionDisabledInfo id={disabledMessageId} />}
                  </Fragment>
                );
              })}
            </RadioGroup>
          )}
        </div>
      )}
    </>
  );
}

function DesktopForm(props: SubProps) {
  const { showPrimary, showSecondary } = showPrimaryAndOrSecondary(props);
  return (
    <>
      {showPrimary && (
        <Card
          header={<Substyle textStyle="headingSix">{props.primaryIdHeading}</Substyle>}
          fullWidth
          className={Styles.desktopCard}
        >
          <FormattedMessage
            id="15c53b17-7ecb-4f48-b346-21b51b9caf8d"
            defaultMessage="Must show a photo of your face, date of birth, and expiration date."
            tagName="p"
          />
          <Select
            label={<PrimaryCountryLabel />}
            data-automation-id="primary-country-select"
            id={props.selectPrimaryCountry}
            aria-invalid={isAriaInvalid(
              props.form.formState.errors.primaryCountry ?? props.unsupportedPrimaryCountryError,
            )}
            items={COUNTRIES}
            {...props.form.register("primaryCountry", {
              required: defaultRequiredMessage(props.intl),
            })}
            autoComplete="country-name"
          />
          <FormattedFieldError
            inputName="primaryCountry"
            error={
              props.form.formState.errors.primaryCountry ?? props.unsupportedPrimaryCountryError
            }
          />
          <Select
            label={props.primaryIdLabel}
            data-automation-id="primary-id-select"
            id={props.selectPrimaryId}
            aria-invalid={isAriaInvalid(props.form.formState.errors.primaryIdType)}
            items={[
              { label: props.intl.formatMessage(messages.selectId), value: "" },
              ...props.primaryIdOptions
                .filter(
                  ({ description }) =>
                    !(
                      props.primaryCountryCode === props.supplementalCountryCode &&
                      description === props.supplementalIdTypeValue
                    ),
                )
                .map(({ description }) => {
                  return { value: description, label: description };
                }),
            ]}
            {...props.form.register("primaryIdType", {
              required: defaultRequiredMessage(props.intl),
            })}
          />
          {props.primaryIdError}
        </Card>
      )}

      {showSecondary && (
        <Card
          header={<Substyle textStyle="headingSix">{props.supplementalHeading} </Substyle>}
          fullWidth
          className={Styles.desktopCard}
        >
          <Select
            label={
              <SupplementalCountryLabel supplementalIdConstraint={props.supplementalIdConstraint} />
            }
            data-automation-id="supplemental-country-select"
            id={props.selectSupplementalCountry}
            aria-invalid={isAriaInvalid(
              props.form.formState.errors.supplementalCountry ??
                props.unsupportedSupplementalCountryError,
            )}
            items={COUNTRIES}
            {...props.form.register("supplementalCountry", {
              required: defaultRequiredMessage(props.intl),
            })}
            autoComplete="country-name"
          />
          <FormattedFieldError
            inputName="supplementalCountry"
            error={
              props.form.formState.errors.supplementalCountry ??
              props.unsupportedSupplementalCountryError
            }
          />
          <Select
            label={props.supplementalIdLabel}
            data-automation-id="supplemental-id-select"
            id={props.selectSupplementalId}
            aria-invalid={isAriaInvalid(props.form.formState.errors.supplementalIdType)}
            items={[
              { label: props.intl.formatMessage(messages.selectId), value: "" },
              ...props.supplementalIdOptions
                .filter(
                  ({ description }) =>
                    !(
                      props.primaryCountryCode === props.supplementalCountryCode &&
                      props.primaryIdTypeValue === description
                    ),
                )
                .map(({ description }) => {
                  return { value: description, label: description };
                }),
            ]}
            {...props.form.register("supplementalIdType", {
              required:
                props.supplementalIdConstraint === "required"
                  ? defaultRequiredMessage(props.intl)
                  : undefined,
            })}
          />
          {props.supplementalIdError}
        </Card>
      )}
    </>
  );
}
