import { Navigate } from "react-router-dom";

import { useActiveOrganization } from "common/account/active_organization";
import LoadingIndicator from "common/core/loading_indicator";
import { BusinessToolsMobileDevice } from "common/proof_frame/mobile_nav/empty_states";
import { useQuery } from "util/graphql/query";

import { ToolsSidebar } from "./tools_sidebar";
import ToolsQuery, { type Tools_node_Organization as Organization } from "./index_query.graphql";
import Styles from "./index.module.scss";
import { useShowTools } from "./util";

export function Tools({ showMobileDeviceContent }: { showMobileDeviceContent: boolean }) {
  const [activeOrganizationId] = useActiveOrganization();
  const { data, loading } = useQuery(ToolsQuery, {
    variables: { organizationId: activeOrganizationId! },
  });

  const organization = data?.node as Organization | null;
  const { hasToolsFeatures } = useShowTools(
    organization?.featureList,
    organization?.organizationType,
  );

  if (loading || !data?.node) {
    return <LoadingIndicator />;
  }
  if (!hasToolsFeatures) {
    return <Navigate to="/" />;
  }
  if (showMobileDeviceContent) {
    return <BusinessToolsMobileDevice />;
  }

  return (
    <div className={Styles.wrapper}>
      <ToolsSidebar organization={organization!} />
    </div>
  );
}
