import { memo, useState, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { NotaryCapacityType } from "graphql_globals";
import FormattedPrice from "common/core/format/formatted_price";
import { NSTSectionTitle } from "common/get_started/common/section";
import WarnNavigateOffNotarizeModal from "common/notary/profile_wizard/section/payout/warn_navigate_off_notarize";
import Icon from "common/core/icon";
import { NotaryLevelBadge } from "common/notary/level";
import ActionButton from "common/core/action_button";
import { isNotaryODN } from "common/notary/capacity";
import ProofSeal from "assets/images/logos/proof-seal.svg";
import WorkflowModal from "common/modals/workflow_modal";

import SectionStyles from "../common/section/index.module.scss";
import Styles from "./notary_stats_overview.module.scss";
import type {
  BusinessGetStarted_viewer as Viewer,
  BusinessGetStarted_viewer_user_notaryProfile as NotaryProfile,
  BusinessGetStarted_viewer_user_notaryProfile_notaryStats as NotaryStats,
} from "./business_get_started_query.graphql";

type Props = {
  viewer: Viewer;
  handleLearnMoreAboutTiers: () => void;
};

type ProofPulseProps = {
  notaryStats: NotaryStats;
};

type ProofPulseStatsProps = {
  notaryStats: NotaryStats;
  notaryProfile: NotaryProfile;
  handleLearnMoreAboutTiers: () => void;
};

type StatBoxProps = {
  title: ReactNode;
  actions?: ReactNode;
  children: ReactNode;
};

type StatsGridProps = {
  notaryProfile: NotaryProfile;
  handleLearnMoreAboutTiers: () => void;
  onStripeClick: () => void;
  showStripe: boolean;
};

function StatBox({ title, actions, children }: StatBoxProps) {
  return (
    <div className={Styles.statSquare}>
      <div className={Styles.statSquareContentWrapper}>{children}</div>
      <p className={Styles.statTitle}>{title}</p>
      {actions}
    </div>
  );
}

function StatsGrid({
  notaryProfile,
  handleLearnMoreAboutTiers,
  onStripeClick,
  showStripe,
}: StatsGridProps) {
  return (
    <div className={Styles.statsGrid}>
      {showStripe && (
        <StatBox
          title={
            <FormattedMessage
              id="6148be78-00df-4346-b326-c5e2c3c92920"
              defaultMessage="Available for payout"
            />
          }
          actions={
            <ActionButton onClick={onStripeClick}>
              <FormattedMessage
                id="617cc8dc-2b4b-47ff-8926-96303607a874"
                defaultMessage="View Stripe"
              />
            </ActionButton>
          }
        >
          <Icon name="card" size="large" />
          <FormattedPrice cents={notaryProfile.stripeBalance} />
        </StatBox>
      )}
      {isNotaryODN(notaryProfile) && (
        <StatBox
          title={
            <FormattedMessage
              id="70c296c7-a3c5-4ec0-aff8-bf96733dd275"
              defaultMessage="Notary tier level"
            />
          }
          actions={
            <ActionButton onClick={handleLearnMoreAboutTiers}>
              <FormattedMessage
                id="617cc8dc-2b4b-47ff-8926-96303607a874"
                defaultMessage="View more"
              />
            </ActionButton>
          }
        >
          <div>
            <Icon name="notary-level" size="large" />
            <NotaryLevelBadge level={notaryProfile.notaryLevel} size="large" />
          </div>
        </StatBox>
      )}
      <StatBox
        title={
          <FormattedMessage
            id="77b46906-fcfb-44a7-89f4-f8b40654191c"
            defaultMessage="Customer satisfaction score"
          />
        }
      >
        <Icon name="notaries" size="large" />
        {notaryProfile.averageRating}
      </StatBox>
      <StatBox
        title={
          <FormattedMessage
            id="5d80489d-b0f6-4a0a-8582-75361b376fc0"
            defaultMessage="Seals placed"
          />
        }
      >
        <Icon name="notary-seal" size="large" className={Styles.iconNotarySeal} />
        {notaryProfile.sealsPlaced}
      </StatBox>
    </div>
  );
}

function StatSection({
  title,
  actions,
  children,
}: {
  title: ReactNode;
  actions?: ReactNode;
  children: ReactNode;
}) {
  return (
    <section>
      <div className={Styles.statContent}>{children}</div>
      <p className={Styles.statTitle}>{title}</p>
      {actions}
    </section>
  );
}

function Stats({ viewer, handleLearnMoreAboutTiers }: Props) {
  const notaryProfile = viewer.user!.notaryProfile!;
  const { stripeBalance, stripeConnected, stripeLoginLink } = notaryProfile;
  const [showWarnNavigateOffNotarizeModal, setShowWarnNavigateOffNotarizeModal] = useState(false);

  const renderWarnNavigateOffNotarizeModal = ({ redirectUrl }: { redirectUrl: string }) => {
    const cancel = () => {
      setShowWarnNavigateOffNotarizeModal(false);
    };
    return (
      <WarnNavigateOffNotarizeModal
        onContinue={() => {
          window.open(redirectUrl);
          cancel();
        }}
        onCancel={cancel}
      />
    );
  };

  return (
    <div className={SectionStyles.nstSection}>
      <NSTSectionTitle
        title={
          <FormattedMessage id="ab8e0461-8335-4df8-a796-7d75d8334044" defaultMessage="Your stats" />
        }
      />
      {stripeConnected &&
        stripeLoginLink &&
        showWarnNavigateOffNotarizeModal &&
        renderWarnNavigateOffNotarizeModal({ redirectUrl: stripeLoginLink })}
      <div className={Styles.onDemandStats}>
        {stripeConnected && stripeLoginLink && (
          <StatSection
            title={
              <FormattedMessage
                id="6148be78-00df-4346-b326-c5e2c3c92920"
                defaultMessage="Available for payout"
              />
            }
            actions={
              <ActionButton
                className={Styles.viewStripeLink}
                onClick={() => setShowWarnNavigateOffNotarizeModal(true)}
              >
                <FormattedMessage
                  id="617cc8dc-2b4b-47ff-8926-96303607a874"
                  defaultMessage="View Stripe"
                />
              </ActionButton>
            }
          >
            <div className={Styles.statContentWrapper}>
              <Icon name="card" size="large" />
              <FormattedPrice cents={stripeBalance} />
            </div>
          </StatSection>
        )}

        {isNotaryODN(notaryProfile) && (
          <>
            <StatSection
              title={
                <FormattedMessage
                  id="70c296c7-a3c5-4ec0-aff8-bf96733dd275"
                  defaultMessage="Your tier"
                />
              }
              actions={
                <ActionButton className={Styles.levelLink} onClick={handleLearnMoreAboutTiers}>
                  <FormattedMessage
                    id="617cc8dc-2b4b-47ff-8926-96303607a874"
                    defaultMessage="View more"
                  />
                </ActionButton>
              }
            >
              <NotaryLevelBadge level={notaryProfile.notaryLevel} size="large" />
            </StatSection>

            <StatSection
              title={
                <FormattedMessage
                  id="77b46906-fcfb-44a7-89f4-f8b40654191c"
                  defaultMessage="Customer satisfaction score"
                />
              }
            >
              <div className={Styles.statContentWrapper}>
                <Icon name="notaries" size="large" />
                {notaryProfile.averageRating}
              </div>
            </StatSection>
          </>
        )}

        <StatSection
          title={
            <FormattedMessage
              id="5d80489d-b0f6-4a0a-8582-75361b376fc0"
              defaultMessage="Seals placed"
            />
          }
        >
          <div className={Styles.statContentWrapper}>
            <Icon className={Styles.iconNotarySeal} name="notary-seal" size="large" />
            {notaryProfile.sealsPlaced}
          </div>
        </StatSection>
      </div>
    </div>
  );
}

function OdnStatsIntroduction({ handleLearnMoreAboutTiers }: Omit<Props, "viewer">) {
  return (
    <div className={SectionStyles.nstSection}>
      <div className={Styles.panel}>
        <FormattedMessage
          id="366a8aba-460d-47f2-9b74-e3b3d1b14d66"
          tagName="h3"
          defaultMessage="On-Demand stats & tiers"
        />
        <p>
          <FormattedMessage
            id="ec73b6f6-58c5-4143-ab7c-99e2a8e694e5"
            defaultMessage="Once you complete your first On-Demand meeting, your stats and active tier information will appear here."
          />
        </p>
        <p>
          <ActionButton className={Styles.levelLink} onClick={handleLearnMoreAboutTiers}>
            <FormattedMessage
              id="617cc8dc-2b4b-47ff-8926-96303607a874"
              defaultMessage="Learn more about tiers"
            />
          </ActionButton>
        </p>
      </div>
    </div>
  );
}

function ProofPulseStats({
  notaryStats,
  notaryProfile,
  handleLearnMoreAboutTiers,
}: ProofPulseStatsProps) {
  const { stripeConnected, stripeLoginLink } = notaryProfile;
  const showStripe = stripeConnected && !!stripeLoginLink;
  const [showWarnNavigateOffNotarizeModal, setShowWarnNavigateOffNotarizeModal] = useState(false);

  return (
    <>
      <div className={Styles.proofPulseStats}>
        <StatsGrid
          notaryProfile={notaryProfile}
          handleLearnMoreAboutTiers={handleLearnMoreAboutTiers}
          onStripeClick={() => setShowWarnNavigateOffNotarizeModal(true)}
          showStripe={showStripe}
        />
        <ProofPulse notaryStats={notaryStats} />
      </div>

      {showStripe && showWarnNavigateOffNotarizeModal && (
        <WarnNavigateOffNotarizeModal
          onContinue={() => {
            window.open(stripeLoginLink);
            setShowWarnNavigateOffNotarizeModal(false);
          }}
          onCancel={() => setShowWarnNavigateOffNotarizeModal(false)}
        />
      )}
    </>
  );
}

function ProofPulse({ notaryStats }: ProofPulseProps) {
  const { totalCalls, successRate, csat, refundCount, instantTerminations, month } = notaryStats;
  const monthYear = new Intl.DateTimeFormat("en-US", {
    month: "long",
    year: "numeric",
    timeZone: "UTC",
  })
    .formatToParts(new Date(month))
    .map((part) => part.value)
    .filter((part) => part.trim())
    .join(", ");
  const [isLearnMoreOpen, setIsLearnMoreOpen] = useState(false);

  return (
    <div className={Styles.proofPulse}>
      <div className={Styles.header}>
        <div className={Styles.titleSection}>
          <img className={Styles.proofSeal} src={ProofSeal} alt="Proof Seal" />
          <h2 className={Styles.title}>
            <FormattedMessage
              id="4e99a014-b1a4-4145-bd21-6b743d245165"
              defaultMessage="Proof Pulse"
            />
          </h2>
        </div>
        <ActionButton className={Styles.learnMore} onClick={() => setIsLearnMoreOpen(true)}>
          <FormattedMessage id="624bec9c-b39b-4b1e-a45b-1c87e87f55de" defaultMessage="Learn more" />
        </ActionButton>
      </div>
      <div className={Styles.date}>{monthYear}</div>
      <table className={Styles.statsTable}>
        <tbody>
          <tr>
            <td className={Styles.label}>
              <FormattedMessage
                id="8f3d4e9a-b5c7-4b5d-9f3e-8d2c7b9e5a1d"
                defaultMessage="Total Calls"
              />
            </td>
            <td className={Styles.value}>{totalCalls}</td>
          </tr>
          <tr>
            <td className={Styles.label}>
              <FormattedMessage
                id="2a7b9c4d-e6f8-4a5b-8c9d-1e3f4a5b6c7d"
                defaultMessage="Success Rate"
              />
            </td>
            <td className={Styles.value}>{successRate * 100}%</td>
          </tr>
          <tr>
            <td className={Styles.label}>
              <FormattedMessage id="3e5f8d2c-a1b9-4c7d-b6e5-f8a9b7c6d5e4" defaultMessage="CSAT" />
            </td>
            <td className={Styles.value}>{csat.toFixed(2)}</td>
          </tr>
          <tr>
            <td className={Styles.label}>
              <FormattedMessage
                id="4d6e8f2a-b3c5-4d7e-9f8a-b2c4d6e8f2a1"
                defaultMessage="Refunds"
              />
            </td>
            <td className={Styles.value}>{refundCount}</td>
          </tr>
          <tr>
            <td className={Styles.label}>
              <FormattedMessage
                id="5a7b9c4d-e6f8-4a5b-8c9d-1e3f4a5b6c7d"
                defaultMessage="Instant terminations"
              />
            </td>
            <td className={Styles.value}>{instantTerminations}</td>
          </tr>
        </tbody>
      </table>
      {isLearnMoreOpen && <LearnMoreModal onClose={() => setIsLearnMoreOpen(false)} />}
    </div>
  );
}

function LearnMoreModal({ onClose }: { onClose: () => void }) {
  return (
    <WorkflowModal
      className={Styles.learnMoreModal}
      closeBehavior={{ tag: "with-button", onClose }}
      title={
        <FormattedMessage id="93aaf52f-39e2-4b39-90c2-484c6d311716" defaultMessage="Proof Pulse" />
      }
    >
      <p className={Styles.learnMoreParagraph}>
        <FormattedMessage
          id="94dc7cba-6eee-4fdf-841a-efb8da8ffa22"
          defaultMessage="As a Proof notary partner, it is critical that you have visibility into your own performance. Below are details on how Proof Pulse is calculated:"
        />
      </p>

      <ul className={Styles.learnMoreList}>
        <li>
          <FormattedMessage
            id="92d1f92a-32a6-410f-adc1-5e78bc2e5429"
            defaultMessage="Total Calls: Number of calls picked up, reflecting engagement."
          />
        </li>
        <li>
          <FormattedMessage
            id="c3e9b4da-2ff9-4d58-860a-c3c295e5134e"
            defaultMessage="Success Rate: Total Calls divided by completed meetings."
          />
        </li>
        <li>
          <FormattedMessage
            id="790a3eb7-5172-42c3-8f77-8af4e425778e"
            defaultMessage="CSAT: Customer satisfaction score, visible from the Get Started page."
          />
        </li>
        <li>
          <FormattedMessage
            id="966166ca-5e4b-453d-8fb2-8959b3146a29"
            defaultMessage="Total Refunds: Number of refunds issued due to notary error."
          />
        </li>
        <li>
          <FormattedMessage
            id="51b234f7-5c89-4b73-8780-26dc5ed072ab"
            defaultMessage="Instant Terminations: Volume of Total Calls terminated within 1 minute."
          />
        </li>
      </ul>

      <p className={Styles.learnMoreParagraph}>
        <FormattedMessage
          id="4f5c70d9-a59f-49cf-a8c0-34922c0baaab"
          defaultMessage="Please use this to better service customers and signers. For example, Total Refunds is indicative of error type and frequency. Instant Terminations highlights opportunities to troubleshoot with a customer in an attempt to reach a completed meeting. In combination, working against these may increase your pay"
        />
      </p>
    </WorkflowModal>
  );
}

function NotaryStatsOverview({ viewer, handleLearnMoreAboutTiers }: Props) {
  const onDemandCapacity = viewer.user?.notaryProfile?.capacities.find(
    ({ type }) => type === NotaryCapacityType.ON_DEMAND,
  );
  const meetingCount = onDemandCapacity?.completedMeetingCount || 0;
  const notaryProfile = viewer.user!.notaryProfile!;
  const { notaryStats } = notaryProfile;

  if (onDemandCapacity && meetingCount === 0) {
    return <OdnStatsIntroduction handleLearnMoreAboutTiers={handleLearnMoreAboutTiers} />;
  }
  // notaryStats field always queries the current month's stats.
  // If it's null, the notary has not completed any meetings this month yet and we will not show the Proof Pulse stats.
  if (onDemandCapacity && notaryStats !== null) {
    return (
      <ProofPulseStats
        notaryStats={notaryStats}
        notaryProfile={notaryProfile}
        handleLearnMoreAboutTiers={handleLearnMoreAboutTiers}
      />
    );
  }
  return <Stats viewer={viewer} handleLearnMoreAboutTiers={handleLearnMoreAboutTiers} />;
}

export default memo(NotaryStatsOverview);
