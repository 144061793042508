import { type ReactNode, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { merge } from "rxjs";

import {
  useIDVInteraction,
  type IDVInteraction,
  BiometricConsent,
} from "common/identity_verification/interaction";
import { Persona } from "common/identity_verification/interaction/persona";
import { Socure } from "common/identity_verification/interaction/socure";
import { IDSelection } from "common/identity_verification/id_selection";
import { Intro } from "common/identity_verification/intro";
import { getPhotoIdentificationRequirements } from "common/identity_verification/util";
import { PhotoType, photoTypeToPhotoIdentification } from "common/identity/document_viewer/engine";
import { fromSocketEvent } from "socket/util";
import type Channel from "socket/channel";
import { useQuery } from "util/graphql";
import { StepType, SignerIdentityRoles, ProofRequirementMfa } from "graphql_globals";
import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import { useId } from "util/html";
import useIDVService from "common/identity_verification/service";
import IdentityVerificationQuery, {
  type IdentityVerification_viewer_signerStepsV2_CredentialAnalysisStep as CredentialAnalysisStep,
  type IdentityVerification_bundle_DocumentBundle as DocumentBundle,
  type IdentityVerification_signerIdentity_SignerIdentity as SignerIdentity,
  type IdentityVerification_viewer as Viewer,
} from "common/identity_verification/index.graphql";
import { getCustomerSignerName } from "util/customer_signer";
import { DOC_CATEGORIES } from "constants/document";

import { type RetakeHandlerMeeting as Meeting } from "./meeting.fragment.graphql";
import Styles from "./index.module.scss";

export type RetakeStep = "persona" | "socure" | "id-selection" | "intro" | null;

type CancelRetakeSocketData = {
  signer_identity_id: string;
};

type PhotoRequestSocketData = {
  action: "photo_request" | "photo_id_with_biometrics_request";
  signer_identity_id: string;
  requires_biometrics: boolean;
  individual_retake: PhotoType | null;
};

type WrapperProps = {
  children: (args: { retakeStep: RetakeStep }) => ReactNode;
  channel: Channel;
  meeting?: Meeting | null;
};

type QueryMakerProps = {
  channel: Channel;
  meeting: Meeting;
  retakeStep: RetakeStep;
  setRetakeStep: (value: RetakeStep) => void;
};

type ContentProps = {
  idvInteraction: IDVInteraction;
  individualRetake: PhotoType | null;
  retakeStep: RetakeStep;
  setRetakeStep: (value: RetakeStep) => void;
  bundle: DocumentBundle;
  signerIdentity: SignerIdentity;
  viewer: Viewer;
  onProviderResults: () => void;
  onProviderClosed: () => void;
};

function RetakeHandlerContent(props: ContentProps) {
  const {
    bundle,
    viewer,
    signerIdentity,
    individualRetake,
    retakeStep,
    setRetakeStep,
    idvInteraction,
    onProviderResults,
    onProviderClosed,
  } = props;
  const { signerStepsV2: signerSteps } = viewer;
  const { id: signerIdentityId, customer, role, proofRequirement } = signerIdentity;
  const formId = useId();
  const [photoIdRequirementsLoading, setPhotoIdRequirementsLoading] = useState(true);
  const { initialize, loading: idvServiceLoading } = useIDVService({
    signerIdentityId,
    onInquiryCreation: ({ inquiryId, vendor, vendorSdkKey }) => {
      idvInteraction.onInputChange({ source: "service", inquiryId, vendorSdkKey });
      setRetakeStep(vendor);
    },
  });
  if (!customer) {
    throw new Error("Error accessing customer information");
  }

  const namePrompt = getCustomerSignerName(
    bundle.organizationTransaction.customerSigners,
    customer,
  );
  const photoIdentificationRequirements = getPhotoIdentificationRequirements({
    signerSteps: viewer.signerStepsV2,
  });
  const caStepIndex = signerSteps.findIndex(
    (step) => step.stepType === StepType.CREDENTIAL_ANALYSIS && step.subjectId === signerIdentityId,
  );
  const caStep = signerSteps[caStepIndex] as CredentialAnalysisStep;
  const isPs1583Bundle = bundle.documents.edges.some(
    (edge) =>
      edge.node.classification?.category?.toUpperCase() === DOC_CATEGORIES.PS1583 ||
      edge.node.classification?.category?.toUpperCase() === DOC_CATEGORIES.PS1583_ATTESTATION,
  );

  const caSelfie = proofRequirement?.ca?.selfie;
  const smsProof = proofRequirement?.mfa?.type === ProofRequirementMfa.SMS;
  const ial2Proof = Boolean(caSelfie && smsProof);

  return (
    <>
      {retakeStep === "intro" && (
        <WorkflowModal
          buttons={[
            <Button
              key="continue"
              variant="primary"
              buttonColor="action"
              fullwidth
              onClick={() => {
                individualRetake === PhotoType.Selfie
                  ? initialize({
                      type: "service",
                      primaryCountry: null,
                      primaryDocumentType: null,
                      secondaryCountry: null,
                      secondaryDocumentType: null,
                      desktopFlow: idvInteraction.configuration.enableDesktopCapture,
                      simulateFailures: idvInteraction.configuration.simulateFailures,
                      singleCredentialVerification:
                        photoTypeToPhotoIdentification(individualRetake),
                    })
                  : setRetakeStep("id-selection");
              }}
              isLoading={idvServiceLoading}
            >
              <FormattedMessage
                id="f35ea940-eb5c-4b04-ab40-a6a952d6ae94"
                defaultMessage="Accept and continue"
              />
            </Button>,
          ]}
          overlayClassName={Styles.modal}
          className={Styles.modalContent}
          footerText={<BiometricConsent />}
        >
          <Intro
            documentBundle={bundle}
            photoIdentificationRequirements={photoIdentificationRequirements}
            caStep={caStep}
            namePrompt={namePrompt}
            individualRetake={individualRetake}
            showAvailableIdOptions={false}
            idvInteraction={idvInteraction}
            loading={idvServiceLoading}
            identityVerifiedWitness={role === SignerIdentityRoles.IDENTITY_VERIFIED_WITNESS}
            isPs1583Bundle={isPs1583Bundle}
          />
        </WorkflowModal>
      )}
      {retakeStep === "id-selection" && (
        <WorkflowModal
          buttons={[
            <Button
              key="close"
              onClick={() => setRetakeStep("intro")}
              variant="secondary"
              buttonColor="action"
            >
              <FormattedMessage id="5881972b-2b9b-4403-9599-3c52a2322db9" defaultMessage="Back" />
            </Button>,
            <Button
              key="continue"
              type="submit"
              form={formId}
              variant="primary"
              buttonColor="action"
              disabled={photoIdRequirementsLoading}
              isLoading={idvServiceLoading}
            >
              <FormattedMessage
                id="169f4d35-e354-4fa1-b3a3-a906a3d466f4"
                defaultMessage="Continue"
              />
            </Button>,
          ]}
          overlayClassName={Styles.modal}
        >
          <IDSelection
            loading={idvServiceLoading}
            signerIdentityId={signerIdentityId}
            individualRetake={individualRetake}
            onContinue={(fv) => {
              if (fv.type === "service") {
                initialize({
                  ...fv,
                  desktopFlow: idvInteraction.configuration.enableDesktopCapture,
                  simulateFailures: idvInteraction.configuration.simulateFailures,
                  singleCredentialVerification: photoTypeToPhotoIdentification(individualRetake),
                });
              } else {
                idvInteraction.onInputChange({ source: "manual", ...fv });
                setRetakeStep("persona");
              }
            }}
            onBack={() => setRetakeStep("intro")}
            onLoadingEvent={setPhotoIdRequirementsLoading}
            formId={formId}
            externalSecondaryIdConstraint={ial2Proof ? "required" : "optional"}
            size="mobile"
            isPs1583Bundle={isPs1583Bundle}
            isIal2Proof={ial2Proof}
          />
        </WorkflowModal>
      )}
      {retakeStep === "persona" && (
        <Persona
          caStep={caStep}
          signerIdentity={signerIdentity}
          idvInteraction={idvInteraction}
          onComplete={onProviderResults}
          onClose={onProviderClosed}
          organizationTransaction={bundle.organizationTransaction}
        />
      )}
      {retakeStep === "socure" && (
        <Socure
          idvInteraction={idvInteraction}
          onComplete={onProviderResults}
          onClose={onProviderClosed}
          signerIdentityId={signerIdentityId}
        />
      )}
    </>
  );
}

function RetakeHandler(props: QueryMakerProps) {
  const { channel, meeting, retakeStep, setRetakeStep } = props;
  const [signerIdentityId, setSignerIdentityId] = useState<string | null>(null);
  const [individualRetake, setIndividualRetake] = useState<PhotoType | null>(null);
  const idvInteraction = useIDVInteraction();
  const { loading, data } = useQuery(IdentityVerificationQuery, {
    variables: {
      documentBundleId: meeting.documentBundle!.id,
      signerIdentityId: signerIdentityId!,
      signerIdentityIds: [signerIdentityId!],
    },
    skip: !signerIdentityId || retakeStep === null,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    const cancelRetakeSubscription = fromSocketEvent<CancelRetakeSocketData>(
      channel,
      "cancel_retake",
    ).subscribe({
      next: (event) => {
        if (event.signer_identity_id === signerIdentityId) {
          setSignerIdentityId(null);
          setRetakeStep(null);
        }
      },
    });
    const photoRequestSubscription = merge(
      fromSocketEvent<PhotoRequestSocketData>(channel, "photo_request"),
      fromSocketEvent<PhotoRequestSocketData>(channel, "photo_id_with_biometrics_request"),
    ).subscribe({
      next: (event) => {
        idvInteraction.onConfigurationChange({
          requiresBiometrics: Boolean(
            event.action === "photo_id_with_biometrics_request" || event.requires_biometrics,
          ),
        });
        setSignerIdentityId(event.signer_identity_id);
        setRetakeStep("intro");
        setIndividualRetake(event.individual_retake);
      },
    });
    return () => {
      cancelRetakeSubscription.unsubscribe();
      photoRequestSubscription.unsubscribe();
    };
  }, [channel, signerIdentityId]);

  if (retakeStep === null || loading || !data) {
    return null;
  }

  const { bundle, viewer } = data;
  const signerIdentity = data.signerIdentity!;

  if (bundle?.__typename !== "DocumentBundle") {
    throw new Error(`Expected DocumentBundle, got ${bundle?.__typename}`);
  }

  if (signerIdentity.__typename !== "SignerIdentity") {
    throw new Error(`Expected SignerIdentity, got ${signerIdentity.__typename}`);
  }

  const handleProviderResults = () => {
    setSignerIdentityId(null);
    setRetakeStep(null);
  };

  const handleRetakeCanceled = () => {
    channel.sendMessage("retake_canceled", {
      signer_identity_id: signerIdentity.id,
    });
    setSignerIdentityId(null);
    setRetakeStep(null);
  };

  return (
    <>
      <RetakeHandlerContent
        retakeStep={retakeStep}
        setRetakeStep={setRetakeStep}
        individualRetake={individualRetake}
        idvInteraction={idvInteraction}
        bundle={bundle}
        signerIdentity={signerIdentity}
        viewer={viewer}
        onProviderClosed={handleRetakeCanceled}
        onProviderResults={handleProviderResults}
      />
    </>
  );
}

export function RetakeHandlerWrapper(props: WrapperProps) {
  const { channel, children, meeting } = props;
  const [retakeStep, setRetakeStep] = useState<RetakeStep>(null);

  return (
    <>
      {meeting && (
        <RetakeHandler
          channel={channel}
          meeting={meeting}
          retakeStep={retakeStep}
          setRetakeStep={setRetakeStep}
        />
      )}
      {children({ retakeStep })}
    </>
  );
}
