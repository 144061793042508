import { useCallback, useEffect, useRef, useState } from "react";
import { concat, defer, filter, of, switchMap, take } from "rxjs";
import SigmaDeviceManager from "@socure-inc/device-risk-sdk";

import Env from "config/environment";
import { useScript, type UseScriptStatus } from "common/core/hooks/use-script";
import { segmentTrack } from "util/segment";
import { useSubject } from "util/rxjs/hooks";
import { retryWhenWithCaptureException } from "util/rxjs";

const SOCURE_SDK_BUNDLE_SRC = "https://websdk.socure.com/bundle.js";

enum SocureProgressEventStatus {
  WAITING_FOR_USER_TO_REDIRECT = "WAITING_FOR_USER_TO_REDIRECT",
  WAITING_FOR_UPLOAD = "WAITING_FOR_UPLOAD",
}

export enum SocureErrorEventStatus {
  CONSENT_DECLINED = "CONSENT_DECLINED",
  DOCUMENTS_UPLOAD_FAILED = "DOCUMENTS_UPLOAD_FAILED",
}

export type SocureProgressEvent = {
  status: SocureProgressEventStatus;
};

export type SocureErrorEvent = {
  status: SocureErrorEventStatus;
};

type SocureConfig = {
  onProgress: (event: SocureProgressEvent) => void;
  onSuccess: (event: unknown) => void;
  onError: (event: SocureErrorEvent) => void;
  qrCodeNeeded: boolean;
  disableSmsInput: boolean;
};

export type OnSuccess = SocureConfig["onSuccess"];

type InteractionProps = {
  docVTransactionToken: string;
  sdkElement: string;
  sdkApiKey: string;
  onSuccess: SocureConfig["onSuccess"];
  onProgress?: SocureConfig["onProgress"];
  onError?: SocureConfig["onError"];
  qrCodeNeeded?: SocureConfig["qrCodeNeeded"];
  disableSmsInput?: SocureConfig["disableSmsInput"];
};

export type SocureDocVSDK = {
  launch: (
    sdkKey: string,
    docVTransactionToken: string,
    webSDKElementIdentifier: string,
    config: SocureConfig,
  ) => Promise<unknown>;
  reset: () => void;
};

declare const window: Window & {
  SocureDocVSDK?: SocureDocVSDK;
};

/** Creates a Socure client per docVTransactionToken. Does not create a new client if callbacks change */
export function useSocureClient(props: InteractionProps) {
  const {
    docVTransactionToken,
    onError,
    onProgress,
    onSuccess,
    qrCodeNeeded = true,
    disableSmsInput = true,
    sdkElement,
    sdkApiKey,
  } = props;

  const moduleStatus = useScript(SOCURE_SDK_BUNDLE_SRC, {
    removeOnUnmount: true,
    id: "socure",
  });

  const [initializing, setInitializing] = useState(true);
  const onErrorRef = useRef<typeof onError | null>(onError);
  const onProgressRef = useRef<typeof onProgress | null>(onProgress);
  const onSuccessRef = useRef<typeof onSuccess | null>(onSuccess);
  const open$ = useSubject<UseScriptStatus>();

  useEffect(() => {
    onErrorRef.current = onError;
    onProgressRef.current = onProgress;
    onSuccessRef.current = onSuccess;
    return () => {
      onErrorRef.current = null;
      onProgressRef.current = null;
      onSuccessRef.current = null;
    };
  });

  const open = useCallback(() => {
    open$.next(moduleStatus);
  }, [moduleStatus, open$]);

  useEffect(() => {
    const subscription = open$
      .pipe(
        filter((moduleStatus) => moduleStatus === "ready"),
        take(1),
        switchMap(() => {
          const launch$ = defer(() =>
            window
              .SocureDocVSDK!.launch(sdkApiKey, docVTransactionToken, sdkElement, {
                onError: (...args) => onErrorRef.current?.(...args),
                onProgress: (...args) => onProgressRef.current?.(...args),
                onSuccess: (...args) => onSuccessRef.current?.(...args),
                qrCodeNeeded,
                disableSmsInput,
              })
              .then(() => {
                segmentTrack("Opened Socure");
                return false;
              }),
          ).pipe(retryWhenWithCaptureException(1));
          return concat(of(true), launch$);
        }),
      )
      .subscribe(setInitializing);

    return () => {
      window.SocureDocVSDK?.reset();
      subscription.unsubscribe();
    };
  }, [docVTransactionToken, sdkApiKey, sdkElement, qrCodeNeeded, disableSmsInput]);

  return {
    open,
    initializing,
  };
}

/**
 * Initializes Socure's SigmaDeviceManager for device risk/intelligence.
 * @returns an async function that attempts to retrieve the device's session token.
 */
export function useSocureDeviceRisk(): () => Promise<string> {
  const { socureDeviceRiskSdkKey } = Env;

  useEffect(() => {
    if (socureDeviceRiskSdkKey) {
      SigmaDeviceManager.initialize({ sdkKey: socureDeviceRiskSdkKey });
    }
  }, [socureDeviceRiskSdkKey]);

  return useCallback(() => {
    if (!socureDeviceRiskSdkKey) {
      return Promise.reject("SDK key is not set");
    }

    return SigmaDeviceManager.getSessionToken();
  }, [socureDeviceRiskSdkKey]);
}
