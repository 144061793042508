import { type ReactNode, useState } from "react";
import { FormattedMessage } from "react-intl";

import Link from "common/core/link";
import { segmentTrack } from "util/segment";

import Styles from "./index.module.scss";

export type IDVInput =
  | {
      source: "manual";
      country: string;
      idClass: string | null;
      idClassSecondary: string | null;
      idTypeSecondary: string | null;
    }
  | {
      source: "service";
      inquiryId: string;
      vendorSdkKey: string | null;
    }
  | null;

export type IDVConfiguration = {
  enableDesktopCapture?: boolean;
  requiresBiometrics?: boolean;
  simulateFailures?: boolean;
};

export type IDVInteraction = ReturnType<typeof useIDVInteraction>;

export function useIDVInteraction(initialConfiguration?: IDVConfiguration) {
  const [input, setInput] = useState<IDVInput>(null);
  const [configuration, setConfiguration] = useState<IDVConfiguration>(
    initialConfiguration || {
      enableDesktopCapture: false,
      simulateFailures: false,
    },
  );

  const onConfigurationChange = (values: Partial<IDVConfiguration>) => {
    setConfiguration({ ...configuration, ...values });
  };

  return {
    input,
    configuration,
    onConfigurationChange,
    onInputChange: setInput,
  };
}

const biometricConsentLink = (msg: ReactNode[]) => {
  return (
    <Link
      onClick={() => segmentTrack("Clicked biometric consent terms link")}
      href={"https://www.proof.com/legal/biometric-consent"}
    >
      {msg}
    </Link>
  );
};

export function BiometricConsent() {
  return (
    <div className={Styles.biometricConsent}>
      <FormattedMessage
        tagName={"span"}
        id="7e425f29-b928-4769-8fe8-1b37374df817"
        defaultMessage={
          'By clicking "Accept and continue", I acknowledge that I have read and agree to the terms of the <biometricConsentLink>biometric consent</biometricConsentLink>.'
        }
        values={{ biometricConsentLink }}
      />
    </div>
  );
}
