import { memo, useEffect } from "react";
import { FormattedMessage } from "react-intl";

import Icon from "common/core/icon";
import { captureException } from "util/exception";
import { pushNotification } from "common/core/notification_center/actions";
import { useMutation } from "util/graphql";
import { useNotaryMeetingContext } from "common/meeting/notary/context";
import { fromSocketEvent, safeSubscribeToChannel } from "socket/util";
import { useSubject } from "util/rxjs/hooks";
import type { NotaryMeeting_meeting_Meeting_documentBundle_documents_edges_node as Document } from "common/meeting/notary/meeting_query.graphql";

import RequestInMeetingDocumentMutation from "./request_in_meeting_document_mutation.graphql";
import Styles from "./request_in_meeting_upload.module.scss";

type Props = {
  meetingId: string;
  activeParticipantId: string;
  onSuccess?: () => void;
};

export function useDocumentUploadNotification(onSelectDocument: (doc: Document) => void) {
  const { channel, refetch } = useNotaryMeetingContext();
  const endNotifier$ = useSubject<null>();
  useEffect(() => {
    safeSubscribeToChannel(
      endNotifier$,
      fromSocketEvent(channel, "meeting.document_bundle:document_added"),
      () => {
        refetch().then(({ data }) => {
          const meeting = data.meeting;
          if (meeting?.__typename !== "Meeting") {
            throw new Error(`Missing meeting, got ${meeting?.__typename}`);
          }
          const documentEdges = meeting.documentBundle!.documents.edges;
          const lastDocument = documentEdges[documentEdges.length - 1].node;
          return pushNotification({
            type: "MEETING",
            title: (
              <FormattedMessage
                id="ba3e0fdb-2477-43af-a89c-ff9e50224a1a"
                defaultMessage="Document Successfully Uploaded"
              />
            ),
            message: (
              <FormattedMessage
                id="a3fde507-39e2-449d-809a-95628951509a"
                defaultMessage="The signer has successfully uploaded their new document."
              />
            ),
            submessage: (
              <FormattedMessage
                id="34f6f1e7-cbcd-4470-b02e-22e344dc0cc3"
                defaultMessage="View It Now."
              />
            ),
            handleSubmessageEvent: () => onSelectDocument(lastDocument),
          });
        });
      },
    );
    return () => endNotifier$.next(null);
  }, [refetch, onSelectDocument]);
}

function InMeetingRequestUploadButton({ meetingId, activeParticipantId, onSuccess }: Props) {
  const requestInMeetingDocumentMutateFn = useMutation(RequestInMeetingDocumentMutation);
  const handleButtonClick = () => {
    requestInMeetingDocumentMutateFn({
      variables: {
        input: {
          meetingId,
          activeParticipantId,
        },
      },
    })
      .then(() => {
        pushNotification({
          type: "MEETING",
          message: (
            <FormattedMessage
              id="b96d487e-d95d-47cb-b5a3-7b7bfe1cbca8"
              defaultMessage="Signer has been notified that a new document is requested."
            />
          ),
        });
        onSuccess?.();
      })
      .catch((error: Error) => {
        captureException(error);
        pushNotification({
          type: "MEETING",
          title: (
            <FormattedMessage
              id="94ce2865-8a2f-47e8-ad24-7586a4363ee8"
              defaultMessage="Failed to request a new document from the signer."
            />
          ),
          message: error.message,
        });
      });
  };
  return (
    <button type="button" data-automation-id="request-in-meeting-doc" onClick={handleButtonClick}>
      <FormattedMessage
        id="136b8afc-fa9c-4db5-9a06-5e06e34aed22"
        defaultMessage="Request Document Upload"
      />
      <Icon name="arrow-up-square" className={Styles.icon} />
    </button>
  );
}

export default memo(InMeetingRequestUploadButton);
