import { useSearchParams } from "react-router-dom";

import WorkflowModal from "common/modals/workflow_modal";

import DotloopWizard from "./dotloop_wizard";
import Styles from "./dotloop.module.scss";

const QUERY_ARG = "import_loop";

export function useDotLoop() {
  const [rawQueryArgs, setRawQueryArgs] = useSearchParams();

  const showDotloopModal = Boolean(rawQueryArgs.get(QUERY_ARG));
  const enableDotloopModal = () => {
    rawQueryArgs.append(QUERY_ARG, "true");
    setRawQueryArgs(rawQueryArgs);
  };
  const closeDotloopModal = () => {
    rawQueryArgs.delete(QUERY_ARG);
    setRawQueryArgs(rawQueryArgs);
  };

  return {
    showDotloopModal,
    enableDotloopModal,
    closeDotloopModal,
  };
}

export function DotLoopModal({ onClose }: { onClose: () => void }) {
  return (
    <WorkflowModal className={Styles.dotloopModal}>
      <DotloopWizard onCancel={onClose} />
    </WorkflowModal>
  );
}
