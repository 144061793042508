import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useState, useRef } from "react";

import { BiometricConsent, type IDVInteraction } from "common/identity_verification/interaction";
import { PhotoType } from "common/identity/document_viewer/engine";
import { Paragraph } from "common/core/typography";
import { isMobileDevice } from "util/support";
import { Footer, Hint, MainHeading, NextStepButton } from "common/signer/common";
import { AcceptableFormsOfId } from "common/signer/bit_landing/content/proof";
import { stepRequiresSelfie } from "common/signer/signer_steps_v2/util";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";
import {
  CredentialAnalysisRequirement,
  IdentifyUseCase,
  StepState,
  StepType,
} from "graphql_globals";
import type { SignerStepsViewer_signerStepsV2 as Step } from "common/signer/signer_steps_v2/index.viewer.graphql";
import { DOC_CATEGORIES } from "constants/document";

import Ps1583DocumentModal from "./ps1583_document_modal";
import { DeviceSelector } from "./device_selector";
import { DevelopmentOptions } from "./development_options";
import { Heading } from "./heading";
import { Subheading } from "./subheading";
import { Body } from "./body";
import { Footer as FooterText } from "./footer";
import { type PhotoIdentificationRequirements } from "../util";
import {
  type IdentityVerification_bundle_DocumentBundle as DocumentBundle,
  type IdentityVerification_viewer_signerStepsV2_CredentialAnalysisStep as CredentialAnalysisStep,
} from "../index.graphql";
import Styles from "./index.module.scss";

type Props = {
  documentBundle: DocumentBundle;
  photoIdentificationRequirements: PhotoIdentificationRequirements;
  caStep: CredentialAnalysisStep;
  previousStep?: Step;
  namePrompt: string;
  individualRetake?: PhotoType | null;
  showAvailableIdOptions?: boolean;
  withBiometricConsentModal?: boolean;
  idvInteraction: IDVInteraction;
  onContinue?: () => void;
  identityVerifiedWitness: boolean;
  loading: boolean;
  isPs1583Bundle: boolean;
};

export type CommonContentProps = {
  previousStepWasKba: boolean;
  selfieRequired: boolean;
  namePrompt: string;
  requiresIdPhotos: boolean;
  showAvailableIdOptions: boolean;
};

export function Intro({
  documentBundle,
  photoIdentificationRequirements,
  caStep,
  previousStep,
  namePrompt,
  individualRetake,
  showAvailableIdOptions = true,
  withBiometricConsentModal = false,
  idvInteraction,
  onContinue,
  identityVerifiedWitness,
  loading,
  isPs1583Bundle,
}: Props) {
  const [showIdTypesModal, setShowIdTypesModal] = useState(false);
  const [showProofingDocument, setShowProofingDocument] = useState<boolean>(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const userIsOnMobile = isMobileDevice();
  const selfieRequired = stepRequiresSelfie(caStep);
  const intl = useIntl();
  useA11y().useDocumentEntitler({
    title: intl.formatMessage(useDocumentTitles().preMeetingCredentialAnalysisIntro),
  });

  const accountRecoveryIdentify =
    documentBundle.organizationTransaction.identifyUseCase === IdentifyUseCase.ACCOUNT_RECOVERY;
  const allowDesktopCapture =
    !userIsOnMobile && !identityVerifiedWitness && !accountRecoveryIdentify;

  const previousStepWasKba =
    previousStep?.stepType === StepType.KBA && previousStep.state === StepState.COMPLETED;

  const proofingDoc = documentBundle.documents.edges.find((edge) => edge.node.proofingRequired);
  const ps1583Doc = documentBundle.documents.edges.find(
    (edge) =>
      edge.node.classification?.category?.toUpperCase() === DOC_CATEGORIES.PS1583 ||
      edge.node.classification?.category?.toUpperCase() === DOC_CATEGORIES.PS1583_ATTESTATION,
  );
  const showProofingResource = Boolean(ps1583Doc || proofingDoc);
  const proofingPdfSrc =
    ps1583Doc?.node.s3OriginalAsset?.url || proofingDoc?.node.s3OriginalAsset?.url;
  const requiresIdPhotos =
    caStep.credentialAnalysisRequirement !== CredentialAnalysisRequirement.BIOMETRIC_ONLY;

  const commonContentProps = {
    selfieRequired,
    requiresIdPhotos,
    previousStepWasKba,
    namePrompt,
    showAvailableIdOptions,
  };

  if (individualRetake) {
    return (
      <IndividualRetakeIntro
        isRetail={documentBundle.organizationTransaction.isRetail}
        individualRetake={individualRetake}
        idvInteraction={idvInteraction}
        photoIdentificationRequirements={photoIdentificationRequirements}
        namePrompt={namePrompt}
        showAvailableIdOptions={showAvailableIdOptions}
        onContinue={onContinue}
        allowDesktopCapture={allowDesktopCapture}
        isPs1583Bundle={isPs1583Bundle}
        requiresIdPhotos={requiresIdPhotos}
        selfieRequired={selfieRequired}
      />
    );
  }

  return (
    <div className={Styles.container}>
      <Heading {...commonContentProps} />
      {(!userIsOnMobile || identityVerifiedWitness) && (
        <Subheading
          {...commonContentProps}
          identityVerifiedWitness={identityVerifiedWitness}
          isPs1583Bundle={isPs1583Bundle}
        />
      )}

      <DeviceSelector
        allowDesktopCapture={allowDesktopCapture}
        idvInteraction={idvInteraction}
        isPs1583Bundle={isPs1583Bundle}
        requiresIdPhotos={requiresIdPhotos}
        selfieRequired={selfieRequired}
      />

      <Body
        {...commonContentProps}
        showAvailableIdOptions={showAvailableIdOptions}
        onIDTypesHintClick={() => setShowIdTypesModal(true)}
        buttonRef={buttonRef}
      />

      <FooterText
        showProofingResource={showProofingResource}
        isAttestation={
          ps1583Doc?.node.classification?.category?.toUpperCase() ===
          DOC_CATEGORIES.PS1583_ATTESTATION
        }
        onShowDocument={() => {
          setShowProofingDocument(true);
        }}
        pdfSrc={proofingPdfSrc}
      />

      <DevelopmentOptions idvInteraction={idvInteraction} />

      {showIdTypesModal && (
        <AcceptableFormsOfId
          onClose={() => {
            setShowIdTypesModal(false);
            setTimeout(() => {
              // give time for FocusScope to try and restore focus before manually focusing
              if (buttonRef.current !== document.activeElement) {
                buttonRef.current?.focus();
              }
            }, 50);
          }}
          photoIdentificationRequirements={photoIdentificationRequirements}
          isPs1583Bundle={isPs1583Bundle}
        />
      )}

      {showProofingDocument && proofingPdfSrc && (
        <Ps1583DocumentModal
          pdfSrc={proofingPdfSrc}
          onClose={() => setShowProofingDocument(false)}
        />
      )}

      {onContinue && (
        <Footer
          nextStepButton={
            <NextStepButton
              automationId="id-verification-intro-continue"
              text={
                withBiometricConsentModal ? (
                  <FormattedMessage
                    id="f30a40c5-38ec-47b2-89cc-691c5c9237c6"
                    defaultMessage="Continue"
                  />
                ) : (
                  <FormattedMessage
                    id="bcd24bf0-03b8-49f0-a7df-4ecc4d0096dd"
                    defaultMessage="Accept and continue"
                  />
                )
              }
              onClick={onContinue}
              isLoading={loading}
            />
          }
          hint={withBiometricConsentModal ? undefined : <BiometricConsent />}
        />
      )}
    </div>
  );
}

const INDIVIDUAL_RETAKE_HEADING = defineMessages<PhotoType>({
  [PhotoType.Primary]: {
    id: "d53211ad-cde9-46ca-b5f2-0b09b164e2fa",
    defaultMessage: "{name}, capture your ID",
  },
  [PhotoType.Secondary]: {
    id: "47c588ab-49db-48aa-b00b-3b4d9aa2bfcb",
    defaultMessage: "{name}, capture your supplemental document",
  },
  [PhotoType.Selfie]: {
    id: "7c1bc91b-455f-4044-98ad-9dcc1af70f72",
    defaultMessage: "{name}, capture your selfie",
  },
});

const INDIVIDUAL_RETAKE_BODY = defineMessages<PhotoType>({
  [PhotoType.Primary]: {
    id: "feae1801-7f1e-48b9-865e-c859d3c53c43",
    defaultMessage:
      "A government ID is required to verify your identity. Avoid blurry or out of focus photos.",
  },
  [PhotoType.Secondary]: {
    id: "338e149b-2252-4419-8c31-07cfbf67201B",
    defaultMessage:
      "A supplemental document is required to verify your identity. Avoid blurry or out of focus photos.",
  },
  [PhotoType.Selfie]: {
    id: "923d7bf5-dbc1-4d00-ab77-dcd85e7f43f5",
    defaultMessage:
      "A selfie photo is required to verify your identity. Take photos head on, not at an angle.",
  },
});

type IndividualRetakeIntroProps = {
  individualRetake: PhotoType;
  idvInteraction: IDVInteraction;
  photoIdentificationRequirements: PhotoIdentificationRequirements;
  namePrompt: string;
  showAvailableIdOptions: boolean;
  isRetail: boolean;
  onContinue?: () => void;
  allowDesktopCapture: boolean;
  isPs1583Bundle: boolean;
  requiresIdPhotos: boolean;
  selfieRequired: boolean;
};

function IndividualRetakeIntro({
  individualRetake,
  idvInteraction,
  allowDesktopCapture,
  photoIdentificationRequirements,
  namePrompt,
  showAvailableIdOptions,
  onContinue,
  isPs1583Bundle,
  requiresIdPhotos,
  selfieRequired,
}: IndividualRetakeIntroProps) {
  const intl = useIntl();
  const [showIdTypesModal, setShowIdTypesModal] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  return (
    <div className={Styles.container}>
      <MainHeading leftAlign>
        {intl.formatMessage(INDIVIDUAL_RETAKE_HEADING[individualRetake], { name: namePrompt })}
      </MainHeading>
      <Paragraph>{intl.formatMessage(INDIVIDUAL_RETAKE_BODY[individualRetake])}</Paragraph>
      <DeviceSelector
        allowDesktopCapture={allowDesktopCapture}
        idvInteraction={idvInteraction}
        requiresIdPhotos={requiresIdPhotos}
        selfieRequired={selfieRequired}
      />
      {showAvailableIdOptions && individualRetake !== PhotoType.Selfie && (
        <Hint
          buttonRef={buttonRef}
          onClick={() => {
            setShowIdTypesModal(true);
          }}
          text={
            <FormattedMessage
              id="b23796e7-0410-4c17-bd77-9bfb4e3b8d33"
              defaultMessage="What are acceptable forms of ID?"
            />
          }
        />
      )}

      {showIdTypesModal && (
        <AcceptableFormsOfId
          onClose={() => {
            setShowIdTypesModal(false);
            setTimeout(() => {
              // give time for FocusScope to try and restore focus before manually focusing
              if (buttonRef.current !== document.activeElement) {
                buttonRef.current?.focus();
              }
            }, 50);
          }}
          photoIdentificationRequirements={photoIdentificationRequirements}
          individualRetake={individualRetake}
          isPs1583Bundle={isPs1583Bundle}
        />
      )}

      {onContinue && (
        <Footer
          nextStepButton={
            <NextStepButton
              automationId="id-verification-intro-continue"
              text={
                <FormattedMessage
                  id="bcd24bf0-03b8-49f0-a7df-4ecc4d0096dd"
                  defaultMessage="Accept and continue"
                />
              }
              onClick={onContinue}
            />
          }
          hint={<BiometricConsent />}
        />
      )}
    </div>
  );
}
