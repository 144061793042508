import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useEffect, useRef, useState } from "react";

import { useFeatureFlag } from "common/feature_gating";
import { Footer, MainHeading, NextStepButton, PreviousStepButton } from "common/signer/common";
import { Paragraph } from "common/core/typography";
import { useForm } from "common/core/form";
import { useQuery } from "util/graphql";
import { useId } from "util/html";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";
import SROnly from "common/core/screen_reader";
import { PhotoType } from "common/identity/document_viewer/engine";

import type { IDVData } from "..";
import { getPhotoIdentificationRequirements } from "../util";
import Styles from "./index.module.scss";
import { IDSelectionForm } from "./form";
import BiometricConsentModal from "../biometric_consent_modal";
import PhotoIdentificationByCountry from "./photo_identification_by_country.graphql";
import type { BiometricConsentInfoTransaction } from "../biometric_consent_info_transaction.fragment.graphql";

export const messages = defineMessages({
  selectCountry: {
    id: "45ebb51f-cad2-4a07-88e3-7a007ea1797d",
    defaultMessage: "Select country",
  },
  unsupportedCountry: {
    id: "1205aaa3-bdae-4476-bbce-fa00463c44df",
    defaultMessage: "Country not supported. Please select another.",
  },
  selectId: {
    id: "dc9832ac-fddf-4736-90ab-4056dd7705e1",
    defaultMessage: "Select ID",
  },
});

export type IDSelectionFormValues = {
  primaryCountry: string;
  primaryIdType: string;
  supplementalCountry: string | null;
  supplementalIdType: string | null;
};

export type Props = {
  onContinue: (data: IDVData) => void;
  onBack: () => void;
  onLoadingEvent?: (loading: boolean) => void;
  signerIdentityId: string;
  individualRetake?: PhotoType | null;
  formId?: string;
  externalSecondaryIdConstraint?: "required" | "optional";
  loading: boolean;
  size: "mobile" | "desktop";
  transaction?: BiometricConsentInfoTransaction;
  isPs1583Bundle: boolean;
  isIal2Proof: boolean;
};

export function IDSelection({
  onContinue,
  onBack,
  onLoadingEvent,
  signerIdentityId,
  individualRetake = null,
  formId: formIdControlled,
  externalSecondaryIdConstraint,
  loading,
  size,
  transaction,
  isPs1583Bundle,
  isIal2Proof,
}: Props) {
  const idvForCaStep = useFeatureFlag("idv-for-ca-step", true);
  const ial2OptionalSecondaryId = useFeatureFlag("ial2-optional-secondary-id");

  const intl = useIntl();
  const formId = useId();
  const formRef = useRef<HTMLFormElement>(null);
  const [showBiometricConsentModal, setShowBiometricConsentModal] = useState(false);

  // This is a ref instead of state because it doesn't affect the UI and when its value changes, the
  // new value needs to be available immediately in the form event handler.
  const gaveBiometricConsentRef = useRef(false);

  useA11y().useDocumentEntitler({
    title: intl.formatMessage(useDocumentTitles().preMeetingCredentialAnalysisPhotoIdTypeSelection),
  });

  const form = useForm<IDSelectionFormValues>({
    defaultValues: {
      supplementalIdType: null,
    },
  });
  const { watch, handleSubmit, resetField } = form;

  const primaryCountryCode = watch("primaryCountry", "US");
  const supplementalCountryCode = watch("supplementalCountry", primaryCountryCode);
  const primaryIdTypeValue = watch("primaryIdType");
  const supplementalIdTypeValue = watch("supplementalIdType");

  const {
    data,
    previousData,
    loading: queryLoading,
  } = useQuery(PhotoIdentificationByCountry, {
    fetchPolicy: "no-cache",
    variables: {
      signerIdentityId,
      primaryCountryCode,
      secondaryCountryCode: supplementalCountryCode,
      filterOutVerifiedId: individualRetake !== null,
    },
    skip: !primaryCountryCode && !supplementalCountryCode,
  });

  // [RX-621] This is a temporary workaround to consider secondary ID as optional for IAL2 Proof transactions
  const optionalSecondaryIDOverride =
    ial2OptionalSecondaryId &&
    isIal2Proof &&
    primaryIdTypeValue === "Driver's license" &&
    primaryCountryCode === "US" &&
    externalSecondaryIdConstraint !== "required";

  const countryDataReady =
    !queryLoading && Boolean(primaryCountryCode) && Boolean(supplementalCountryCode);

  const photoIdentificationRequirements = getPhotoIdentificationRequirements({
    signerSteps: countryDataReady ? data?.steps : previousData?.steps,
    externalSecondaryIdConstraint:
      individualRetake === PhotoType.Secondary
        ? "required"
        : optionalSecondaryIDOverride
          ? "optional"
          : externalSecondaryIdConstraint,
  });

  const primarySatisfied = Boolean(
    (individualRetake && individualRetake !== PhotoType.Primary) ||
      (primaryCountryCode && primaryIdTypeValue),
  );
  const secondarySatisfied = Boolean(
    (individualRetake && individualRetake !== PhotoType.Secondary) ||
      photoIdentificationRequirements?.supplementalIdConstraint !== "required" ||
      (supplementalCountryCode && supplementalIdTypeValue) ||
      optionalSecondaryIDOverride,
  );

  const disabled = queryLoading || !primarySatisfied || !secondarySatisfied;

  const onSubmit = ({
    primaryCountry,
    primaryIdType,
    supplementalCountry,
    supplementalIdType,
  }: IDSelectionFormValues) => {
    if (disabled) {
      return;
    }

    if (transaction && !gaveBiometricConsentRef.current) {
      setShowBiometricConsentModal(true);
      return;
    }

    const primaryId =
      photoIdentificationRequirements?.primaryIdOptions.find(
        (option) => option.description === primaryIdType,
      ) ?? null;

    const secondaryId = photoIdentificationRequirements?.supplementalIdOptions
      ? (photoIdentificationRequirements.supplementalIdOptions.find(
          (option) => option.description === supplementalIdType,
        ) ?? null)
      : null;

    onContinue(
      idvForCaStep
        ? {
            type: "service",
            primaryCountry,
            primaryDocumentType: primaryId?.idType ?? null,
            secondaryCountry: supplementalCountry,
            secondaryDocumentType: secondaryId?.idType ?? null,
          }
        : {
            type: "persona",
            country: primaryCountry,
            idClass: primaryId?.providerCode ?? null,
            idClassSecondary: secondaryId?.providerCode ?? null,
            idTypeSecondary: supplementalIdType,
          },
    );
  };

  useEffect(() => {
    resetField("supplementalCountry", { defaultValue: primaryCountryCode });
  }, [primaryCountryCode]);

  useEffect(() => {
    resetField("primaryIdType");
  }, [primaryCountryCode, size]);

  useEffect(() => {
    resetField("supplementalIdType");
  }, [supplementalCountryCode, size]);

  useEffect(() => {
    onLoadingEvent?.(queryLoading);
  }, [queryLoading]);

  return (
    <>
      {showBiometricConsentModal && transaction && (
        <BiometricConsentModal
          signerIdentityId={signerIdentityId}
          transaction={transaction}
          onAccept={() => {
            setShowBiometricConsentModal(false);
            gaveBiometricConsentRef.current = true;
            formRef.current?.requestSubmit();
          }}
          onClose={() => setShowBiometricConsentModal(false)}
        />
      )}
      <div className={Styles.container}>
        <MainHeading leftAlign>
          <FormattedMessage
            id="efaa54ff-32bc-41cb-8380-4297443d436c"
            defaultMessage="What type of ID do you have?"
          />
        </MainHeading>
        <Paragraph>
          <FormattedMessage
            id="63cb93bb-1c02-4ec8-b8ba-39f7cc781a05"
            defaultMessage="For security, we must capture {mode, select, ps1583 {a Photo ID and an Address ID} secondaryRetake {an ID} other {a government ID}} for verification."
            values={{
              mode: isPs1583Bundle
                ? "ps1583"
                : individualRetake === PhotoType.Secondary
                  ? "secondaryRetake"
                  : "default",
            }}
          />
        </Paragraph>

        <form id={formIdControlled || formId} ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <IDSelectionForm
            form={form}
            photoIdentificationRequirements={photoIdentificationRequirements}
            countryDataReady={countryDataReady}
            size={size}
            individualRetake={individualRetake}
            isPs1583Bundle={isPs1583Bundle}
          />
        </form>
      </div>

      {!formIdControlled && (
        <Footer
          nextStepButton={
            <NextStepButton
              automationId="id-type-continue"
              type="submit"
              form={formId}
              disabled={disabled}
              isLoading={loading}
              text={
                <FormattedMessage
                  id="f4bf1b76-cb79-403c-9fdb-75c30b893736"
                  defaultMessage="Continue"
                />
              }
            />
          }
          previousStepButton={<PreviousStepButton onClick={onBack} />}
        />
      )}
    </>
  );
}

export function SelectionDisabledInfo({ id }: { id: string }) {
  return (
    <SROnly>
      <div id={id}>
        <FormattedMessage
          id="f39d1b36-fd4e-4dcd-a72b-efe1bded4738"
          defaultMessage="When using multiple forms of identification, selections must be unique."
        />
      </div>
    </SROnly>
  );
}
