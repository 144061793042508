import { useParams, useLocation, Navigate } from "react-router-dom";

import { QueryWithLoading } from "util/graphql/query";
import TransactionEditQuery, {
  type TransactionEdit as TransactionEditType,
  type TransactionEdit_transaction_OrganizationTransaction as Transaction,
  type TransactionEdit_organization_Organization as Organization,
  type TransactionEdit_organization_Organization_activePricingPlan_prices as OrganizationPrices,
  type TransactionEdit_transaction_OrganizationTransaction_contacts as Contact,
} from "common/transactions/graphql/queries/edit_query.graphql";
import { transactionDetailsRoute, transactionEditRouteV3 } from "util/routes";
import { useActiveOrganization } from "common/account/active_organization";
import {
  OrganizationTransactionVariant,
  type AddressType,
  type SigningScheduleTypes,
  type SignerCapacityInputType,
} from "graphql_globals";
import { useGetConfigId } from "common/transaction_creation/v3/config";
import { TransactionErrorRedirect } from "common/transaction_creation/v3/form";

export type CustomerSignerFromForm = {
  id: string;
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  email: string | null;
  address: AddressType | null;
  phoneCountryCode: string | undefined;
  phoneNumber: string | undefined;
  signatoryTitle: string | null;
  capacities: SignerCapacityInputType[];
  ial2Proof: boolean;
  smsAuthProof: boolean;
  kbaProof: boolean;
  recipientGroup: {
    sharedInboxEmail: string | null;
  } | null;
};

export type WriteUpdateMutationArgs = {
  signingScheduleType: SigningScheduleTypes | null;
  additionalSealFee: OrganizationPrices["additionalSeal"];
  notarizationFee: OrganizationPrices["notaverseFirstSeal"];
  transactionFees: "custom" | "default";
  transactionName: Transaction["name"];
  transactionType: Transaction["transaction_type"];
  externalId: Transaction["externalId"];
  signerDetails: CustomerSignerFromForm[];
  organizationTransactionWitnesses: Transaction["organizationTransactionWitnesses"];
  secondaryId: Transaction["secondary_id_required"];
  subjectLine: Transaction["message_subject"];
  customerNote: Transaction["message"];
  emailSignature: Transaction["message_signature"];
  payer: Transaction["payer"];
  activationDate: Date | null;
  activationTimezone: string | null;
  expirationDate: Date | null;
  expirationTimezone: Transaction["expirationTimezone"];
  notaryMeetingDate: Date | null;
  notaryMeetingTime: Date | null;
  notaryMeetingTimezone: Transaction["notaryMeetingTimezone"];
  notarizeCloserOverride: Transaction["notarizeCloserOverride"];
  closerAssigneeId: string | null;
  personallyKnownToNotary: boolean;
  smsAuthenticationRequired: boolean;
  entityName: Transaction["entityName"];
  loanNumber: string | null;
  fileNumber: string | null;
  recallReason: string | null;
  pointsOfContact: Contact[];
  isTransactionForEntity: boolean;
};

// these fields aren't used for update mutation, but still used by components in the form
export type FormValues = WriteUpdateMutationArgs & {
  notaryNote: string;
  cosigner: boolean;
  recalled: boolean;
  hasPointsOfContact: boolean;
  isRecalled: boolean;
};

type Props = {
  data: TransactionEditType;
  variant: OrganizationTransactionVariant | null;
};

function V3Redirect(props: Props) {
  const { data } = props;
  const configId = useGetConfigId(props.variant, data.organization as Organization);

  return (
    <Navigate replace to={transactionEditRouteV3((data.transaction as Transaction).id, configId)} />
  );
}

function TransactionContainer() {
  const [activeOrganizationId] = useActiveOrganization();
  const { transactionId } = useParams();
  const { pathname } = useLocation();

  return (
    <QueryWithLoading
      query={TransactionEditQuery}
      variables={{
        transactionId: transactionId!,
        organizationId: activeOrganizationId!,
      }}
    >
      {({ data, error }) => {
        if (!data?.transaction) {
          return <TransactionErrorRedirect error={error} />;
        }

        const transaction = data.transaction as Transaction;

        if (!transaction.editable) {
          return <Navigate replace to={transactionDetailsRoute(transaction.id)} />;
        }

        function getVariant() {
          // For business transactions on creation, the transactionVariant on creation comes back as
          // NOTARIZATION for other variants until they're saved, so we have to check the path name
          // instead of variant and override it to ensure the right config is shown. This will need to be
          // in place until v2 is cleaned up.
          if (pathname.startsWith("/transaction/esign")) {
            return OrganizationTransactionVariant.ESIGN;
          }

          if (pathname.startsWith("/transaction/proof")) {
            return OrganizationTransactionVariant.PROOF;
          }

          if (pathname.startsWith("/transaction/identify")) {
            return OrganizationTransactionVariant.IDENTIFY;
          }

          if (pathname.startsWith("/transaction/verify")) {
            return OrganizationTransactionVariant.VERIFY;
          }

          if (transaction.transactionVariant === OrganizationTransactionVariant.CERTIFY) {
            return transaction.transactionVariant;
          }

          // OrganizationTransactionVariant.VERIFICATION_OF_FACT uses notarization form
          return OrganizationTransactionVariant.NOTARIZATION;
        }

        return <V3Redirect data={data} variant={getVariant()} />;
      }}
    </QueryWithLoading>
  );
}

export default TransactionContainer;
