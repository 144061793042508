import { memo, useState, useCallback, type ComponentProps } from "react";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import {
  isBlockingRequirementKey,
  type BlockingRequirement,
  type Requirement,
} from "common/meeting/notary/requirements";
import { useNotaryMeetingContext } from "common/meeting/notary/context";
import {
  MeetingParticipantRoles,
  type CertificateMigrationStatus,
  type NotaryCertificateProvider,
} from "graphql_globals";

import type {
  NotaryCompleteMeeting as Meeting,
  NotaryCompleteMeeting_documentBundle_documents_edges as DocumentEdge,
  NotaryCompleteMeeting_documentBundle_documents as Documents,
  NotaryCompleteMeeting_documentBundle_documents_edges,
} from "./complete_fragment.graphql";
import RequirementsModal from "./requirements_modal";
import ReasonsModal from "./reasons_modal";

type DocumentReqs = { document: DocumentEdge; requirements: Reqs }[];

type Reqs = {
  value: number;
  messageKey: ComponentProps<typeof Requirement>["messageKey"];
  documentId?: null | string;
}[];
type BlockingReqs = {
  value: number;
  messageKey: ComponentProps<typeof BlockingRequirement>["messageKey"];
}[];

type ModalProps = {
  onClose: () => void;
  onCompleteMeeting: (overrideReason: string | null) => Promise<unknown>;
  onPostCompleteMeeting: (meetingId: string) => void;
  onDocumentNavigateClick: (documentNode: { id: string }) => void;
  meeting: Meeting;
  numOfUnConfirmedInstructions: number;
};
type Props = Pick<
  ModalProps,
  | "onPostCompleteMeeting"
  | "onCompleteMeeting"
  | "meeting"
  | "numOfUnConfirmedInstructions"
  | "onDocumentNavigateClick"
> & {
  notaryUser: {
    notaryProfile: null | {
      certificateProvider: NotaryCertificateProvider;
      certificateMigrationStatus: CertificateMigrationStatus;
    };
  };
  disabled?: boolean;
};

function constructUiReqs(
  unfulfilledRequirements: Meeting["unfulfilledRequirements"],
  numOfUnConfirmedInstructions: number,
): Reqs {
  const {
    numOfUnverifiedSignerIdentities,
    numOfUnfulfilledEnoteDesignations,
    numOfUnacknowledgedSigners,
  } = unfulfilledRequirements;
  return (
    [
      {
        value: numOfUnfulfilledEnoteDesignations,
        messageKey: "enoteDesignation",
        documentId: null,
      },
      { value: numOfUnverifiedSignerIdentities, messageKey: "signerIdentities", documentId: null },
      { value: numOfUnConfirmedInstructions, messageKey: "instructions", documentId: null },
      {
        value: numOfUnacknowledgedSigners,
        messageKey: "witnessAcknowledgements",
        documentId: null,
      },
    ] as const
  ).filter((req) => req.value);
}

function constructUiDocumentReqs(
  unfulfilledRequirements: Meeting["unfulfilledRequirements"],
  documents: Documents,
): DocumentReqs {
  const { unfulfilledRequirements: requirementsArray } = unfulfilledRequirements;
  const docReqs = requirementsArray.flatMap((docRequirement) => {
    const requirements = (
      [
        {
          value: docRequirement!.num_of_unfulfilled_signatures as number,
          messageKey: "notarySignatures",
        },
        {
          value: docRequirement!.num_of_unfulfilled_designations as number,
          messageKey: "designation",
        },

        { value: docRequirement!.num_of_unfulfilled_seals as number, messageKey: "seals" },
        {
          value: docRequirement!.num_of_seals_without_signatures as number,
          messageKey: "sealsWithoutSignatures",
        },
        { value: docRequirement!.online_disclosure === false ? 1 : 0, messageKey: "disclosures" },
      ] as const
    ).filter((req) => req.value);
    return requirements.length
      ? [
          {
            requirements,
            document: documents.edges.find(
              (d) => d.node.id === docRequirement!.id,
            ) as NotaryCompleteMeeting_documentBundle_documents_edges,
          },
        ]
      : [];
  });
  return docReqs;
}

function constructUiBlockingReqs(
  unfulfilledRequirements: Meeting["unfulfilledRequirements"],
): BlockingReqs {
  const {
    enforceRequired,
    numOfUnfulfilledDesignations,
    numOfUnverifiedSignerIdentities,
    numOfMissingRequiredSealsForTransaction,
    missingParticipantRoles,
    numOfUnacknowledgedSigners,
  } = unfulfilledRequirements;
  return (
    [
      {
        value: enforceRequired && numOfUnfulfilledDesignations > 0 ? 1 : 0,
        messageKey: "enforceRequired",
      },
      { value: numOfUnverifiedSignerIdentities, messageKey: "signerIdentities" },
      { value: numOfMissingRequiredSealsForTransaction, messageKey: "requiredSeals" },
      {
        value: missingParticipantRoles.filter((role) => role === MeetingParticipantRoles.SIGNER)
          .length,
        messageKey: "missingSignersForVaultedTransaction",
        documentId: null,
      },
      { value: numOfUnacknowledgedSigners, messageKey: "witnessAcknowledgements" },
    ] as const
  ).filter((req) => req.value);
}

function CompleteMeetingModal({
  meeting,
  onClose,
  onCompleteMeeting,
  onPostCompleteMeeting,
  onDocumentNavigateClick,
  numOfUnConfirmedInstructions,
}: ModalProps) {
  const { id: meetingId, unfulfilledRequirements } = meeting;
  const { analytics, simulatedNotaryProfile } = useNotaryMeetingContext();
  const [isLoading, setIsLoading] = useState(false);
  const [modalStep, setModalStep] = useState<"reasons" | "requirements">("requirements");

  const requirements = constructUiReqs(unfulfilledRequirements, numOfUnConfirmedInstructions);
  const docSpecificRequirements = constructUiDocumentReqs(
    unfulfilledRequirements,
    meeting.documentBundle!.documents,
  );
  const blockingRequirements: BlockingReqs = constructUiBlockingReqs(
    unfulfilledRequirements,
  ).filter(({ messageKey }) => isBlockingRequirementKey(messageKey));
  const hasBlockingReqs = blockingRequirements.length > 0;
  const missingReq = Boolean(
    requirements.length || docSpecificRequirements.length || blockingRequirements.length,
  );

  const handleComplete = (overrideReason: string | null) => {
    setIsLoading(true);
    return (
      onCompleteMeeting(overrideReason)
        // Finally goes first to avoid a react warning when navigate unmounts this component.
        .finally(() => setIsLoading(false))
        .then(() => {
          if (!simulatedNotaryProfile) {
            analytics.onCompleteMeeting(missingReq);
          }
          onPostCompleteMeeting(meetingId);
        })
    );
  };

  const handleContinue = (overrideReason: string | null) => {
    if (missingReq) {
      setModalStep("reasons");
      analytics.onViewMissingRequirementsReasonsModal();
    } else {
      handleComplete(overrideReason);
    }
  };

  const handleBack = () => {
    setModalStep("requirements");
  };

  switch (modalStep) {
    case "requirements":
      return (
        <RequirementsModal
          missingReq={missingReq}
          onClose={onClose}
          onContinue={handleContinue}
          isLoading={isLoading}
          hasBlockingReqs={hasBlockingReqs}
          requirements={requirements}
          docSpecificRequirements={docSpecificRequirements}
          blockingRequirements={blockingRequirements}
          onDocumentNavigateClick={onDocumentNavigateClick}
          sequentiallySigning={meeting.sequentiallySigning}
        />
      );
    case "reasons":
      return (
        <ReasonsModal
          isLoading={isLoading}
          hasBlockingReqs={hasBlockingReqs}
          handleBack={handleBack}
          handleComplete={handleComplete}
          onClose={onClose}
        />
      );
  }
}

function CompleteButton({
  onCompleteMeeting,
  onPostCompleteMeeting,
  onDocumentNavigateClick,
  meeting,
  numOfUnConfirmedInstructions,
  disabled,
}: Props) {
  const { refetch, analytics } = useNotaryMeetingContext();
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const handleOpen = useCallback(() => {
    setLoading(true);
    return refetch()
      .then(() => {
        setModalOpen(true);
        analytics.onViewMissingRequirementsModal();
      })
      .finally(() => {
        setLoading(false);
      });
  }, [refetch]);
  const handleClose = useCallback(() => setModalOpen(false), []);
  return (
    <>
      <Button
        className="NotaryMeetingHeader--CompleteButton"
        onClick={handleOpen}
        automationId="complete-review-button"
        buttonColor="action"
        variant="primary"
        isLoading={loading}
        disabled={disabled}
      >
        <FormattedMessage
          id="922d8116-90d7-44d5-b896-602f44230e8d"
          description="Notary complete meeting button"
          defaultMessage="Complete"
        />
      </Button>
      {modalOpen && (
        <CompleteMeetingModal
          onClose={handleClose}
          meeting={meeting}
          onCompleteMeeting={onCompleteMeeting}
          onPostCompleteMeeting={onPostCompleteMeeting}
          onDocumentNavigateClick={onDocumentNavigateClick}
          numOfUnConfirmedInstructions={numOfUnConfirmedInstructions}
        />
      )}
    </>
  );
}

export default memo(CompleteButton);
