import { useState, useEffect, type ReactNode } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { isValidPhoneNumber } from "react-phone-number-input";

import { useForm } from "common/core/form";
import { Heading, Paragraph } from "common/core/typography";
import { MASTER_GENERAL_TERMS_URL, PROOF_MASTER_PRIVACY_POLICY_URL } from "constants/globals";
import { type MediaError, MediaErrorMessage } from "common/video_conference/exception";
import { WebcamPicker } from "common/tech_check/video/webcam";
import { MicrophonePicker, SpeakerPicker } from "common/tech_check/audio/picker";
import { PhoneNumberInput } from "common/core/form/phone-number";
import { useSelectedDevices } from "common/selected_devices_controller";
import { isMobileDevice, isiOSDevice } from "util/support";
import { CheckboxLabel, Checkbox } from "common/core/form/option";
import { useId } from "util/html";
import { ResponsiveWrapper, NextStepButton, Footer } from "common/signer/common";
import { captureException } from "util/exception";
import { useQuery, useMutation } from "util/graphql";
import AlertMessage from "common/core/alert_message";
import LoadingIndicator from "common/core/loading_indicator";
import { FullLogo } from "common/core/logo";
import { Hr } from "common/core/horizontal_rule";
import { TestSoundPlayer } from "common/tech_check/audio/test_sound_player";
import ActionButton from "common/core/action_button";
import MicrophoneVolume from "common/tech_check/audio/microphone_volume";
import { Select } from "common/core/form/select";

import JoinMeetingMutation from "./join_meeting_mutation.graphql";
import ViewerQuery from "./viewer_query.graphql";
import UnsupportedBrowserModal, { meetingIsUnsupported } from "./unsupported_browser";
import Styles from "./index.module.scss";

const PHONE = "Phone";
const COMPUTER = "Computer";

const MESSAGES = defineMessages({
  meetingOver: {
    id: "91c21035-8b0b-45a7-bc66-59728c614515",
    defaultMessage: "Cannot join meeting because it has already completed.",
  },
  joinError: {
    id: "4d065a83-a670-4e70-b14f-afe41bf6d168",
    defaultMessage: "Unable to join meeting.",
  },
  maxedCapacity: {
    id: "8be57b7f-326e-403e-bb9c-74b083e99c8f",
    defaultMessage: "Cannot join meeting because it contains too many participants.",
  },
  selectVideo: {
    id: "c30e7bf7-c7e1-4231-8309-c576377f0d22",
    defaultMessage: "Select a device for video",
  },
  selectAudio: {
    id: "c30e7bf7-c7e1-4231-8309-c576377f0d22",
    defaultMessage: "Select a device for audio",
  },
  microphone: {
    id: "451c8b50-abda-4354-bde1-09d2d72d3228",
    defaultMessage: "Select microphone",
  },
  input: {
    id: "a37017a7-6fe1-4496-bb2f-81e56e1f054d",
    defaultMessage: "Input",
  },
  confirmSettings: {
    id: "f041732a-fe9f-4de0-a49d-976958b0d51e",
    defaultMessage: "Confirm your device settings",
  },
  tos: {
    id: "3ee140dd-bd68-47d1-a026-a263beb6f828",
    defaultMessage: "Terms of Service",
  },
  privacyPolicy: {
    id: "733f49b3-6de4-4323-b94e-a35ba1e6c9ce",
    defaultMessage: "Privacy Policy",
  },
  testSound: {
    id: "1b4d957d-dd6c-4637-b969-756d01340777",
    defaultMessage: "Play test sound",
  },
  playing: {
    id: "b9189edf-3593-4c46-989b-78fc039faeb5",
    defaultMessage: "Playing test sound: Notarize",
  },
  selectSpeaker: {
    id: "29352c4e-2d83-46b2-af4c-733ebc2e3757",
    defaultMessage: "Select speakers",
  },
  joinMeeting: {
    id: "4c51700a-c0ec-44be-9097-7fb168313e58",
    defaultMessage: "Join meeting",
  },
  phone: {
    id: "4004d6e5-dc42-4082-9039-01d21e6ff315",
    defaultMessage: "Phone",
  },
  computer: {
    id: "b6dcfa06-f334-442c-b211-a93be73fd2ff",
    defaultMessage: "Computer",
  },
});

type Props = {
  children: Exclude<ReactNode, undefined>;
};

export default function AudioVideoSettings({ children }: Props) {
  const navigate = useNavigate();
  const intl = useIntl();
  const { meetingId } = useParams();
  const [avChecked, setAvChecked] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<null | ReactNode>(null);
  const { selectedDevices, onChangeDevices } = useSelectedDevices();
  const { microphone, webcam, speaker } = selectedDevices;
  const [webcamDeviceError, setWebcamDeviceError] = useState<null | MediaError>(null);
  const form = useForm({
    defaultValues: {
      phoneNumber: "",
      audioSource: selectedDevices.phone ? PHONE : COMPUTER,
      recordingConsent: false,
    },
  });
  const audioSource = form.watch("audioSource");
  const recordingConsent = form.watch("recordingConsent");
  const phoneNumber = form.watch("phoneNumber");
  const phoneSourceSelected = audioSource === PHONE;

  useEffect(() => {
    onChangeDevices({ phone: phoneNumber });
  }, [phoneNumber]);

  const disabled =
    !recordingConsent ||
    !microphone ||
    !speaker ||
    !webcam ||
    (phoneSourceSelected && (!selectedDevices.phone || !isValidPhoneNumber(phoneNumber)));

  const recordingConsentId = useId();
  const selectId = useId();
  const joinMeetingMutateFn = useMutation(JoinMeetingMutation);
  const { loading, data } = useQuery(ViewerQuery);

  if (loading || !data) {
    return <LoadingIndicator />;
  }

  if (meetingIsUnsupported()) {
    return <UnsupportedBrowserModal onClose={() => navigate("/")} />;
  }

  function joinMeeting() {
    joinMeetingMutateFn({
      variables: {
        input: {
          meetingId: meetingId!,
          userId: data!.viewer.user!.id,
          recordingConsent,
          phoneNumber: (phoneSourceSelected && phoneNumber) || null,
        },
      },
    })
      .then(() => {
        setAvChecked(true);
      })
      .catch((err: Error) => {
        if (err.message.includes("meeting_already_completed")) {
          setErrorMessage(intl.formatMessage(MESSAGES.meetingOver));
        } else if (err.message.includes("maximum_participant_capacity_reached")) {
          setErrorMessage(intl.formatMessage(MESSAGES.maxedCapacity));
        } else {
          captureException(err);
          setErrorMessage(intl.formatMessage(MESSAGES.joinError));
        }
      });
  }
  if (!avChecked) {
    return (
      <div className={Styles.audioVideoGate}>
        <div className={Styles.header}>
          <FullLogo className={Styles.logo} />
        </div>
        <ResponsiveWrapper>
          <div className={Styles.selectedDevicesContainer}>
            <Heading level="h1" textStyle="subtitle">
              {intl.formatMessage(MESSAGES.confirmSettings)}
            </Heading>
            <fieldset>
              <legend>{intl.formatMessage(MESSAGES.selectVideo)}</legend>
              <div className={Styles.options}>
                <WebcamPicker
                  onDeviceMissing={() => onChangeDevices({ webcam: "" })}
                  onDeviceError={setWebcamDeviceError}
                  onDeviceSelect={(webcam: string | null) => onChangeDevices({ webcam })}
                  selectedDeviceId={webcam}
                  defaultCameraDirection="front"
                />
              </div>
              {webcamDeviceError && <MediaErrorMessage error={webcamDeviceError} />}
            </fieldset>

            <Hr />

            <fieldset>
              <legend>{intl.formatMessage(MESSAGES.selectAudio)}</legend>
              {!isMobileDevice() && (
                <div className={Styles.sourceDropdown}>
                  <Select
                    id={selectId}
                    items={[
                      {
                        value: COMPUTER,
                        label: intl.formatMessage(MESSAGES.computer),
                      },
                      {
                        value: PHONE,
                        label: intl.formatMessage(MESSAGES.phone),
                      },
                    ]}
                    aria-invalid="false"
                    {...form.register("audioSource", { required: true })}
                  />
                </div>
              )}

              {phoneSourceSelected ? (
                <>
                  <Paragraph>
                    <FormattedMessage
                      id="c5a78226-237f-4afd-b9f4-90709b4b6eb4"
                      defaultMessage="Proof will call your phone and connect your audio to this meeting"
                    />
                  </Paragraph>
                  <div className={Styles.options}>
                    <PhoneNumberInput
                      control={form.control}
                      name="phoneNumber"
                      label={
                        <FormattedMessage
                          id="360111ad-082a-492b-82af-55db4e0de9ef"
                          defaultMessage="Phone number"
                        />
                      }
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className={Styles.picker}>
                    <div className={Styles.options}>
                      <MicrophonePicker
                        onDeviceMissing={() => onChangeDevices({ microphone: "" })}
                        onDeviceSelect={(microphone: string | null) =>
                          onChangeDevices({ microphone })
                        }
                        selectedDeviceId={microphone}
                      />
                    </div>
                    <div className={Styles.inputTest}>
                      {!isiOSDevice() && (
                        <>
                          <MicrophoneVolume microphoneId={microphone} />
                          <span aria-hidden="true">{intl.formatMessage(MESSAGES.input)}</span>
                        </>
                      )}
                    </div>
                  </div>
                  <div className={Styles.picker}>
                    <div className={Styles.options}>
                      <SpeakerPicker
                        onDeviceMissing={() => onChangeDevices({ speaker: "" })}
                        onDeviceSelect={(speaker: string | null) => onChangeDevices({ speaker })}
                        selectedDeviceId={speaker}
                        onDisable={() => onChangeDevices({ speaker: "" })}
                      />
                    </div>
                    <TestSoundPlayer speakerDeviceId={speaker}>
                      {(playSound, playing) => (
                        <ActionButton className={Styles.outputTest} onClick={playSound}>
                          {playing ? (
                            <>
                              <div className={Styles.speakerPlaying} />
                              {intl.formatMessage(MESSAGES.playing)}
                            </>
                          ) : (
                            <>
                              <div className={Styles.speaker} />
                              {intl.formatMessage(MESSAGES.testSound)}
                            </>
                          )}
                        </ActionButton>
                      )}
                    </TestSoundPlayer>
                  </div>
                </>
              )}
            </fieldset>

            {errorMessage && <AlertMessage>{errorMessage}</AlertMessage>}

            <div className={Styles.disclaimer}>
              <CheckboxLabel
                label={
                  <FormattedMessage
                    id="7ba54791-93cb-42e2-ad81-94460c149a20"
                    defaultMessage="As an account holder by clicking {joinMeeting} I consent to Proof creating a audio-video recording of me and that the {termOfService} and {privacyPolicy} that I have previously agreed to will apply to the recording and all actions I take on the Platform."
                    values={{
                      joinMeeting: <em>{intl.formatMessage(MESSAGES.joinMeeting)}</em>,
                      termOfService: (
                        <a
                          href={MASTER_GENERAL_TERMS_URL}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          {intl.formatMessage(MESSAGES.tos)}
                        </a>
                      ),
                      privacyPolicy: (
                        <a
                          href={PROOF_MASTER_PRIVACY_POLICY_URL}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          {intl.formatMessage(MESSAGES.privacyPolicy)}
                        </a>
                      ),
                    }}
                  />
                }
                checkbox={
                  <Checkbox
                    aria-invalid={!recordingConsent}
                    aria-describedby={recordingConsentId}
                    {...form.register("recordingConsent", { required: true })}
                    data-automation-id="recordingConsent"
                  />
                }
              />
            </div>
          </div>
          <Footer
            nextStepButton={
              <NextStepButton
                text={intl.formatMessage(MESSAGES.joinMeeting)}
                onClick={joinMeeting}
                disabled={disabled}
                automationId="join-meeting-button"
              />
            }
          />
        </ResponsiveWrapper>
      </div>
    );
  }
  return <>{children}</>;
}
