import { Children, type ReactNode, type ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import WhatsNewImage from "assets/images/get_started/megaphone.svg";
import WebinarsImage from "assets/images/get_started/product-training.svg";
import Svg from "common/core/svg";
import { WEBINARS_URL, BLOG_URL } from "constants/globals";
import { Heading } from "common/core/typography";
import { usePermissions } from "common/core/current_user_role";
import { useShowDocTemplates, useShowEasylinks } from "common/dashboard/util";
import {
  type Feature,
  MortgageTransactionType,
  OrganizationTransactionVariant,
} from "graphql_globals";
import { useFeatureFlag } from "common/feature_gating";
import { HIDE_CLOSE_FROM_NEW_TRANSACTION_DROPDOWN } from "constants/feature_gates";
import { useCreateBusinessTransaction } from "common/transactions/create/hooks/use-create-business-transaction";
import { ESIGN_TRANSACTION_TYPE } from "constants/transaction";
import {
  SIMPLE_NOTARIZATION_CONFIG,
  NOTARIZATION_CONFIG,
  SIGN_CONFIG,
  useSimpleTransactionForm,
} from "common/transaction_creation/v3/config";
import { type BusinessGetStarted_organization_Organization as Organization } from "common/get_started/business/business_get_started_query.graphql";

import { Tile, TileLink, WorkflowBadge } from "../tiles";
import Styles from "./index.module.scss";

type SectionTitleProps = {
  title?: ReactNode;
};

type SectionProps = SectionTitleProps & {
  children: ReactNode;
};

export function NSTSectionTitle({ title }: SectionTitleProps) {
  return (
    <Heading level="h3" textStyle="headingFour" className={Styles.nstTitle}>
      {title}
    </Heading>
  );
}

function SectionTitle({ title, pendoId }: SectionTitleProps & { pendoId?: string }) {
  return (
    <Heading data-pendo-id={pendoId} level="h3" className={Styles.title} textStyle="headingFive">
      {title}
    </Heading>
  );
}

export function NSTSection({ children, title }: SectionProps) {
  return (
    <div className={Styles.nstSection}>
      {title && <NSTSectionTitle title={title} />}
      <div className={Styles.nstGrid}>{children}</div>
    </div>
  );
}

export function Section({ children, title, pendoId }: SectionProps & { pendoId?: string }) {
  return (
    <section className={Styles.section}>
      {title && <SectionTitle pendoId={pendoId} title={title} />}
      <div className={Styles.sectionGrid}>{children}</div>
    </section>
  );
}

function isCompleteChild(child: ReactNode): boolean {
  return Boolean(!child || (child as ReactElement<{ complete?: boolean }>).props.complete);
}

export function TaskSection(props: SectionProps) {
  const completeChildren: boolean[] = Children.map(props.children, isCompleteChild) || [];
  return completeChildren.every(Boolean) ? null : <NSTSection {...props} />;
}

export function ResourcesSection() {
  return (
    <Section
      title={
        <FormattedMessage id="fe3283da-c8c7-4bce-9269-ec1a0229685f" defaultMessage="Resources" />
      }
    >
      <Tile
        alignLinkRight
        withFooterBorder
        title={
          <FormattedMessage id="9a8009bd-0184-49e6-88fc-0ab818f22f3c" defaultMessage="Webinars" />
        }
        description={
          <FormattedMessage
            id="629a2911-9a0f-463f-9f04-18017a9106d4"
            defaultMessage="Watch on-demand recordings and register for upcoming webinars hosted by Proof's product experts."
          />
        }
        image={<Svg src={WebinarsImage} alt="" />}
        link={
          <TileLink
            buttonSize="condensed"
            data-pendo-id="get-started-webinars"
            href={WEBINARS_URL}
            variant="tertiary"
          >
            <FormattedMessage
              id="02f95939-5a87-403d-9431-ba7c1b2a7e00"
              defaultMessage="On-demand recordings"
            />
          </TileLink>
        }
      />
      <Tile
        alignLinkRight
        withFooterBorder
        title={
          <FormattedMessage
            id="78b5424d-1ccf-4a45-a231-c1d1659e60f7"
            defaultMessage="What's new?"
          />
        }
        description={
          <FormattedMessage
            id="ebf62494-dcf9-4991-ad72-83a4daacbc72"
            defaultMessage="Stay up-to-date with industry news and product updates on our blog articles and release notes."
          />
        }
        image={<Svg src={WhatsNewImage} alt="" />}
        link={
          <TileLink
            buttonSize="condensed"
            data-pendo-id="get-started-blog"
            href={BLOG_URL}
            variant="tertiary"
          >
            <FormattedMessage
              id="908ed8d0-6953-4d34-849f-ca98951b64cb"
              defaultMessage="Visit the blog"
            />
          </TileLink>
        }
      />
    </Section>
  );
}

export function ToolsSection({ featureList }: { featureList: Feature[] }) {
  const { hasPermissionFor } = usePermissions();
  const showEasylinks = useShowEasylinks(featureList) && hasPermissionFor("createEasyLinks");
  const showTemplates = useShowDocTemplates(featureList);

  if (!showEasylinks && !showTemplates) {
    return null;
  }

  return (
    <Section
      title={<FormattedMessage id="5f5a1652-198b-4146-ac49-3ed47e25efbe" defaultMessage="Tools" />}
    >
      {showTemplates && (
        <div className={Styles.halfWidth}>
          <Tile
            alignLinkRight
            title={
              <FormattedMessage
                id="30f7982a-a546-4249-8c1a-a4b93cf1e9de"
                defaultMessage="Document templates"
              />
            }
            headingPendoId="get-started-doc-templates-heading"
            description={
              <FormattedMessage
                id="6d685a1d-4d6b-48b3-b6f5-65c8f51fa8e2"
                defaultMessage="Upload and tag your commonly-used documents once, then save them as a template for repeat use."
              />
            }
            link={
              <TileLink
                data-pendo-id="get-started-doc-templates"
                to="/templates-dashboard"
                variant="secondary"
              >
                <FormattedMessage
                  id="5524ace2-90f9-4e37-a300-c06d1c0dbc2f"
                  defaultMessage="Create a document template"
                />
              </TileLink>
            }
          />
        </div>
      )}
      {showEasylinks && (
        <div className={Styles.halfWidth}>
          <Tile
            alignLinkRight
            title={
              <FormattedMessage
                id="72389d71-c6b2-42c7-902c-2e75fd52d3da"
                defaultMessage="EasyLinks"
              />
            }
            headingPendoId="get-started-easylinks-heading"
            description={
              <FormattedMessage
                id="dafcb631-3a93-48be-b74d-2eb55b7f7d97"
                defaultMessage="EasyLinks are unique landing pages that let your customers sign documents from a template or upload their own."
              />
            }
            link={
              <TileLink data-pendo-id="get-started-easylinks" to="/easylinks" variant="secondary">
                <FormattedMessage
                  id="fd1a7015-9076-406f-b35f-b41d00d7050d"
                  defaultMessage="Create an EasyLink"
                />
              </TileLink>
            }
          />
        </div>
      )}
    </Section>
  );
}

function SignTile() {
  const { createTransaction, loading } = useCreateBusinessTransaction();

  return {
    key: "sign",
    badge: <WorkflowBadge />,
    title: (
      <FormattedMessage id="7e5e71ce-02ac-427c-bc73-72c4d7e68703" defaultMessage="Sign request" />
    ),
    description: (
      <FormattedMessage
        id="671d719d-774c-4a62-97bb-15557f7b75f7"
        defaultMessage="Send a digital signature request that does not require notarization."
      />
    ),
    link: (
      <TileLink
        data-pendo-id="get-started-send-esign-txn"
        isLoading={loading}
        variant="primary"
        onClick={() => {
          createTransaction({
            transactionType: ESIGN_TRANSACTION_TYPE,
            configId: SIGN_CONFIG.id,
          });
        }}
      >
        <FormattedMessage
          id="89369eb5-e6f1-4738-a3f2-84fb03f3fabe"
          defaultMessage="Send a Sign request"
        />
      </TileLink>
    ),
  };
}

function NotarizationTile(canCreateSimpleNotarization: boolean) {
  const { createTransaction, loading } = useCreateBusinessTransaction();

  return {
    key: "notarization",
    badge: <WorkflowBadge />,
    title: (
      <FormattedMessage
        id="f3cbf203-8857-4879-b4a8-6247a57f5d24"
        defaultMessage="Notarization request"
      />
    ),
    description: (
      <FormattedMessage
        id="388bebd9-1d93-445b-b674-ddf03dc14dd5"
        defaultMessage="Send a notarization request to one or more recipients."
      />
    ),
    link: (
      <TileLink
        data-pendo-id="get-started-send-notarization-txn"
        variant="primary"
        isLoading={loading}
        onClick={() => {
          createTransaction({
            configId: canCreateSimpleNotarization
              ? SIMPLE_NOTARIZATION_CONFIG.id
              : NOTARIZATION_CONFIG.id,
          });
        }}
      >
        <FormattedMessage
          id="30eb7744-e4be-46bb-80bb-e3182a97b592"
          defaultMessage="Send a notarization request"
        />
      </TileLink>
    ),
  };
}

const titlePathname = "/transaction/setup/v2?transaction_type=";
const titleLinkState = { state: { goBackToReferrer: true } };

const TITLE_WORKFLOWS = [
  // Cash closing tile
  {
    key: "cash",
    badge: <WorkflowBadge isClosing />,
    title: (
      <FormattedMessage id="ce0c3a11-dc3d-4c9f-bc2e-b2d8edc31e9e" defaultMessage="Cash closing" />
    ),
    description: (
      <FormattedMessage
        id="b28cc955-9e0f-461d-9199-3a968249249d"
        defaultMessage="Check eligibility and complete a cash closing fully online."
      />
    ),
    link: (
      <TileLink
        data-pendo-id="get-started-send-cash-closing"
        to={`${titlePathname}${MortgageTransactionType.purchase_buyer_cash}`}
        {...titleLinkState}
        variant="primary"
      >
        <FormattedMessage
          id="a5033b3c-634b-491c-9d10-921898a77396"
          defaultMessage="Send a cash closing"
        />
      </TileLink>
    ),
  },
  // Seller-side tile
  {
    key: "seller",
    badge: <WorkflowBadge isClosing />,
    title: (
      <FormattedMessage
        id="5ae98241-5ba0-4cda-ae46-73248a0471f6"
        defaultMessage="Seller-side closing"
      />
    ),
    description: (
      <FormattedMessage
        id="ac51f797-2c29-4210-8f36-557758a6b7f7"
        defaultMessage="Check eligibility and complete a seller-side closing fully online."
      />
    ),
    link: (
      <TileLink
        data-pendo-id="get-started-send-seller-side-closing"
        to={`${titlePathname}${MortgageTransactionType.purchase_seller}`}
        {...titleLinkState}
        variant="primary"
      >
        <FormattedMessage
          id="e8c52d58-8ffd-461f-8c95-dd12d014ef97"
          defaultMessage="Send a seller-side closing"
        />
      </TileLink>
    ),
  },
  // HELOC tile
  {
    key: "heloc",
    badge: <WorkflowBadge isClosing />,
    title: <FormattedMessage id="b809cc58-12f0-4e94-8156-3b886dcae1e9" defaultMessage="HELOC" />,
    description: (
      <FormattedMessage
        id="eeff2bd2-3cff-4315-8e4c-ffe3805ba7be"
        defaultMessage="Check eligibility and complete a HELOC fully online."
      />
    ),
    link: (
      <TileLink
        data-pendo-id="get-started-send-HELOC"
        to={`${titlePathname}${MortgageTransactionType.heloc}`}
        {...titleLinkState}
        variant="primary"
      >
        <FormattedMessage id="76f41687-fc0a-4f53-a04e-019d591d7f1a" defaultMessage="Send a HELOC" />
      </TileLink>
    ),
  },
];

function WorkflowsSection({
  workflows,
}: {
  workflows: {
    key: string;
    badge: ReactNode;
    title: ReactNode;
    description: ReactNode;
    link: ReactNode;
  }[];
}) {
  return (
    <Section
      pendoId="get-started-choose-workflow-heading"
      title={
        <FormattedMessage
          id="1c8cf234-9ed6-4276-bb33-ff5e1a42cf2d"
          defaultMessage="Choose a workflow to send a transaction"
        />
      }
    >
      {workflows.map((tile) => (
        <Tile
          key={tile.key}
          workflowBadge={tile.badge}
          title={tile.title}
          description={tile.description}
          link={tile.link}
        />
      ))}
    </Section>
  );
}

export function BusinessWorkflows({ organization }: { organization: Organization }) {
  const canCreateSimpleNotarization = useSimpleTransactionForm(
    OrganizationTransactionVariant.NOTARIZATION,
    organization,
  );

  const workflows = [NotarizationTile(canCreateSimpleNotarization), SignTile()];

  return <WorkflowsSection workflows={workflows} />;
}

export function TitleWorkflows() {
  const showClosingOption = !useFeatureFlag(HIDE_CLOSE_FROM_NEW_TRANSACTION_DROPDOWN);
  return showClosingOption ? <WorkflowsSection workflows={TITLE_WORKFLOWS} /> : null;
}
