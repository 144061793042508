import { useCallback, useEffect, useRef, useState } from "react";

import { PhotoIdVerificationStatus, StepType } from "graphql_globals";
import { isGraphQLError, useLazyQuery } from "util/graphql/query";
import { useMutation } from "util/graphql";
import { captureException } from "util/exception";

import PollPhotoIdentificationQuery, {
  type PollPhotoIdentificationResult,
  type PollPhotoIdentificationResult_steps_CredentialAnalysisStep as PollPhotoCredentialAnalysisStep,
  type PollPhotoIdentificationResult_steps_CredentialAnalysisStep_identityVerificationResult_failureReasons as FailureReasons,
} from "./poll_photo_identification_result.query.graphql";
import RetrieveIdvResult from "./retrieve_idv_result.mutation.graphql";

// This is a special type used to resolve the discrepancies between the old
// CreatePersonaPhotoIdentification mutation and the new
// PollPhotoIdentificationResult query
// 1) `photoIdentificationId` is nullable in the new PollPhotoIdentificationResult
// 2) `result` is a generic string in old CreatePersonaPhotoIdentification mutation
// TODO: clean this up and use the graph types when we remove the old mechanism
export type IdvResult = {
  photoIdentificationId: string | null;
  failureReasons: FailureReasons[] | null;
  result: string | PhotoIdVerificationStatus;
};

function getIdvResult(data?: PollPhotoIdentificationResult): IdvResult | null {
  if (!data) {
    return null;
  }
  const steps = data.steps;
  const caStep = steps.find((step) => step.stepType === StepType.CREDENTIAL_ANALYSIS) as
    | PollPhotoCredentialAnalysisStep
    | undefined;
  if (!caStep) {
    return null;
  }
  return caStep.identityVerificationResult;
}

export type Options = {
  currentSignerIdentityId: string;
  onComplete: (result: IdvResult) => void;
};

export default function usePollIdentityVerificationResult({
  currentSignerIdentityId,
  onComplete,
}: Options) {
  const completeCalled = useRef(false);
  const [polling, setPolling] = useState(false);
  const [retrievingIdvResult, setRetrievingIdvResult] = useState(false);
  const [startQuery, { data, stopPolling }] = useLazyQuery(PollPhotoIdentificationQuery, {
    variables: { signerIdentityIds: [currentSignerIdentityId] },
    pollInterval: 2_000,
    fetchPolicy: "no-cache",
  });
  const retrieveIdvResult = useMutation(RetrieveIdvResult);

  const idvResult = getIdvResult(data);
  const resultStatus = idvResult?.result;
  const completedIdv = resultStatus && resultStatus !== PhotoIdVerificationStatus.PENDING;

  useEffect(() => {
    // eslint-disable-next-line no-console -- log for sumo
    console.log("[PollIdentityVerificationResult] data changed", data);
  }, [data]);

  useEffect(() => {
    // eslint-disable-next-line no-console -- log for sumo
    console.log("[PollIdentityVerificationResult] resultStatus changed", resultStatus);
  }, [resultStatus]);

  useEffect(() => {
    if (completedIdv && !completeCalled.current) {
      // eslint-disable-next-line no-console -- log for sumo
      console.log("[PollIdentityVerificationResult] IDV completed, calling onComplete");
      setPolling(false);
      stopPolling();
      completeCalled.current = true;
      onComplete(idvResult);
    }
  }, [completedIdv, onComplete]);

  // Before we begin polling, we need to call `RetrieveIdvResultForCredentialAnalysis mutation to handle
  // clients such as e2e test runners that won't receive Persona webhook information to start an inquiry
  const startPollingIdvResult = useCallback(async () => {
    setRetrievingIdvResult(true);
    try {
      await retrieveIdvResult({
        variables: { input: { signerIdentityId: currentSignerIdentityId } },
      });
      setPolling(true);
      return startQuery();
    } catch (error) {
      captureException(
        new Error(
          isGraphQLError(error)
            ? error.graphQLErrors[0].message
            : "An unknown issue occurred retrieving IDV results",
        ),
      );
    } finally {
      setRetrievingIdvResult(false);
    }
  }, [currentSignerIdentityId]);

  return {
    startPollingIdvResult,
    loading: polling || retrievingIdvResult,
  };
}
