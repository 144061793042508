import { FormattedMessage } from "react-intl";
import type { Ref } from "react";

import { Hint, InfoList, InfoListItem, SubHeading } from "common/signer/common";

import type { CommonContentProps } from "..";

export function Body({
  selfieRequired,
  requiresIdPhotos,
  showAvailableIdOptions,
  onIDTypesHintClick,
  buttonRef,
}: CommonContentProps & {
  showAvailableIdOptions: boolean;
  onIDTypesHintClick: () => void;
  buttonRef: Ref<HTMLButtonElement>;
}) {
  return (
    <>
      {!requiresIdPhotos || selfieRequired ? (
        <>
          <SubHeading>
            <FormattedMessage
              id="fdc78931-6a49-4231-a486-b4d2c0bfe042"
              defaultMessage="Tips for accurate results"
            />
          </SubHeading>
          <InfoList>
            <InfoListItem>
              <FormattedMessage
                id="55d05f40-d509-45a0-a35f-508644638931"
                defaultMessage="Maintain adequate lighting"
              />
            </InfoListItem>
            {!requiresIdPhotos ? (
              <>
                <InfoListItem>
                  <FormattedMessage
                    id="2fa7923b-b7b3-46d7-97d1-88e97e2ca027"
                    defaultMessage="Ensure your face is straight on, not angled"
                  />
                </InfoListItem>
                <InfoListItem>
                  <FormattedMessage
                    id="c4266a83-2585-4264-af06-bb9207616f38"
                    defaultMessage="Make sure your photo is clear and in focus"
                  />
                </InfoListItem>
              </>
            ) : (
              selfieRequired && (
                <>
                  <InfoListItem>
                    <FormattedMessage
                      id="408d93ed-4817-4264-9e45-c938d010156a"
                      defaultMessage="Take photos head on, not at an angle"
                    />
                  </InfoListItem>
                  <InfoListItem>
                    <FormattedMessage
                      id="a30bc777-640a-4f3c-a3e0-7c781987ee49"
                      defaultMessage="Avoid blurry or out of focus photos"
                    />
                  </InfoListItem>
                </>
              )
            )}
          </InfoList>
        </>
      ) : (
        <>
          <SubHeading>
            <FormattedMessage
              id="a738eed5-c05e-4e7f-b444-ebc32af598f5"
              defaultMessage="What you'll need"
            />
          </SubHeading>
          <InfoList>
            <InfoListItem>
              <FormattedMessage
                id="a5cabf9c-1eca-4e28-b2fb-a0a03d6fcba4"
                defaultMessage="A valid government-issued photo ID"
              />
            </InfoListItem>
            <InfoListItem>
              <FormattedMessage
                id="7884764a-2c9e-4392-8081-9c9a18742d6a"
                defaultMessage="Good lighting conditions"
              />
            </InfoListItem>
            <InfoListItem>
              <FormattedMessage
                id="7be9ed24-87b2-437a-8ceb-7b4175700090"
                defaultMessage="Clear, unobstructed view of your ID"
              />
            </InfoListItem>
            <InfoListItem>
              <FormattedMessage
                id="be4a45d4-ef2a-4d38-bf1c-8fa0087bf56a"
                defaultMessage="All four corners visible in the photo"
              />
            </InfoListItem>
          </InfoList>
        </>
      )}

      {showAvailableIdOptions && requiresIdPhotos && (
        <Hint
          buttonRef={buttonRef}
          onClick={onIDTypesHintClick}
          text={
            <FormattedMessage
              id="b23796e7-0410-4c17-bd77-9bfb4e3b8d33"
              defaultMessage="What are acceptable forms of ID?"
            />
          }
        />
      )}
    </>
  );
}
