import { memo } from "react";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import ModalGraphic from "common/blocking_modal/graphic";
import ModalHeader from "common/blocking_modal/header";
import ModalSubheader from "common/blocking_modal/subheader";
import ModalActions from "common/blocking_modal/actions";
import BlockingModal from "common/blocking_modal";
import errorImg from "assets/images/destructive-action.svg";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const CancelTransactionErrorModal = memo(({ isOpen, onClose }: Props) => {
  return (
    <BlockingModal isOpen={isOpen}>
      <ModalGraphic>
        <img alt="error" src={errorImg} />
      </ModalGraphic>

      <ModalHeader>
        <FormattedMessage
          id="9081e6b8-c530-439a-8d63-b789bb935f06"
          defaultMessage={"Transaction cannot be cancelled."}
        />
      </ModalHeader>

      <ModalSubheader>
        <FormattedMessage
          id="96341097-d5ad-4ab6-8592-45657635dc7c"
          defaultMessage={
            "Transactions that are partially complete or have a meeting in progress cannot be cancelled."
          }
        />
      </ModalSubheader>

      <ModalActions>
        <Button onClick={onClose} buttonColor="action" variant="secondary">
          <FormattedMessage
            id="b6a23253-9753-4c3e-a0af-bd71bae128b1"
            defaultMessage={"Return to transaction"}
          />
        </Button>
      </ModalActions>
    </BlockingModal>
  );
});

export { CancelTransactionErrorModal };
