import "./witness_party.scss";

import { memo, useState } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";

import { MeetingEndedState, MeetingParticipantRoles } from "graphql_globals";
import { VideoCondenseAction } from "common/meeting/sidebar/party/actions";
import { MeetingSidebarParty, MeetingSidebarPartyInfo } from "common/meeting/sidebar/party";
import UserFullName from "common/user/user_full_name";
import { StandardTrack } from "common/meeting/sidebar/party/track";
import type { RemoteParty } from "common/video_conference";
import PopoutMenuItem from "common/core/popout_menu/item";

import AlertAVIssueAction from "./alert_av_issue_action";
import type {
  NotaryMeetingWitnessParty as Meeting,
  NotaryMeetingWitnessParty_meetingParticipants_WitnessParticipant as WitnessParticipant,
  NotaryMeetingWitnessParty_meetingParticipants_IdentityVerifiedWitnessParticipant as IdentityVerifiedWitnessParticipant,
} from "./witness_party_fragment.graphql";
import { RemoveParticipantModal } from "./remove_participant_modal";

type Props = {
  meeting: Meeting;
  party: RemoteParty<WitnessParticipant | IdentityVerifiedWitnessParticipant>;
  onSetPenholder: (participantId: string) => void;
};

const MESSAGES = defineMessages({
  popoutButtonLabel: {
    id: "f277348a-ca65-4f19-a7d2-f859083bab6f",
    defaultMessage: "Open witness party controls",
  },
});

function WitnessParty({ party, meeting, onSetPenholder }: Props) {
  const { participants, track } = party;
  const intl = useIntl();
  const networkQuality = party.useNetworkQuality();
  const [witness] = participants;
  const [removeParticipantModalOpen, setRemoveParticipantModalOpen] = useState(false);
  const toggleRemoveParicipantModal = () => setRemoveParticipantModalOpen((v) => !v);
  const witnessBundleIndex =
    meeting.meetingParticipants.filter((p) => p.role === MeetingParticipantRoles.WITNESS).length > 1
      ? witness.signerRole.index
      : undefined;

  return (
    <>
      <MeetingSidebarParty
        track={(trackProps) => (
          <StandardTrack
            isCompleted={meeting.endedState !== MeetingEndedState.NOT_COMPLETED}
            track={track}
            networkQuality={networkQuality}
            trackButtonAriaLabel={intl.formatMessage(MESSAGES.popoutButtonLabel)}
            witnessBundleIndex={witnessBundleIndex}
            popoutMenuProps={{
              children: () => (
                <>
                  <AlertAVIssueAction meeting={meeting} activeParticipantId={witness.id} />
                  <VideoCondenseAction
                    videoCondensed={trackProps.videoCondensed}
                    toggleVideoCondensed={trackProps.toggleVideoCondensed}
                  />
                  {witness.isOnDemand && !removeParticipantModalOpen && (
                    <PopoutMenuItem
                      onClick={() => {
                        toggleRemoveParicipantModal();
                        close();
                      }}
                      iconName="x-mark-circle"
                    >
                      <FormattedMessage
                        id="a3402806-519c-417b-b1bf-5ad3f2f73ef0"
                        defaultMessage="Remove Witness"
                      />
                    </PopoutMenuItem>
                  )}
                </>
              ),
            }}
          />
        )}
        isActive={witness.isCurrentPenHolder}
        onActivate={!witness.isCurrentPenHolder ? () => onSetPenholder(witness.id) : undefined}
      >
        <MeetingSidebarPartyInfo
          header={<UserFullName user={witness} wrap />}
          headerSubtext={
            witness.isCurrentPenHolder ? (
              <FormattedMessage
                id="385c3c54-f40a-4f33-a30d-95bf587a6445"
                defaultMessage="Active Witness"
              />
            ) : (
              <FormattedMessage
                id="55e81de6-96de-4d3d-b9a3-76fd1daa3782"
                defaultMessage="Witness"
              />
            )
          }
        />
      </MeetingSidebarParty>
      {removeParticipantModalOpen && (
        <RemoveParticipantModal
          onClose={toggleRemoveParicipantModal}
          meeting={meeting}
          participantId={witness.id}
        />
      )}
    </>
  );
}

export default memo(WitnessParty);
