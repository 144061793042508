import { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";

import LoadingIndicator from "common/core/loading_indicator";
import {
  useCreateBusinessTransaction,
  getTransactionTypeFromParam,
} from "common/transactions/create/hooks/use-create-business-transaction";

function NewTransaction() {
  const [searchParams] = useSearchParams();
  const hasCreatedTransaction = useRef(false);
  const transactionType = getTransactionTypeFromParam(searchParams.get("type"));

  const { createTransaction, loading } = useCreateBusinessTransaction({ getErrorRoute: () => "/" });

  useEffect(() => {
    if (!loading && !hasCreatedTransaction.current) {
      createTransaction({ transactionType });
      hasCreatedTransaction.current = true;
    }
  }, [loading, createTransaction, transactionType]);

  return <LoadingIndicator />;
}

export default NewTransaction;
