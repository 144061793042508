import { useCallback, useEffect } from "react";
import classnames from "classnames";

import LoadingIndicator from "common/core/loading_indicator";
import WorkflowModal from "common/modals/workflow_modal";
import {
  useSocureClient,
  SocureErrorEventStatus,
  type SocureErrorEvent,
} from "common/identity_verification/clients/socure";
import type { IdvResult } from "common/identity_verification/service";
import usePollIdentityVerificationResult from "common/identity_verification//service/poll_identify_verification_result";

import type { IDVInteraction } from "..";
import Styles from "./index.module.scss";

type Props = {
  onClose: () => void;
  onComplete: (resp: IdvResult) => void;
  idvInteraction: IDVInteraction;
  signerIdentityId: string;
};

export function Socure({ idvInteraction, onClose, onComplete, signerIdentityId }: Props) {
  if (idvInteraction.input?.source !== "service") {
    throw new Error("Expected IDV service result to initialize Socure");
  }

  if (!idvInteraction.input.vendorSdkKey) {
    throw new Error("Missing Socure SDK key");
  }

  const handleSocureError = useCallback(
    (event: SocureErrorEvent) => {
      if (event.status === SocureErrorEventStatus.CONSENT_DECLINED) {
        onClose();
      }
    },
    [onClose],
  );

  const { startPollingIdvResult, loading } = usePollIdentityVerificationResult({
    currentSignerIdentityId: signerIdentityId,
    onComplete,
  });

  const { open, initializing } = useSocureClient({
    docVTransactionToken: idvInteraction.input.inquiryId,
    onSuccess: startPollingIdvResult,
    onError: handleSocureError,
    sdkElement: "#socure-modal",
    sdkApiKey: idvInteraction.input.vendorSdkKey,
  });

  useEffect(() => {
    open();
  }, [open]);

  const showUI = !loading && !initializing;

  return (
    <WorkflowModal closeBehavior={{ tag: "with-button", onClose }} autoFocus isSensitive>
      <div className={Styles.sdkContainer}>
        {!showUI && <LoadingIndicator />}
        <div id="socure-modal" className={classnames(!showUI && Styles.hidden)} />
      </div>
    </WorkflowModal>
  );
}
