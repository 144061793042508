import { FormattedMessage } from "react-intl";
import { Navigate, Route, Routes } from "react-router-dom";
import { useMemo } from "react";

import Referrals from "common/dashboard/referrals";
import { PermissionRedirect } from "common/core/current_user_role";
import { EasylinkManager } from "common/dashboard/easy_link";
import { OrganizationTemplates } from "common/organization_templates";
import OrganizationTemplateEdit from "common/organization_templates/edit";
import OrganizationTemplatePrepare from "common/organization_templates/prepare";
import OrganizationTemplateTools from "common/organization_templates/tools";
import { SidebarV2 } from "common/sidebar";
import { EasylinkTypes } from "graphql_globals";

import type { Tools_node_Organization as Organization } from "./index_query.graphql";
import { ToolsSidebarTemplatesLink, ROUTE as TEMPLATES_ROUTE } from "./document_templates";
import { ToolsSidebarEasylinksLink, ROUTE as EASYLINKS_ROUTE } from "./easylinks";
import { ToolsSidebarReferralsLink, ROUTE as REFERRALS_ROUTE } from "./referral_campaigns";
import { useShowTools } from "./util";

export function ToolsSidebar({ organization }: { organization: Organization }) {
  const {
    featureList,
    easylinks: { totalCount: easylinksCount },
    templates: { totalCount: templatesCount },
    referralCampaigns: { totalCount: referralCampaignsCount },
    organizationType,
  } = organization;

  const { showTemplates, showEasyLinks, showReferrals } = useShowTools(
    featureList,
    organizationType,
  );

  const defaultRoute = useMemo(() => {
    if (showTemplates) {
      return TEMPLATES_ROUTE;
    }
    if (showEasyLinks) {
      return EASYLINKS_ROUTE;
    }
    return REFERRALS_ROUTE;
  }, []);

  return (
    <>
      <SidebarV2
        title={
          <FormattedMessage id="07d245dc-5197-4bf8-bd1d-748d45cbcf5d" defaultMessage="Tools" />
        }
      >
        {showTemplates && <ToolsSidebarTemplatesLink count={templatesCount} />}
        {showEasyLinks && <ToolsSidebarEasylinksLink count={easylinksCount} />}
        {showReferrals && <ToolsSidebarReferralsLink count={referralCampaignsCount} />}
      </SidebarV2>
      <Routes>
        <Route index element={<Navigate to={defaultRoute} replace />} />
        <Route path={TEMPLATES_ROUTE}>
          <Route path=":templateId/tools" element={<OrganizationTemplateTools />} />
          <Route path=":templateId/new" element={<OrganizationTemplateEdit />} />
          <Route path=":templateId/edit" element={<OrganizationTemplateEdit />} />
          <Route path=":globalID" element={<OrganizationTemplatePrepare />} />
          <Route index element={<OrganizationTemplates dashboardMode />} />
        </Route>
        <Route path={EASYLINKS_ROUTE}>
          <Route
            index
            element={
              <PermissionRedirect permissions={["viewEasyLinks"]}>
                <EasylinkManager
                  allowedEasylinkTypes={[
                    EasylinkTypes.IDENTIFY,
                    EasylinkTypes.DOCUMENTS_PROVIDED,
                    EasylinkTypes.SIGNER_UPLOAD,
                    EasylinkTypes.VERIFY,
                  ]}
                />
              </PermissionRedirect>
            }
          />
        </Route>
        <Route path={REFERRALS_ROUTE}>
          <Route
            index
            element={
              <PermissionRedirect permissions={["viewOrganizationTransactions"]}>
                <Referrals />
              </PermissionRedirect>
            }
          />
        </Route>
      </Routes>
    </>
  );
}
