import { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";

import { DeprecatedFormGroup as FormGroup } from "common/form/group";
import { DeprecatedFormGroupErrors as FormGroupErrors } from "common/form/group_errors";
import { DeprecatedTextField } from "common/form/fields/text";
import { DeprecatedMultipartColumn } from "common/form/inputs/multipart/column";
import { DeprecatedMultipartRow } from "common/form/inputs/multipart/row";
import { messages } from "common/meeting/notary/credential_analysis/messages";
import { PICTURE_ID_PENDING } from "constants/id_validation";

class SignerInfoFormFields extends Component {
  componentDidMount() {
    const { signerIdentity, initialize } = this.props;
    initialize(SignerInfoFormFields.initialFormValues(signerIdentity));
  }

  static initialFormValues(signerIdentity) {
    const { documentAddress, dob, firstName, middleName, lastName } = signerIdentity.photoId;

    return {
      dateOfBirth: dob,
      firstName,
      middleName,
      lastName,
      line1: documentAddress ? documentAddress.line1 : null,
      line2: documentAddress ? documentAddress.line2 : null,
      city: documentAddress ? documentAddress.city : null,
      state: documentAddress ? documentAddress.state : null,
      postal: documentAddress ? documentAddress.postal : null,
      country: documentAddress ? documentAddress.country : null,
    };
  }

  render() {
    const {
      intl,
      signerIdentity: {
        photoId: { status },
      },
    } = this.props;
    const isPending = status === PICTURE_ID_PENDING;

    return (
      <div className="MeetingCredentialAnalysis--inputField signerInfoForm">
        <FormGroup fields={["firstName", "middleName", "lastName"]}>
          <DeprecatedMultipartRow>
            <DeprecatedMultipartColumn width={5}>
              <DeprecatedTextField
                id="firstName"
                name="firstName"
                placeholder={intl.formatMessage(messages.first)}
                data-automation-id="first-name-field"
                placeholderAsLabel
                useStyledInput
                disabled={isPending}
                autoComplete="off"
              />
            </DeprecatedMultipartColumn>

            <DeprecatedMultipartColumn width={3}>
              <DeprecatedTextField
                id="middleName"
                name="middleName"
                placeholder={intl.formatMessage(messages.middle)}
                data-automation-id="middle-name-field"
                placeholderAsLabel
                useStyledInput
                disabled={isPending}
                autoComplete="off"
              />
            </DeprecatedMultipartColumn>

            <DeprecatedMultipartColumn width={5}>
              <DeprecatedTextField
                id="lastName"
                name="lastName"
                placeholder={intl.formatMessage(messages.last)}
                data-automation-id="last-name-field"
                placeholderAsLabel
                useStyledInput
                disabled={isPending}
                autoComplete="off"
              />
            </DeprecatedMultipartColumn>
          </DeprecatedMultipartRow>
          <FormGroupErrors fields={["firstName", "middleName", "lastName"]} />
        </FormGroup>
        <FormGroup fields={["dateOfBirth"]}>
          <DeprecatedTextField
            id="dateOfBirth"
            name="dateOfBirth"
            component="input"
            placeholder="DOB"
            placeholderAsLabel
            useStyledInput
            maxLength="10"
            data-automation-id="dateOfBirth-field"
            disabled={isPending}
            autoComplete="off"
          />
          <FormGroupErrors fields={["dateOfBirth"]} />
        </FormGroup>
        <FormGroup>
          <DeprecatedTextField
            id="line1"
            name="line1"
            placeholder="Address Line 1"
            useStyledInput
            placeholderAsLabel
            data-automation-id="line1-field"
            disabled={isPending}
            autoComplete="off"
          />
          <DeprecatedTextField
            id="line2"
            name="line2"
            placeholder="Address Line 2"
            useStyledInput
            placeholderAsLabel
            data-automation-id="line2-field"
            disabled={isPending}
            autoComplete="off"
          />
          <DeprecatedTextField
            id="city"
            name="city"
            placeholder="City"
            useStyledInput
            placeholderAsLabel
            data-automation-id="city-field"
            disabled={isPending}
            autoComplete="off"
          />
          <DeprecatedMultipartRow>
            <DeprecatedTextField
              id="state"
              name="state"
              placeholder="State"
              useStyledInput
              placeholderAsLabel
              data-automation-id="state-field"
              disabled={isPending}
              autoComplete="off"
            />
            <DeprecatedTextField
              id="postal"
              name="postal"
              placeholder="ZIP/Postal"
              useStyledInput
              placeholderAsLabel
              data-automation-id="postal-field"
              disabled={isPending}
              autoComplete="off"
            />
          </DeprecatedMultipartRow>
          <DeprecatedTextField
            id="country"
            name="country"
            placeholder="Country"
            useStyledInput
            placeholderAsLabel
            data-automation-id="country-field"
            disabled={isPending}
            autoComplete="off"
          />
          <FormGroupErrors fields={["line1", "city", "state", "postal"]} />
        </FormGroup>
      </div>
    );
  }
}

SignerInfoFormFields.propTypes = {
  initialize: PropTypes.func,
  intl: PropTypes.object.isRequired, // from injectIntl
  signerIdentity: PropTypes.shape({
    photoId: PropTypes.shape({
      status: PropTypes.string,
      dob: PropTypes.string,
      firstName: PropTypes.string,
      middleName: PropTypes.string,
      lastName: PropTypes.string,
      documentAddress: PropTypes.object,
      documentNumber: PropTypes.string,
    }),
  }).isRequired,
};

SignerInfoFormFields.defaultProps = {
  initialize: () => {},
};

export default injectIntl(SignerInfoFormFields);
