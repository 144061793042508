import { userFullName } from "./user";

type CustomerSigner = {
  id: string;
  userId: string | null;
  firstName: string | null;
  middleName?: string | null;
  lastName: string | null;
};

type SignerIdentityUser = {
  id: string;
  firstName: string | null;
  middleName?: string | null;
  lastName: string | null;
};

export function getCustomerSigner<C extends CustomerSigner>(
  customerSigners: C[] | undefined,
  userId: string,
) {
  if (!customerSigners) {
    return undefined;
  }
  return customerSigners.find((cs) => cs.userId === userId);
}

export function getCustomerSignerName(
  customerSigners: CustomerSigner[],
  siUser: SignerIdentityUser,
) {
  const customerSigner = getCustomerSigner(customerSigners, siUser.id);
  const currentSigner = customerSigner || siUser; // needed to support Credible Witness
  const useFullName = customerSigners.some(
    (cs) => siUser.id !== cs.userId && cs.firstName === customerSigner?.firstName,
  );
  return (
    (useFullName ? userFullName(currentSigner) : currentSigner.firstName) || siUser.firstName || ""
  );
}
