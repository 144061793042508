import { useCallback, useEffect, useMemo, useState } from "react";
import { v4 } from "uuid";
import { useIntl } from "react-intl";

import LoadingIndicator from "common/core/loading_indicator";
import {
  CredentialAnalysisRequirement,
  PhotoIdentification,
  PhotoIdVerificationFailureCategories,
} from "graphql_globals";
import {
  type Fields,
  Templates,
  usePersonaClient,
} from "common/identity_verification/clients/persona";
import { useMutation } from "util/graphql";
import { segmentTrack } from "util/segment";
import type { FailureReasons } from "common/identity_verification";
import type {
  IdentityVerification_bundle_DocumentBundle_organizationTransaction as OrganizationTransaction,
  IdentityVerification_signerIdentity_SignerIdentity as SignerIdentity,
  IdentityVerification_viewer_signerStepsV2_CredentialAnalysisStep as CredentialAnalysisStep,
} from "common/identity_verification/index.graphql";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";
import { getCustomerSigner } from "util/customer_signer";

import CreatePersonaPhotoIdentificationMutation, {
  type CreatePersonaPhotoIdentification_createPersonaPhotoIdentification as CreatePersonaPhotoIdentificationResult,
} from "./create_persona_photo_identification.mutation.graphql";
import usePollIdentityVerificationResult, {
  type IdvResult,
} from "../../service/poll_identify_verification_result";
import type { IDVConfiguration, IDVInput, IDVInteraction } from "..";

type Props = {
  caStep: CredentialAnalysisStep;
  signerIdentity: SignerIdentity;
  onComplete: (resp: IdvResult) => void;
  onClose: () => void;
  idvInteraction: IDVInteraction;
  organizationTransaction: OrganizationTransaction;
};

type TemplateInfo = {
  mobileTemplate: Templates;
  desktopTemplate: Templates | null;
};

function getTemplateInfo({
  requirement,
  configuration,
}: {
  requirement: CredentialAnalysisRequirement;
  configuration?: IDVConfiguration;
}): TemplateInfo {
  if (configuration?.requiresBiometrics) {
    return {
      desktopTemplate: Templates.BIOMETRIC_VERIFICATION_DESKTOP_ONLY,
      mobileTemplate: Templates.BIOMETRIC_VERIFICATION,
    };
  }
  switch (requirement) {
    case CredentialAnalysisRequirement.BIOMETRIC_PS1583:
    case CredentialAnalysisRequirement.BIOMETRIC_ONLY:
      return {
        desktopTemplate: Templates.BIOMETRIC_VERIFICATION_DESKTOP_ONLY,
        mobileTemplate: Templates.BIOMETRIC_VERIFICATION,
      };
    case CredentialAnalysisRequirement.BIOMETRIC:
      return {
        desktopTemplate: Templates.BIOMETRIC_VERIFICATION_DESKTOP_ONLY,
        mobileTemplate: Templates.BIOMETRIC_VERIFICATION,
      };
    case CredentialAnalysisRequirement.FOREIGN_ID:
    case CredentialAnalysisRequirement.IDENTITY_DOCUMENT_VALIDATION:
    case CredentialAnalysisRequirement.PS1583:
    case CredentialAnalysisRequirement.SECONDARY_ID:
      return {
        desktopTemplate: Templates.GOV_ID_DESKTOP_ONLY,
        mobileTemplate: Templates.GOV_ID_MOBILE_ONLY,
      };
    case CredentialAnalysisRequirement.NONE:
    case CredentialAnalysisRequirement.AUTO_PASS:
      return {
        desktopTemplate: Templates.AUTO_PASS_DESKTOP_ONLY,
        mobileTemplate: Templates.AUTO_PASS_MOBILE_ONLY,
      };
    default:
      throw new Error(`unhandled credential analysis requirement: ${requirement}`);
  }
}

function getTemplateId({
  requirement,
  configuration,
}: {
  requirement: CredentialAnalysisRequirement;
  configuration: IDVConfiguration;
}): Templates {
  const templateInfo = getTemplateInfo({
    requirement,
    configuration,
  });
  return configuration.enableDesktopCapture && templateInfo.desktopTemplate !== null
    ? templateInfo.desktopTemplate
    : templateInfo.mobileTemplate;
}

function getProcessableSecondaryIdParams(input: IDVInput): Fields {
  if (!input || input.source === "service") {
    return {};
  }

  const { idClassSecondary } = input;
  return idClassSecondary
    ? {
        selected_id_class_1: idClassSecondary,
        secondary_id_gov: true,
        selected_country_code_secondary: input.country,
      }
    : {
        ...(input.idTypeSecondary && { secondary_id_type: input.idTypeSecondary }),
        secondary_id_gov: false,
      };
}

function getFieldsForTemplate(
  templateId: string,
  caStep: CredentialAnalysisStep,
  idvInteraction: IDVInteraction,
): Fields {
  const { input } = idvInteraction;
  if (!input || input.source === "service") {
    return {};
  }

  const requiresSecondaryId = Boolean(
    caStep.secondaryIdRequired || input.idClassSecondary || input.idTypeSecondary,
  );

  const commonFields = {
    selected_country_code: input.country,
    requires_secondary_id: requiresSecondaryId,
    ...(input.idClass && { selected_id_class: input.idClass }),
  };

  switch (templateId) {
    case Templates.BIOMETRIC_VERIFICATION:
    case Templates.BIOMETRIC_VERIFICATION_DESKTOP_ONLY:
      return {
        ...commonFields,
        ...(input.idClassSecondary ? getProcessableSecondaryIdParams(input) : {}),
      };
    case Templates.GOV_ID_MOBILE_ONLY:
    case Templates.GOV_ID_DESKTOP_ONLY:
    case Templates.AUTO_PASS_DESKTOP_ONLY:
    case Templates.AUTO_PASS_MOBILE_ONLY:
      return {
        ...commonFields,
        ...(input.idTypeSecondary && { secondary_id_type: input.idTypeSecondary }),
        allows_foreign_id:
          caStep.credentialAnalysisRequirement === CredentialAnalysisRequirement.FOREIGN_ID,
        ps1583: caStep.credentialAnalysisRequirement === CredentialAnalysisRequirement.PS1583,
      };
    default:
      return {};
  }
}
export function getFailureReason(
  failureReasons: CreatePersonaPhotoIdentificationResult["failureReasons"],
): FailureReasons | null {
  const reasons = failureReasons?.map((reason) => reason.required && reason.category);
  if (reasons?.includes(PhotoIdVerificationFailureCategories.FRAUD)) {
    return "fraud";
  } else if (
    reasons?.includes(PhotoIdVerificationFailureCategories.IMAGE_QUALITY) ||
    reasons?.includes(PhotoIdVerificationFailureCategories.INELIGIBLE_ID)
  ) {
    return "idOrImage";
  } else if (reasons?.includes(PhotoIdVerificationFailureCategories.KBA_MISMATCH)) {
    return "kbaMismatch";
  }
  return null;
}

export function Persona({
  caStep,
  signerIdentity,
  onComplete,
  onClose,
  idvInteraction,
  organizationTransaction,
}: Props) {
  const { configuration, input } = idvInteraction;
  const [loading, setLoading] = useState(false);
  const { id } = signerIdentity.customer!;
  const { lockRecipientName, customerSigners } = organizationTransaction;
  const dob = signerIdentity.customerInformation!.dob;
  const currentSignerIdentityId = signerIdentity.id;
  const intl = useIntl();
  useA11y().useDocumentEntitler({
    title: intl.formatMessage(useDocumentTitles().preMeetingCredentialAnalysisPhotoIdUpload),
  });

  const createPersonaPhotoIdentificationMutateFn = useMutation(
    CreatePersonaPhotoIdentificationMutation,
  );

  const onCancel = useCallback(() => {
    setLoading(false);
    onClose();
  }, []);

  const createVerification = useCallback(
    async ({ inquiryId }: { inquiryId: string }) => {
      setLoading(true);
      try {
        const { data } = await createPersonaPhotoIdentificationMutateFn({
          variables: {
            input: {
              identificationType: PhotoIdentification.PRIMARY,
              inquiryId,
              signerIdentityId: currentSignerIdentityId,
            },
          },
        });
        onComplete(data!.createPersonaPhotoIdentification!);
      } finally {
        setLoading(false);
      }
    },
    [currentSignerIdentityId, onComplete],
  );

  const { startPollingIdvResult, loading: idvPolling } = usePollIdentityVerificationResult({
    currentSignerIdentityId,
    onComplete,
  });

  const referenceId = useMemo(
    () => `${currentSignerIdentityId}-${v4()}`,
    [currentSignerIdentityId],
  );

  const customerSigner =
    (lockRecipientName && getCustomerSigner(customerSigners, signerIdentity.user!.id)) ||
    signerIdentity.customer; // signer identity needed for credible witness flow

  if (!customerSigner) {
    throw Error("Could not find customer information");
  }

  const { firstName, lastName } = customerSigner;
  const participant = { firstName, lastName, dob, id };

  const templateId = useMemo(
    () =>
      getTemplateId({
        requirement: caStep.credentialAnalysisRequirement,
        configuration,
      }),
    [caStep.credentialAnalysisRequirement, configuration],
  );

  const fields = useMemo(
    () => getFieldsForTemplate(templateId, caStep, idvInteraction),
    [caStep, templateId, input, configuration],
  );

  const client = usePersonaClient({
    inquiryId: input?.source === "service" ? input.inquiryId : undefined,
    referenceId,
    onComplete: input?.source === "service" ? startPollingIdvResult : createVerification,
    currentParticipant: participant,
    templateId,
    onCancel,
    fields,
  });

  useEffect(() => {
    client.open();
    segmentTrack("Opened Persona", { templateId, referenceId });
  }, [client, templateId, referenceId]);

  if (loading || idvPolling) {
    return <LoadingIndicator />;
  }
  return null;
}
