import { useCallback, useEffect, useMemo, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { debounceTime } from "rxjs";

import Button from "common/core/button";
import LoadingIndicator from "common/core/loading_indicator";
import TablePagination, { usePagination } from "common/core/table/pagination";
import TableSearch from "common/core/table/search";
import { DashboardHeader } from "common/dashboard";
import { useSubject } from "util/rxjs/hooks";

import type { Loop } from ".";
import { PAGE_SIZE } from "./constants";
import Styles from "./import_loop.module.scss";
import ProfileSelector from "./profile_selector";
import { getLoops } from "./requests";

const MESSAGES = defineMessages({
  searchPlacholder: {
    id: "7d8d35c7-d421-45ab-a651-cb65816c28f1",
    defaultMessage: "Search by loop name",
  },
  searchLabel: {
    id: "d497478a-c0f3-4105-a1e5-90f115513ce9",
    defaultMessage: "Search for loops",
  },
});

type Props = {
  onCancel: () => void;
  goToNextStep: () => void;
  selectedProfileId: number | null;
  selectedLoop: Loop | null;
  onSelectProfile: (profileId: number) => void;
  onSelectLoop: (loopId: Loop | null) => void;
};

export default function ImportLoop({
  onCancel,
  goToNextStep,
  selectedProfileId,
  selectedLoop,
  onSelectProfile,
  onSelectLoop,
}: Props) {
  const intl = useIntl();

  const [loading, setLoading] = useState<boolean>(false);
  const [loops, setLoops] = useState<Loop[]>([]);

  const [pageIndex, setPageIndex] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const searchValue$ = useSubject<string>();
  let filteredLoopsLength = loops.length;

  useEffect(() => {
    let cancelled = false;

    if (selectedProfileId) {
      setLoading(true);
      getLoops(selectedProfileId)
        .then((data: Loop[]) => {
          if (!cancelled) {
            setLoops(data);
          }
        })
        .finally(() => {
          setPageIndex(0);
          if (!cancelled) {
            setLoading(false);
          }
        });
    }

    return () => {
      cancelled = true;
    };
  }, [selectedProfileId]);

  useEffect(() => {
    const sub = searchValue$.pipe(debounceTime(750)).subscribe((value: string) => {
      setSearchValue(value);
      setPageIndex(0);
    });
    return () => sub.unsubscribe();
  }, []);

  const handleSearchChange = useCallback(({ value }: { value: string }) => {
    searchValue$.next(value);
  }, []);

  const handlePageChange = (newPageIndex: number) => {
    setPageIndex(newPageIndex);
  };

  const applySearchFilter = (searchValue: string) => {
    return loops.filter((loop) => loop.name.toLowerCase().includes(searchValue.toLowerCase()));
  };

  const applyPagination = (loops: Loop[], pageIndex: number) => {
    const startIndex = pageIndex * PAGE_SIZE;
    return loops.slice(startIndex, startIndex + PAGE_SIZE);
  };

  const handleContinue = () => {
    onSelectLoop(selectedLoop);
    goToNextStep();
  };

  const items = useMemo(() => {
    let filteredLoops;
    filteredLoops = applySearchFilter(searchValue);
    filteredLoopsLength = filteredLoops.length;
    filteredLoops = applyPagination(filteredLoops, pageIndex);
    return filteredLoops;
  }, [loops, searchValue, pageIndex]);

  const { canNextPage, canPreviousPage, nextPage, previousPage, startIndex, endIndex } =
    usePagination({
      disabled: loading,
      pageIndex,
      pageCount: Math.max(Math.ceil(filteredLoopsLength / PAGE_SIZE), 1),
      pageSize: PAGE_SIZE,
      items,
      onPageChange: handlePageChange,
    });

  return (
    <div>
      <div className={Styles.selectLoopTitle}>
        <FormattedMessage
          id="10686f54-9ce2-40c9-b889-f35670c8c4b0"
          defaultMessage="Select a Loop to Import"
          tagName="h3"
        />
        <ProfileSelector
          onSelectProfile={onSelectProfile}
          initialSelectedProfile={selectedProfileId}
        />
      </div>

      <DashboardHeader>
        <div>
          <TableSearch
            value={searchValue}
            placeholder={intl.formatMessage(MESSAGES.searchPlacholder)}
            aria-label={intl.formatMessage(MESSAGES.searchLabel)}
            onChange={handleSearchChange}
          />
        </div>
        <div>
          <TablePagination
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            nextPage={nextPage}
            previousPage={previousPage}
            startIndex={startIndex}
            endIndex={endIndex}
            totalCount={filteredLoopsLength}
          />
        </div>
      </DashboardHeader>

      <table className={Styles.table}>
        <thead className={Styles.thead}>
          <tr>
            <FormattedMessage
              id="d497478a-c0f3-4105-a1e5-90f115513ce9"
              tagName="th"
              defaultMessage="Loop Name"
            />
            <FormattedMessage
              id="e653ee46-a510-4e38-a046-3f231f553837"
              tagName="th"
              defaultMessage="Type"
            />
            <FormattedMessage
              id="1be79450-76d7-45d5-8e8a-53eff78d15cd"
              tagName="th"
              defaultMessage="Status"
            />
            <FormattedMessage
              id="d0aa19c0-8889-4a34-9a3b-0344c4c50e97"
              tagName="th"
              defaultMessage="Closing Date"
            />
          </tr>
        </thead>
        <tbody className={Styles.tbody}>
          {loading ? (
            <tr>
              <td>
                <LoadingIndicator />
              </td>
            </tr>
          ) : items.length ? (
            items.map((loop) => (
              <tr
                key={loop.id}
                onClick={() => onSelectLoop(loop)}
                className={loop.id === selectedLoop?.id ? Styles.selected : undefined}
              >
                <td>{loop.name}</td>
                <td>{loop.transaction_type}</td>
                <td>{loop.status}</td>
                <td>{loop.closing_date}</td>
              </tr>
            ))
          ) : (
            <FormattedMessage
              id="5ae5c6a4-f1cb-433e-a4ec-fa23fd8fe935"
              defaultMessage="No loops to show for this Dotloop profile. Try changing your profile."
            />
          )}
        </tbody>
      </table>

      <div className={Styles.buttons}>
        <Button onClick={onCancel} variant="secondary" buttonColor="action">
          <FormattedMessage id="10686f54-9ce2-40c9-b889-f35670c8c2b0" defaultMessage="Cancel" />
        </Button>
        <Button
          onClick={handleContinue}
          disabled={!selectedLoop}
          variant="primary"
          buttonColor="action"
        >
          <FormattedMessage id="10686f54-9ce2-40c9-b889-f35670c8c2b1" defaultMessage="Continue" />
        </Button>
      </div>
    </div>
  );
}
